(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('OrderCouponCode', OrderCouponCode);

    OrderCouponCode.$inject = ['$resource'];

    function OrderCouponCode($resource) {
        var resourceUrl = 'order/api/0.1/orders/:id/coupon-codes';

        return $resource(resourceUrl, {}, {
            'update': {method: 'PUT'}
        });
    }
})();
