(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('label', {
            parent: 'app',
            url: '/label?',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/label/label.html',
                    controller: 'LabelController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('label');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('us', {
            parent: 'app',
            url: '/label/us',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/label/label-us.html',
                    controller: 'LabelController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('label');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('eu', {
            parent: 'app',
            url: '/label/eu',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/label/label-eu.html',
                    controller: 'LabelController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('label');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
