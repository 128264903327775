(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('help', {
            parent: 'app',
            url: '/help?',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/help/help.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('help');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('terms', {
            parent: 'app',
            url: '/terms',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/help/help-terms-conditions.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('help');
                    return $translate.refresh();
                }]
            }
        })
        $stateProvider.state('privacy-policy', {
            parent: 'app',
            url: '/privacy-policy',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/help/help-privacy-policy.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('help');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
