(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportStatusTag', reportStatusTag)
        .controller('ReportStatusTagController', ReportStatusTagController);

    ReportStatusTagController.$inject = ['Principal', 'Report'];

    function reportStatusTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                status: '=',
                hideIcon: '@',
                hideLabel: '@',
                editable: '@',
                report: '='
            },
            templateUrl: 'app/entities/report/report-status-tag.html',
            controller: 'ReportStatusTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.activate();
                }
            }, true);
        }
    }

    function ReportStatusTagController(Principal, Report) {
        var vm = this;

        vm.editable = (vm.editable == 'true');
        var realStatusList = [
            {
                name: 'INCOMPLETE',
                key: 'webportalApp.ReportStatus.INCOMPLETE',
                icon: 'fa-ban',
                class: 'text-danger'
            },
            {name: 'UPLOADED', key: 'webportalApp.ReportStatus.UPLOADED', icon: 'fa-upload', class: 'text-info'},
            {name: 'VERIFIED', key: 'webportalApp.ReportStatus.VERIFIED', icon: 'fa-upload', class: 'text-success'},
            {
                name: 'PUBLISHING_STARTED',
                key: 'webportalApp.ReportStatus.PUBLISHING_STARTED',
                icon: 'fa-cogs',
                class: 'text-warning'
            },
            {
                name: 'COMMUNICATED',
                key: 'webportalApp.ReportStatus.COMMUNICATED',
                icon: 'fa-cogs',
                class: 'text-success'
            }];

        var visualStatusList = [
            {
                name: 'INCOMPLETE',
                key: 'webportalApp.ReportStatus.INCOMPLETE',
                icon: 'fa-ban',
                class: 'text-danger'
            },
            {name: 'UPLOADED', key: 'webportalApp.ReportStatus.UPLOADED', icon: 'fa-upload', class: 'text-info'},
            {
                name: 'VERIFIED',
                key: 'webportalApp.ReportStatus.APPROVED',
                icon: 'fa-check-circle-o',
                class: 'text-normal'
            },
            {
                name: 'PUBLISHING_STARTED',
                key: 'webportalApp.ReportStatus.APPROVED',
                icon: 'fa-check-circle-o',
                class: 'text-normal'
            },
            {
                name: 'COMMUNICATED',
                key: 'webportalApp.ReportStatus.APPROVED',
                icon: 'fa-check-circle-o',
                class: 'text-normal'
            }];

        vm.updateStatusList = [
            {name: 'VERIFIED', key: 'webportalApp.ReportStatus.VERIFIED', icon: 'fa-upload', class: 'text-success'},
            {
                name: 'PUBLISHING_STARTED',
                key: 'webportalApp.ReportStatus.PUBLISHING_STARTED',
                icon: 'fa-cogs',
                class: 'text-warning'
            }];

        vm.activate = activate;
        vm.updateReport = updateReport;

        activate();

        ////////////////

        function activate() {
            if (Principal.hasAnyAuthority(['ROLE_DATA_MANAGER'])) {
                vm.allowEdit = vm.editable;
                vm.statusList = realStatusList;
            } else {
                if (vm.report.description) {
                    visualStatusList
                        .filter(approvedStatus)
                        .forEach(function (status) {
                            status.key = 'webportalApp.ReportStatus.APPROVED_WITH_REMARKS';
                        });
                }
                vm.statusList = visualStatusList;
            }
        }

        function approvedStatus(status) {
            return status.key === 'webportalApp.ReportStatus.APPROVED';
        }

        function updateReport() {
            Report.update(vm.report);
        }
    }
})();
