(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Patient', Patient);

    Patient.$inject = ['$resource', 'DateUtils'];

    function Patient($resource, DateUtils) {
        var resourceUrl = 'api/patients/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true, transformResponse: transformPatients},
            'get': {
                method: 'GET',
                transformResponse: transformResponse
            },
            'update': {
                method: 'PUT',
                transformRequest: transformRequest,
                transformResponse: transformResponse
            },
            'save': {
                method: 'POST',
                transformRequest: transformRequest,
                transformResponse: transformResponse
            }
        });

        function transformRequest(data) {
            if (data.info) {
                data.info.birthDate = DateUtils.convertLocalDateToServer(data.info.birthDate);
            }
            if (data.labels || data.labelsUI) {
                if (!data.labelsUI) {
                    data.labels = '';
                } else {
                    data.labels = data.labelsUI.map(function (item) {
                        return item.text;
                    }).join(' ');
                }
            }
            return angular.toJson(data);
        }

        function transformResponse(data) {
            data = angular.fromJson(data);
            if (data.info) {
                data.info.birthDate = DateUtils.convertLocalDateFromServer(data.info.birthDate);
            }
            if (data.labels) {
                data.labelsUI = data.labels.split(' ').map(function (item) {
                    return {text: item};
                });
            }
            return concatPatientName(data);
        }

        function transformPatients(data) {
            var patients = angular.fromJson(data);
            return patients.map(concatPatientName);
        }

        function concatPatientName(patient) {
            var name = '';
            if (patient.info && patient.info.user) {
                if (patient.info.user.lastName) {
                    name = patient.info.user.lastName + ', ';
                }
                if (patient.info.user.firstName) {
                    name = name + patient.info.user.firstName;
                }
                patient.name = name;
            }
            //console.debug(patient);
            return patient;
        }
    }
})();
