(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPublicationCountryTag', publicationCountryTag)
        .controller('PublicationCountryTagController', PublicationCountryTagController);

    PublicationCountryTagController.$inject = ['Principal', 'CaseStudyPublicationResource', '$scope', 'ISO3166'];

    function publicationCountryTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '=',
                editable: '@'
            },
            templateUrl: 'app/infos/case-study/publication-country-tag.html',
            controller: 'PublicationCountryTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.caseStudyOption', function (newValue) {
                if (newValue) {
                    scope.vm.prepareEdit(newValue);
                }
            }, true);
        }
    }

    function PublicationCountryTagController(Principal, CaseStudyPublicationResource, $scope, ISO3166) {
        var vm = this;

        vm.prepareEdit = prepareEdit;
        vm.updatePublications = updatePublications;
        vm.validatePublications = validatePublications;

        vm.editable = vm.editable && vm.editable == 'true' && Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);

        ////////////////

        function prepareEdit(newValue) {
            vm.caseStudy = newValue;
            if (vm.editable) {
                if(vm.caseStudy.then){
                    vm.caseStudy.then(prepareTextRep);
                }else{
                    prepareTextRep(vm.caseStudy);
                }
            }
        }

        function prepareTextRep(data) {
            if (data && data.publications) {
                vm.caseStudy = data;

                var textRep = '';
                var separator = '';

                for (var index = 0; index < vm.caseStudy.publications.length; index++) {
                    var publication = vm.caseStudy.publications[index];
                    if (publication.countryInclude && publication.countryInclude != '*') {
                        textRep += separator + vm.caseStudy.publications[index].countryInclude;
                        separator = ', ';
                    } else if (publication.countryExclude) {
                        textRep += separator + '!' + vm.caseStudy.publications[index].countryExclude;
                        separator = ', ';
                    }
                }
                vm.caseStudy.publicationTextRep = textRep;
            }
        }

        function updatePublications() {
            var countries = vm.caseStudy.publicationTextRep.split(',');
            var publications = vm.caseStudy.publications;
            var countriesLength = countries.length;
            var publicationsLength = publications.length;
            var minLength = Math.min(countriesLength, publicationsLength);

            if (countriesLength < publicationsLength) {
                // the new list is shorter, remove latest publications
                publications = publications.slice(0, minLength == 0 ? 1 : minLength);
                publicationsLength = publications.length;
            }

            for (var index = 0; index < countriesLength; index++) {
                var country = countries[index];
                var publication = publications[index];
                if (country && publication) {
                    setValue(publication, country);
                } else if (!country) {
                    if (publicationsLength == 1) {
                        setValue(publication, '*');
                    }
                } else {
                    publications.push(setValue({}, country))
                }
            }

            vm.caseStudy.publications = publications;

            CaseStudyPublicationResource.update({id: vm.patient.id}, vm.caseStudy, onSaveSuccess, onSaveError);
        }

        function setValue(publication, country) {
            country = country.replace(/ /g, '');
            if (country.indexOf('!') == -1) {
                publication.countryInclude = country;
                publication.countryExclude = undefined;
            } else {
                publication.countryInclude = undefined;
                publication.countryExclude = country.substring(1, 3);
            }
            return publication;
        }

        function validatePublications(data) {
            if (invalidIncludeExclude(data)) {
                return "You cannot mix includes and excludes";
            }
            var countries = data.split(',');
            for (var index = 0; index < countries.length; index++) {
                if(invalidCountryCode(countries[index])){
                    return "Unknown country code: " + countries[index];
                }
            }
        }

        function invalidIncludeExclude(data) {
            var numberOfExcludes = (data.match(/!/g) || []).length;
            var countries = data.split(',');

            if (numberOfExcludes > 0 && numberOfExcludes != countries.length) {
                return true;
            }else{
                return false;
            }
        }

        function invalidCountryCode(country) {
            country = country.replace(/ /g, '');
            if (country.indexOf('!') != -1) {
                country = country.substring(1, 3);
            }
            if(country && !ISO3166.isCountryCode(country)){
                return "Unknown country code: " + country;
            }
        }

        function onSaveSuccess(result) {
            //$scope.$emit('webportalApp:patientUpdate', vm.patient);
        }

        function onSaveError() {

        }
    }
})();
