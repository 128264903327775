(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('ReportHelper', ReportHelper);

    ReportHelper.$inject = ['Principal', 'Report'];

    function ReportHelper(Principal, Report) {
        var pneumoParts = ['SPIROMETRIE', 'LUNG_VOLUMES', 'AIRWAY_RESISTANCE_CLINICAL', 'DIFFUSION_CAPACITY', 'PNEUMO_INFO'];
        var medDevParts = ['LUNG_FUNCTION'];
        var sleepParts = ['SLEEP_INFO'];
        var partStatuses = [{value: 'INCOMPLETE', text: 'webportalApp.PartStatus.INCOMPLETE'},
            {value: 'UPLOADED', text: 'webportalApp.PartStatus.UPLOADED'},
            {value: 'VERIFIED', text: 'webportalApp.PartStatus.VERIFIED'},
            {value: 'PUBLISHED', text: 'webportalApp.PartStatus.PUBLISHED'}];

        var service = {
            getPart: getPart,
            getParts: getParts,
            getFilteredParts: getFilteredParts,
            getFilteredPartsSortedArray: getFilteredPartsSortedArray,
            getClinicalParts: getClinicalParts,
            getClinicalPartNames: getClinicalPartNames,
            partStatuses: partStatuses,
            getPartStatuses: getPartStatuses
        };
        return service;

        ////////////

        function getPartStatuses(category) {
            if (category === 'MED_DEV') {
                return partStatuses.slice(0, 3);
            } else {
                return partStatuses;
            }

        }

        function getPart(report, name) {
            return getParts(report)[name];
        }

        function getParts(report) {
            var parts = {};
            if (report && report.parts) {
                report.parts.forEach(function (item) {
                    if (!parts[item.type.name] || !parts[item.type.name].binaries) { // keep first in map if binaries
                        parts[item.type.name] = item;
                    }
                });
            }
            return parts;
        }

        function getFilteredParts(report, filteredNames) {
            var parts = {};
            if (report && report.parts) {
                report.parts.forEach(function (item) {
                    if (filteredNames.indexOf(item.type.name) > -1) {
                        if (!parts[item.type.name] || !parts[item.type.name].binaries) { // keep first in map if binaries
                            parts[item.type.name] = item;
                        }
                    }
                });
            }
            return parts;
        }

        function getFilteredPartsSortedArray(report, filteredNames) {
            var partsObject = {};
            report.parts.forEach(function (item) {
                if (filteredNames.indexOf(item.type.name) > -1) {
                    if (!partsObject[item.type.name] || !partsObject[item.type.name].binaries) { // keep first in map if binaries
                        partsObject[item.type.name] = item;
                    }
                }
            });
            var partsArray = [];
            filteredNames.forEach(function (name) {
                var part = partsObject[name];
                if (part) {
                    partsArray.push(part);
                } else {
                    partsArray.push({'type': {'name': name}});
                }
            });
            return partsArray;
        }

        function getClinicalParts(report) {
            var parts = {};
            report.parts.forEach(function (item) {
                if (item.type.category === 'CLINICAL') {
                    parts[item.type.name] = item;
                }
            });
            return parts;
        }

        function getClinicalPartNames(visitType, category) {
            if (visitType === 'PNEUMO') {
                if (category === 'RESEARCH') {
                    return pneumoParts;
                } else {
                    return medDevParts;
                }
            } else if (visitType === 'SLEEP') {
                return sleepParts;
            } else {
                return [];
            }
        }
    }
})();
