(function () {
    'use strict';

    angular
        .module('webportalApp')
        .factory('Invitation', Invitation);

    Invitation.$inject = ['$resource'];

    function Invitation($resource) {
        return $resource('api/invites', {}, {
            'save': {method: 'POST'},
            'finish': {url: 'api/invites/finish', method: 'POST'}
        });
    }
})();
