(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('CaseStudyPublicationResource', CaseStudyPublicationResource);

    CaseStudyPublicationResource.$inject = ['$resource', 'DateUtils'];

    function CaseStudyPublicationResource($resource, DateUtils) {
        var resourceUrl = 'api/case-studies/:id/publications';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'PUT'
            }
        });
    }
})();
