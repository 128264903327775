(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('CaseStudyService', CaseStudyService);

    CaseStudyService.$inject = ['$q', 'CaseStudyResource'];

    function CaseStudyService($q, CaseStudyResource) {
        var service = {
            retrieve: retrieve,
            retrieveByVisit: retrieveByVisit,
            retrieveByPatient: retrieveByPatient
        };
        return service;

        ////////////

        function retrieve(patientId) {
            return CaseStudyResource.query(
                {
                    patientId: patientId,
                    page: 0,
                    size: 1
                }).$promise.then(getFirst);
        }

        function getFirst(array) {
            return array[0];
        }

        function retrieveByVisit(visit) {
            if (visit) {
                if (visit.patient) {
                    return retrieveByPatient(visit.patient);
                } else if (visit.$promise) {
                    return visit.$promise.then(retrieveByVisit);
                }
            }
            return $q.when({});
        }

        function retrieveByPatient(patient) {
            if (patient) {
                if (patient.id) {
                    return retrieve(patient.id);
                } else if (patient.$promise) {
                    return patient.$promise.then(retrieveByPatient);
                }
            }
            return $q.when({});
        }
    }
})();
