(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('webportalApp')
        .constant('VERSION', "1.5.1")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('HIDE_LABEL_EU', true)
        .constant('HIDE_LABEL_US', false)
;
})();
