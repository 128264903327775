(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldMediaLightbox', mediaLightbox)
        .controller('MediaLightboxController', MediaLightboxController);

    MediaLightboxController.$inject = ['$uibModal', '$filter', '$log'];

    function mediaLightbox() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                items: '=',
                titleKey: '@',
                thumbnailClass: '@',
                max: '@'
            },
            templateUrl: 'app/components/media/ligthbox.html',
            controller: 'MediaLightboxController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.items', function (newVal) {
                if (newVal) {
                    scope.vm.groupItems();
                }
            }, true);
        }
    }

    function MediaLightboxController($uibModal, $filter, $log) {
        var vm = this;

        vm.open = open;
        vm.groupItems = groupItems;
        vm.mobileApple = isMobile.apple.device;
        vm.max = vm.max ? vm.max : 2;

        groupItems();


        /////////////////////////

        function groupItems() {
            vm.groupedItems = [];

            if (vm.items) {
                var mappedItems = {};
                for (var i = 0; i < vm.items.length; i++) {
                    var item = vm.items[i];

                    if (vm.mobileApple && isVideo(item)) {
                        // video not supported on apple mobile
                    } else {
                        var mappedItem = getMappedItem(mappedItems, item, i);

                        mappedItem.items.push(item);

                        mappedItem.hasVideo = mappedItem.hasVideo || isVideo(item);
                        mappedItem.priority += correctPriority(item);
                    }
                }
                vm.groupedItems = collectSortAndSlice(mappedItems);

                addTitles();
                //console.log(vm.groupedItems);
            }
        }

        function getMappedItem(mappedItems, item, i) {
            var key = item.type + '_' + item.tags[0];
            if (!mappedItems[key]) {
                mappedItems[key] = {hasVideo: false, items: [], priority: i};
            }
            return mappedItems[key];
        }

        function correctPriority(item) {
            var priority = 0;
            if (item.tags.indexOf('main') == -1) {
                priority += 10;
            }
            if (item.tags.indexOf('response') != -1) {
                //response last, healthy first
                priority += 10;
            }
            return priority;
        }

        function isVideo(item) {
            return item.contentType.indexOf('video') != -1;
        }

        function collectSortAndSlice(mappedItems) {
            var groupedItems = [];

            for (var key in mappedItems) {
                groupedItems.push(mappedItems[key]);
            }
            groupedItems.sort(compare);

            return groupedItems.slice(0, vm.max); //keep max 2 items
        }

        function compare(group1, group2) {
            return group1.priority >= group2.priority;
        }

        function addTitles() {
            vm.groupedItems.forEach(function (mappedItem) {
                var first = mappedItem.items[0];
                if (first) {
                    mappedItem.title = generateTitle(first);
                }
            })
        }


        function getTitleKey(item) {
            var translationByType = $filter('translate')('webportalApp.binary.' + item.type);
            if (vm.titleKey) {
                return vm.titleKey;
            } else if (item.tags.indexOf('healthy') != -1) {
                return 'webportalApp.binary.healthy';
            } else if (item.tags.indexOf('response') != -1) {
                return 'webportalApp.binary.response';
            } else if (translationByType.indexOf('webportalApp.binary.') == -1) {
                //$log.debug(translationByType);
                return 'webportalApp.binary.' + item.type;
            } else if (item.tags.indexOf('main') != -1) {
                return 'webportalApp.binary.main';
            }
        }

        function generateTitle(item) {
            var translation = $filter('translate')(getTitleKey(item));
            if (item.title) {
                return translation + ': ' + item.title;
            } else {
                return translation;
            }
        }

        function open(index) {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'modalLightbox.html',
                controller: 'ModalInstanceCtrl',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    groupedItems: function () {
                        return vm.groupedItems;
                    },
                    index: index
                }
            });
        }
    }

    angular
        .module('webportalApp')
        .controller('ModalInstanceCtrl', function ($scope, $uibModalInstance, groupedItems, index) {
            var vm = this;

            vm.index = index;

            setItem();

            vm.navigate = function (index) {
                if (index < 0) {
                    vm.index = groupedItems.length - 1;
                } else if (index >= groupedItems.length) {
                    vm.index = 0;
                } else {
                    vm.index = index;
                }
                setItem();
            };

            vm.close = function () {
                $uibModalInstance.close();
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function setItem() {
                vm.group = groupedItems[vm.index];
                vm.showPrevious = vm.index > 0;
                vm.showNext = vm.index < groupedItems.length - 1;

            }
        });
})();
