(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldDepositionChart', depositionChart)
        .controller('DepositionChartController', DepositionChartController)
        /*.config(['ChartJsProvider', function (ChartJsProvider) {
            // Configure all charts
            ChartJsProvider.setOptions({
                colours: ['#4db6ac', '#64B5F6', '#4D5360', '#FDB45C', '#00ADF9', '#DCDCDC', '#46BFBD', '#949FB1', '#46716A', '#53B5A1', '#1F876F'],
                responsive: true
            });
        }])*/;

    DepositionChartController.$inject = ['ReportDetailPartDepositionService'];

    function depositionChart() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                type: '@'
            },
            templateUrl: 'app/entities/part/deposition/report-detail-part-deposition-chart.html',
            controller: 'DepositionChartController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newValue) {
                if (newValue) {
                    scope.vm.refreshChartTile(newValue);
                }
            }, true);
        }
    }

    function DepositionChartController(ReportDetailPartDepositionService) {
        var vm = this;

        vm.graphOption = ReportDetailPartDepositionService.graphOptions[vm.type];
        vm.options = {
            legend: {
                display: false,
                position: 'bottom',
                labels:{
                    boxWidth: 20
                }
            },
            elements: {
                arc: {
                    borderWidth: 0
                }
            }
        };

        vm.refreshChartTile = refreshChartTile;

        ////////////

        function refreshChartTile(depositionPart) {
            vm.colors = vm.graphOption.colors;

            vm.labels = ReportDetailPartDepositionService.generateLabels(vm.graphOption);
            vm.data = ReportDetailPartDepositionService.generateData(depositionPart, vm.graphOption);
        }
    }
})();
