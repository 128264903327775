(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldOrderStatusSwitch', orderStatusSwitch)
        .controller('OrderStatusSwitchController', OrderStatusSwitchController);

    OrderStatusSwitchController.$inject = ['Order'];

    function orderStatusSwitch() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                order: '=',
                statusFrom: '@',
                statusTo: '@'
            },
            templateUrl: 'app/orders/order/order-status-switch.html',
            controller: 'OrderStatusSwitchController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function OrderStatusSwitchController(Order) {
        var vm = this;

        vm.updateStatus = updateStatus;

        ////////////////

        function updateStatus(newStatus){
            vm.order.status = newStatus;
            Order.update(vm.order)
                .$promise.then(function(order) {
                    vm.order = order;
                });
        }

    }
})();
