(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartDepositionData', reportDetailPartDepositionData)
        .controller('DetailPartDepositionDataController', DetailPartDepositionDataController);

    DetailPartDepositionDataController.$inject = ['ReportDetailPartHelperService', 'ReportDetailPartDepositionService'];

    function reportDetailPartDepositionData() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                report: '='
            },
            templateUrl: 'app/entities/part/deposition/report-detail-part-deposition-data.html',
            controller: 'DetailPartDepositionDataController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.prepareDepositions(newValue);
                }
            }, true);
        }
    }

    function DetailPartDepositionDataController(ReportDetailPartHelperService, ReportDetailPartDepositionService) {
        var vm = this;
        vm.chartOptions = ReportDetailPartHelperService.procentChartOptions;
        vm.chartColors = ReportDetailPartHelperService.procentChartColors;

        vm.prepareDepositions = prepareDepositions;

        ////////////


        function prepareDepositions(report) {
            vm.levels = ['full', 'intra', 'region'];
            vm.parts = report.parts.filter(function (part) {
                return part.type.name === 'AEROSOL_DEPOSITION';
            });
            vm.depositionEntries = [];
            if (vm.parts) {
                vm.productNames = vm.parts.map(function (depositionPart) {
                    return depositionPart.product
                });

                vm.depositionEntriesMap = {};

                vm.levels.forEach(function (level) {
                    var graphOption = ReportDetailPartDepositionService.graphOptions[level];

                    graphOption.values.forEach(function (propertyName) {

                        vm.parts.forEach(function (depositionPart, index) {

                            var zoneEntry = depositionPart.zoneEntries.filter(function (entry) {
                                return entry.zone === propertyName.toUpperCase();
                            });

                            var entry = vm.depositionEntriesMap[propertyName];
                            if (!entry) {
                                entry = {
                                    level: level,
                                    area: propertyName,
                                    value: []
                                };
                                vm.depositionEntriesMap[propertyName] = entry;
                                vm.depositionEntries.push(entry);
                            }

                            entry.value[index] = {
                                percentage: zoneEntry[0].percentage,
                                chartData: [zoneEntry[0].percentage, 100 - zoneEntry[0].percentage],
                                chartLabels: ['one', 'two']
                            };
                        });

                    });
                });
            }
        }
    }
})();
