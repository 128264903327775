(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('ReportDetailController', ReportDetailController);

    ReportDetailController.$inject = ['$scope', '$rootScope', '$state', 'entity', 'visit', 'Report', 'Visit', '$analytics', 'ReportHelper', 'Principal'];

    function ReportDetailController($scope, $rootScope, $state, entity, visit, Report, Visit, $analytics, ReportHelper, Principal) {
        var vm = this;
        vm.isCollapsed = true;
        vm.report = entity;
        vm.visit = visit;
        vm.now = new Date();

        vm.exportToPdf = exportToPdf;
        vm.print = print;

        if (vm.visit.$promise) {
            vm.visit.$promise
                .then(loadOtherVisits);
        }

        if (vm.report.$promise) {
            vm.report.$promise
                .then(checkUserIsAllowedToSee)
                .then(prepareParts);
        }

        $analytics.eventTrack('impression', {category: 'meddev', label: 'html'});

        ////////////

        function loadOtherVisits() {
            vm.patient = vm.visit.patient;
            var params = {
                patientId: vm.patient.id,
                page: 0,
                size: 100,
                sort: ['date,asc']
            };
            Visit.query(params, function (result) {
                vm.visits = result.filter(function (element) {
                    return vm.visit.nr >= element.nr;
                });
            });
        }

        function checkUserIsAllowedToSee() {
            var isMedDev = vm.report.category === 'MED_DEV';
            var isDoctorMedDev = Principal.hasAnyAuthority(['ROLE_DOCTOR_MEDDEV']);
            var isPublished = vm.report.status === 'PUBLISHING_STARTED' || vm.report.status === 'COMMUNICATED';

            if (isMedDev && isDoctorMedDev && !isPublished) {
                $state.go('accessdenied');
            }

        }

        function prepareParts() {
            vm.parts = ReportHelper.getParts(vm.report);

            prepareLungFunction();
            //console.debug(vm.parts);
        }

        function prepareLungFunction() {
            if (vm.parts.LUNG_FUNCTION) {
                vm.lungFunctions = {};
                vm.parts.LUNG_FUNCTION.entries.forEach(function (item) {
                    vm.lungFunctions[item.type.name] = item;
                });
                //console.debug(vm.lungFunctions);
            }
        }

        function exportToPdf() {
            $analytics.eventTrack('click', {category: 'meddev', label: 'pdf'});
            window.print();
        }

        function print() {
            $analytics.eventTrack('click', {category: 'meddev', label: 'print'});
            window.print();
        }
    }
})();
