(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('InvitationController', InvitationController);

    InvitationController.$inject = ['$stateParams', 'Invitation', 'LoginService', '$scope', '$analytics', 'Principal'];

    function InvitationController($stateParams, Invitation, LoginService, $scope, $analytics, Principal) {
        var vm = this;

        vm.copyEmail = copyEmail;
        vm.sendInvite = sendInvite;

        /*Auth.activateAccount({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });*/

        //vm.login = LoginService.open;

        /////


        function copyEmail() {
            if (!vm.user.login && vm.user.email && vm.user.email.indexOf('@') != -1) {
                var firstPart = vm.user.email.substr(0, vm.user.email.indexOf('@'));
                vm.user.login = firstPart.replace(/[^0-9a-z]/gi, '');
            }
        }

        function sendInvite() {
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;

            Invitation.save(vm.user).$promise.then(function () {
                vm.success = 'OK';
                Principal.identity().then(function (account) {
                    $analytics.eventTrack('click', {category: 'invite', label: account.login});
                });

            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                    $scope.editForm.login.$setValidity("userexists", false);
                } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                    vm.errorEmailExists = 'ERROR';
                    $scope.editForm.email.$setValidity("emailexists", false);
                } else {
                    vm.error = 'ERROR';
                }
            });

        }
    }
})();
