(function() {
    'use strict';

    angular
        .module('webportalApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$stateParams'];

    function HomeController ($scope, Principal, $stateParams) {
        var vm = this;

        vm.isAuthenticated = null;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if ($stateParams.login === 'true' && !vm.isAuthenticated()) {
                    vm.login();
                }
            });
        }
    }
})();
