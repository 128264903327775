(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('LabelController', LabelController);

    LabelController.$inject = ['$scope', 'Principal', '$stateParams', 'HIDE_LABEL_EU', 'HIDE_LABEL_US'];

    function LabelController($scope, Principal, $stateParams, HIDE_LABEL_EU, HIDE_LABEL_US) {
        var vm = this;
        vm.hideLabelEU = HIDE_LABEL_EU
        vm.hideLabelUS = HIDE_LABEL_US
    }
})();
