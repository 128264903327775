(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('center', {
                parent: 'entity',
                url: '/center?page&sort&search',
                data: {
                    authorities: ['ROLE_DATA_VIEWER', 'ROLE_DATA_MANAGER', 'ADMIN'],
                    pageTitle: 'webportalApp.center.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/center/centers.html',
                        controller: 'CenterController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        return $translate.refresh();
                    }]
                }
            })
            .state('center-detail', {
                parent: 'entity',
                url: '/center/{id}?tab&page&sort',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER', 'ADMIN'],
                    pageTitle: 'webportalApp.center.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/center/center-detail.html',
                        controller: 'CenterDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tab: {
                        value: '0',
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');

                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Center', function ($stateParams, Center) {
                        if ('new' !== $stateParams.id) {
                            return Center.get({id: $stateParams.id});
                        }
                    }]
                }
            })
            .state('center.new', {
                parent: 'center',
                url: '/new',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/center/center-dialog.html',
                        controller: 'CenterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    country: null,
                                    timezone: null,
                                    lengthUnit: 'CM',
                                    weightUnit: 'KG',
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('center', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('center', null, {location: 'replace'});
                    });
                }]
            })
            .state('center-detail.listEdit', {
                parent: 'center-detail',
                url: '/listEdit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/center/center-dialog.html',
                        controller: 'CenterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Center', function (Center) {
                                return Center.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('center', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('center', null, {location: 'replace'});
                    });
                }]
            })
            .state('center-detail.edit', {
                parent: 'center-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/center/center-dialog.html',
                        controller: 'CenterDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Center', function (Center) {
                                return Center.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('center.delete', {
                parent: 'center',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/center/center-delete-dialog.html',
                        controller: 'CenterDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Center', function (Center) {
                                return Center.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('center', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('center', null, {location: 'replace'});
                    });
                }]
            });
    }

})();
