(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('InvoiceDetailController', InvoiceDetailController);

    InvoiceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Invoice', 'Address', 'Order', '$localStorage', '$sessionStorage'];

    function InvoiceDetailController($scope, $rootScope, $stateParams, previousState, entity, Invoice, Address, Order, $localStorage, $sessionStorage) {
        var vm = this;

        vm.invoice = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('webportalApp:invoiceUpdate', function (event, result) {
            vm.invoice = result;
        });
        $scope.$on('$destroy', unsubscribe);

        setupAuthorizationHeader();
        loadOrder();
        //PDFObject.embed("/pdf/sample-3pp.pdf", "#example1");

        ///////////////

        function loadOrder() {
            if (vm.invoice.id) {
                Order.query({
                    invoiceId: vm.invoice.id,
                    page: 0,
                    size: 1
                }, function (result, headers) {
                    vm.order = result[0];

                    loadPdf();
                });
            }
        }

        function loadPdf() {
            var url = 'media/api/0.1/invoices/'+vm.invoice.id+'.pdf?customerId='+vm.order.customer.id;
            var options = {
                pdfOpenParams: {
                    view: 'FitV'
                }
            };
            PDFObject.embed(url, '#pdfPlaceholder', options);
        }

        function setupAuthorizationHeader() {
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            $scope.httpHeaders = { Authorization: 'Bearer '+token };
        }
    }
})();

