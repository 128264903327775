(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('BookController', BookController);

    BookController.$inject = ['$scope', '$state', 'Product', 'AlertService', 'Book'];

    function BookController($scope, $state, Product, AlertService, Book) {
        var vm = this;

        vm.preview = 'Ym9va3MtRlJJcGFydDFfcHJldmlldy5wZGY=';

        vm.quantity = 1;
        Book.get().then(function (books) {
            for (var i = 0; i < books.length; i++) {
                var item = books[i];
                if (item.code == 'BOOK_FRI_1') {
                    vm.book = item;
                    vm.id = vm.book.id;
                } else if (item.code == 'EBOOK_FRI_1') {
                    vm.ebook = item;
                }
            }

        });


        ///////////////////

    }

})();
