(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PatientDeleteController', PatientDeleteController);

    PatientDeleteController.$inject = ['$uibModalInstance', 'entity', 'Patient', 'Visit'];

    function PatientDeleteController($uibModalInstance, entity, Patient, Visit) {
        var vm = this;
        vm.patient = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        retrieveVisits();

        ////////////

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            Patient.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

        function retrieveVisits() {
            vm.patient.$promise.then(function () {
                Visit.query({
                    patientId: vm.patient.id
                }, onSuccess);
            });
        }

        function onSuccess(data, headers) {
            vm.hasVisits = data.length > 0;
        }
    }
})();
