(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VideoController', VideoController);

    VideoController.$inject = ['$scope', '$state', 'LoginService', '$translate', 'AlertService', 'Principal', 'Redeem', '$sce', '$http'];

    function VideoController($scope, $state, LoginService, $translate, AlertService, Principal, Redeem, $sce, $http) {
        var vm = this;

        vm.quantity = 1;

        vm.freeVideoUrls = [
            trust('https://www.youtube.com/embed/Upsavq3EzPw?rel=0'),
            trust('https://www.youtube.com/embed/xbfRN4DMc_U?rel=0')
        ];

        vm.paidVideos = [
            {
                url: 'ZGlzZWFzZXMtYXN0aG1hLWNvbWJpbmUubXA0.mp4',
                posterName: 'ZGlzZWFzZXMtYXN0aG1hLWNvbWJpbmVfcG9zdGVyLnBuZw==.png',
                tags: ['asthma']
            },
            {
                url: 'ZGlzZWFzZXMtY29wZC1jb21iaW5lLm1wNA==.mp4',
                posterName: 'ZGlzZWFzZXMtY29wZC1jb21iaW5lX3Bvc3Rlci5wbmc=.png',
                tags: ['copd']
            },
            {
                url: 'ZGlzZWFzZXMtaXBmLWNvbWJpbmUubXA0.mp4',
                posterName: 'ZGlzZWFzZXMtaXBmLWNvbWJpbmVfcG9zdGVyLnBuZw==.png',
                tags: ['ipf']
            },
            {
                url: 'ZGlzZWFzZXMtY2YtY29tYmluZS5tcDQ=.mp4',
                posterName: 'ZGlzZWFzZXMtY2YtY29tYmluZV9wb3N0ZXIucG5n.png',
                tags: ['cf']
            },
            {
                url: 'ZGlzZWFzZXMtY2FuY2VyLWNvbWJpbmUubXA0.mp4',
                posterName: 'ZGlzZWFzZXMtY2FuY2VyLWNvbWJpbmVfcG9zdGVyLnBuZw==.png',
                tags: ['cancer']
            },
            {
                url: 'ZGlzZWFzZXMtYXN0aG1hLWJsb29kdmVzc2VsLm1wNA==.mp4',
                posterName: 'ZGlzZWFzZXMtYXN0aG1hLWJsb29kdmVzc2VsX3Bvc3Rlci5wbmc=.png',
                tags: ['asthma', 'blood vessels']
            },
            {
                url: 'ZGlzZWFzZXMtYXN0aG1hLWlWYXd3Lm1wNA==.mp4',
                posterName: 'ZGlzZWFzZXMtYXN0aG1hLWlWYXd3X3Bvc3Rlci5wbmc=.png',
                tags: ['asthma', 'airway wall']
            },
            {
                url: 'ZGlzZWFzZXMtYXN0aG1hLWVtcGh5c2VtYS5tcDQ=.mp4',
                posterName: 'ZGlzZWFzZXMtYXN0aG1hLWVtcGh5c2VtYV9wb3N0ZXIucG5n.png',
                tags: ['asthma', 'emphysema']
            },
            {
                url: 'ZGlzZWFzZXMtYXN0aG1hLWFpcnRyYXBwaW5nLm1wNA==.mp4',
                posterName: 'ZGlzZWFzZXMtYXN0aG1hLWFpcnRyYXBwaW5nX3Bvc3Rlci5wbmc=.png',
                tags: ['asthma', 'air trapping']
            }
        ];

        vm.login = LoginService.open;
        vm.redeem = redeem;

        initPage();
        $scope.$on('authenticationSuccess', function () {
            initPage();
        });

        ///////////////////

        function initPage() {
            retrievePosterImages();

            vm.isAuthenticated = Principal.isAuthenticated() == true;
            Principal.hasAuthority('ROLE_VIDEO_VIEWER').then(function (result) {
                vm.paidVideosAccess = result;
            });
        }

        function retrievePosterImages() {
            for (var index = 0; index < vm.paidVideos.length; ++index) {
                var name = vm.paidVideos[index].posterName;
                if(name){
                    posterUrl(name).then(setPoster(index));
                }
            }
        }

        function posterUrl(fileName) {
            var url = 'media/0.1/images/' + fileName;
            //console.log('Retrieving '+ url + '...')
            return $http.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    'accept': 'image/webp,image/*,*/*;q=0.8'
                }
            }).then(function(response){
                var blob = new Blob(
                    [response.data],
                    {type: response.headers('Content-Type')}
                );
                return URL.createObjectURL(blob);
            });
        }

        function trust(url) {
            return $sce.trustAsResourceUrl(url);
        }

        function setPoster(index){
            var i = index;
            return function(url){
                vm.paidVideos[i].poster = url;
            };
        }

        function redeem(accessCode) {
            Redeem.update({accessCode: accessCode}, {})
                .$promise.then(function (result) {
                    vm.paidVideosAccess = true;
                },
                function (error) {
                    var message = $translate.instant('webportalApp.video.redeem.error');
                    AlertService.error(message);
                });
        }
    }

})();
