(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CompareDetailController', CompareDetailController);

    CompareDetailController.$inject = ['$stateParams', '$state', '$q', 'Compare', 'visits', 'CaseStudyService', 'CompareConfig'];

    function CompareDetailController($stateParams, $state, $q, Compare, visits, CaseStudyService, CompareConfig) {
        var vm = this;

        vm.patientId = $stateParams.patientId;
        vm.partNames = $state.$current.data.part;
        vm.visits = visits;
        vm.visit = visits[0];
        vm.visitDates = [];
        vm.visitNrs = [];
        vm.maxValue = 100;

        vm.navigateToVisit = navigateToVisit;
        vm.compareSelected = compareSelected;

        vm.visits.$promise.then(function (visits) {
            vm.patient = vm.visits[0].patient;
            initCaseStudy();
            retrieveCompares().then(function (compares) {
                for (var index = 0; index < vm.compares.length; index++) {
                    prepareSeries(vm.compares[index]);
                    prepareGraph(vm.compares[index]);
                    prepareBinaries(vm.compares[index]);
                }
            }, function (errors) {
                console.log(errors);
            });
        });

        /////////////////////////////////

        function navigateToVisit(index) {
            var visitId = vm.visitIds[index];
            $state.go(resolveStateName(visitId), {id: visitId});
        }

        function compareSelected() {
            var selectedVisitIds = selectedIds();
            if (selectedVisitIds.length > 1) {
                $state.go($state.current.name, {patienId: $stateParams.patientId, visitIds: selectedVisitIds});
            }
        }

        function initCaseStudy() {
            vm.caseStudy = CaseStudyService.retrieveByPatient(vm.patient);
            /*vm.caseStudy.then(function (caseStudy) {
             vm.caseStudy = caseStudy;
             })*/
        }

        function selectedIds() {
            return vm.visits.filter(function (visit) {
                return visit.selected;
            }).map(function (visit) {
                return visit.id
            }).join(",");
        }

        function resolveStateName(visitId) {
            var stateName = $state.current.name.replace('compare-', 'visit-detail.');
            if ($state.get().map(function (x) {
                    return x.name;
                }).indexOf(stateName) == -1) {
                stateName = 'visit-detail.lobar';
            }
            return stateName;
        }

        function retrieveCompares() {
            vm.compares = [];
            vm.visitIds = $stateParams.visitIds ? $stateParams.visitIds.split(',') : null;
            if (!vm.visitIds) {
                vm.visitIds = vm.visits.map(function (elem) {
                    return elem.id + '';
                });
            }
            vm.visits.forEach(function (element) {
                var index = vm.visitIds.indexOf(element.id + '');
                if (index != -1) {
                    element.selected = true;
                    var visitDate = new Date(element.date);
                    vm.visitDates[index] = visitDate;
                    vm.visitNrs[index] = element.nr;
                    vm.lastVisitNr = element.nr;
                } else {
                    element.selected = false;
                }
            });
            //console.log(vm.visitNrs);

            for (var i = 0; i < vm.partNames.length; i++) {
                vm.compares[i] = Compare.get({id: vm.partNames[i], visitId: vm.visitIds}).$promise;
                vm.compares[i].then(storeCompare(i));
            }

            return $q.all(vm.compares);
        }

        function storeCompare(index) {
            var i = index;
            return function (data) {
                vm.compares[i] = data;
            }
        }

        /*
         * Prepare series: add visit date to each entry (= X value on graph)
         */
        function prepareSeries(compare) {
            compare.graph = {};
            compare.maxValue = CompareConfig.defaultMaxValue(compare);
            for (var serieIndex = 0; serieIndex < compare.series.length; serieIndex++) {
                var serie = compare.series[serieIndex];
                for (var visitIndex = 0; visitIndex < serie.values.length; visitIndex++) {
                    var data = serie.values[visitIndex];
                    data.date = vm.visitDates[visitIndex];

                    if (CompareConfig.selectYvalue(data) > compare.maxValue) {
                        compare.maxValue = CompareConfig.selectYvalue(data);
                    }
                }
            }
            //console.log(compare.series);
            compare.graph.data = compare.series;
        }

        function prepareGraph(compare) {
            compare.graph.options = CompareConfig.options(compare, vm.visitDates);
        }

        function prepareBinaries(compare) {

            compare.binaries.forEach(prepareBinary)
        }

        function prepareBinary(binary) {
            binary.title = binary.tags.filter(isNumber).map(lookupNumber).join(' - ');
        }

        function isNumber(value) {
            return !isNaN(value);
        }

        function lookupNumber(tag) {
            var index = vm.visitIds.indexOf(tag);

            return 'V' + vm.visitNrs[index];
        }

    }
})();

