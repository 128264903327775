(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('googlecity', googlecity);

    function googlecity() {
        return {
            require: 'ngModel',
            scope: {
                address: '=',
                ngModel: '='
            },
            link: link
        };

        function link($scope, element, attrs, model) {

            var options = {
                types: ['(cities)'],
                componentRestrictions: {}
            };
            var autocomplete = new google.maps.places.Autocomplete(element[0], options);

            google.maps.event.addListener(autocomplete, 'place_changed', function() {
                $scope.$apply(function() {
                    var place = autocomplete.getPlace();

                    var components = place.address_components;
                    $scope.address.city = selectField('locality', components).long_name;
                    $scope.address.locality = selectField('locality', components).long_name;
                    var country = selectField('country', components).long_name;
                    $scope.address.countryKey = selectField('country', components).short_name;
                    $scope.address.countryCode = $scope.address.countryKey;

                    if($scope.address.city || country) {
                        $scope.address.location = $scope.address.city;
                    }
                });
            });
        }

        function selectField(type, components){
            var field;
            for(var i=0, length=components.length; i<length; i++){
                field = components[i];
                if(field.types.indexOf(type)!=-1){
                    return field;
                }
            }
            return '';
        }
    }
})();
