(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldHomeLoggedIn', homeLoggedIn)
        .controller('HomeLoggedInController', HomeLoggedInController);

    HomeLoggedInController.$inject = ['Principal', '$stateParams', 'AlertService', '$translate', 'UploadSession', '$state'];

    function homeLoggedIn() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
            },
            templateUrl: 'app/home/home-logged-in.html',
            controller: 'HomeLoggedInController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.master', function (newValue) {
                if (newValue) {
                    scope.vm.loadAll();
                }
            }, true);
        }
    }

    function HomeLoggedInController(Principal, $stateParams, AlertService, $translate, UploadSession, $state) {
        var vm = this;

        vm.goToUpload = goToUpload;

        activate();

        ////////////

        function activate() {
            vm.account = null;
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if ($stateParams.login === 'true' && !vm.isAuthenticated()) {
                    vm.login();
                }
                vm.hospitalRequired = Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV']);
                if (vm.hospitalRequired && !vm.account.company) {
                    AlertService.clear();
                    var message = $translate.instant('settings.messages.error.incomplete');
                    AlertService.add({
                        type: 'warning',
                        msg: message,
                        toast: true,
                        timeout: -1
                    });
                }
            });
        }

        function goToUpload() {
            UploadSession.sessionEnded();
            $state.go('upload.init');
        }

    }
})();
