(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPatientPanel', patientPanel)
        .controller('PatientPanelController', patientPanelController);

    patientPanelController.$inject = ['MyCenter', 'Principal'];

    function patientPanel() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/entities/patient/patient-panel.html',
            controller: 'PatientPanelController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                if (newVal) {
                    scope.vm.refreshShowPatientInfo();
                }
            }, true);
        }
    }

    function patientPanelController(MyCenter, Principal) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.showPatientLink = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER']);

        vm.refreshShowPatientInfo = refreshShowPatientInfo;

        ////////////

        function refreshShowPatientInfo() {
            getMyCenter().then(function () {
                vm.showPatientInfo = isDoctor() && vm.patient.center.id === vm.myCenter.id;
                vm.showPatientLink = vm.showPatientInfo || vm.showPatientLink;
            });
        }

        function isDoctor() {//TODO: fix this
            return Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV']);
        }

        function getMyCenter(){
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }
    }
})();
