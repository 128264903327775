(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('CaseStudyResource', CaseStudyResource);

    CaseStudyResource.$inject = ['$resource', 'DateUtils'];

    function CaseStudyResource($resource, DateUtils) {
        var resourceUrl = 'api/case-studies/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    if (data.info) {
                        data.info.birthDate = DateUtils.convertLocalDateFromServer(data.info.birthDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            }
        });
    }
})();
