(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitPanel', visitPanel)
        .controller('VisitPanelController', visitPanelController);

    function visitPanel() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '='
            },
            templateUrl: 'app/entities/visit/visit-panel.html',
            controller: 'VisitPanelController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function visitPanelController() {
        var vm = this;


    }
})();
