(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartDepositionChartTable', reportDetailPartDepositionChartTable)
        .controller('DepositionChartTableController', DepositionChartTableController);

    DepositionChartTableController.$inject = [];

    function reportDetailPartDepositionChartTable() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                report: '='
            },
            templateUrl: 'app/entities/part/deposition/report-detail-part-deposition-chart-table.html',
            controller: 'DepositionChartTableController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newValue) {
                if (newValue) {
                    scope.vm.reportPart = newValue;
                }
            }, true);
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.prepareCharts(newValue);
                }
            }, true);
        }
    }

    function DepositionChartTableController() {
        var vm = this;

        vm.prepareCharts = prepareCharts;

        ////////////

        function prepareCharts(report) {

            vm.parts = report.parts.filter(function (part) {
                return part.type.name === 'AEROSOL_DEPOSITION';
            });


            // init of depositions
            vm.leftProduct = vm.parts[0];
            vm.rightProduct = vm.parts[1];
        }
    }
})();
