(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('DoctorDetailController', DoctorDetailController);

    DoctorDetailController.$inject = ['$scope', '$rootScope', 'entity', 'pagingParams', 'Doctor'];

    function DoctorDetailController($scope, $rootScope, entity, pagingParams, Doctor) {
        var vm = this;
        vm.doctor = entity;
        vm.pagingParams = pagingParams;

        vm.load = load;

        var unsubscribe = $rootScope.$on('webportalApp:doctorUpdate', function (event, result) {
            vm.doctor = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function load(id) {
            Doctor.get({id: id}, function (result) {
                vm.doctor = result;
            });
        }

    }
})();
