(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('invite-new', {
            parent: 'account',
            url: '/invite/new',
            data: {
                authorities: ['ROLE_DATA_VIEWER', 'ROLE_DATA_MANAGER', 'ADMIN'],
                pageTitle: 'invite.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/invite/new.html',
                    controller: 'InvitationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('activate');
                    $translatePartialLoader.addPart('user-management');
                    $translatePartialLoader.addPart('invite');
                    return $translate.refresh();
                }]
            }
        }),
            $stateProvider.state('invite-choose-pwd', {
                parent: 'account',
                url: '/invite/choose-pwd?key',
                data: {
                    pageTitle: 'invite.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/invite/invitation-change-pwd.html',
                        controller: 'InvitationChangePwdController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('user-management');
                        $translatePartialLoader.addPart('invite');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
