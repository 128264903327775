(function () {
    'use strict';

    angular
        .module('webportalApp')
        .factory('ReportDetailPartHelperService', ReportDetailPartHelperService);

    ReportDetailPartHelperService.$inject = [];

    function ReportDetailPartHelperService() {

        var stateNames = {
            'LOBAR_VOLUMES_TLC': 'lobar',
            'LOBAR_VOLUMES_FRC': 'lobar',
            'INTERNAL_FLOW_DISTRIBUTION': 'distribution',
            'AIRWAY_VOLUME_TLC': 'airway',
            'AIRWAY_VOLUME_FRC': 'airway',
            'SPECIFIC_AIRWAY_VOLUME_TLC': 'airway',
            'SPECIFIC_AIRWAY_VOLUME_FRC': 'airway',
            'SPECIFIC_AIRWAY_RADIUS_TLC': 'airway',
            'AIRWAY_RESISTANCE_FUNCTIONAL_TLC': 'resistance',
            'AIRWAY_RESISTANCE_FUNCTIONAL_FRC': 'resistance',
            'SPECIFIC_AIRWAY_RESISTANCE_TLC': 'resistance',
            'SPECIFIC_AIRWAY_RESISTANCE_FRC': 'resistance',
            'AIR_TRAPPING': 'trapping',
            'AIRWAY_WALL_THICKNESS': 'airwaywall',
            'BLOOD_VESSEL_DENSITY': 'density',
            'FIBROSIS': 'fibrosis',
            'EMPHYSEMA': 'emphysema',
            'AEROSOL_DEPOSITION': 'deposition',
            'NODULES': 'nodules',
            'VENTILATION_PERFUSION': 'ventilationPerfusion',
            'SPIROMETRIE': 'spirometrie',
            'LUNG_VOLUMES': 'lungvolumes',
            'AIRWAY_RESISTANCE_CLINICAL': 'lungresistance',
            'DIFFUSION_CAPACITY': 'diffusion',
            'PNEUMO_INFO': 'pneumo_info',
            'SCAN_AXIAL': 'axial'
        };

        var options = {
            animate: {
                duration: 1000,
                enabled: true
            },
            barColor: '#49ad99',
            trackColor: '#e2e2e2',
            scaleColor: false,
            lineWidth: 10,
            lineCap: 'circle',
            size: 20
        };

        var procentChartOptions = {
            legend: {display: false},
            responsive: false,
            elements: {
                arc: {
                    borderWidth: 0
                }
            },
            tooltips: {enabled: false}
        };
        var procentChartColors = ['#49AD99', '#E2E2E2'];
        var procentWarningChartColors = ['#F8AC59', '#E2E2E2'];
        var procentDangerChartColors = ['#ED5565', '#E2E2E2'];

        var deviationBarSettings = {
            normal: {
                'LL': {
                    startPixel: 0,
                    width: 25,
                    from: 'min',
                    to: 'lowLow'
                },
                'L': {
                    startPixel: 25,
                    width: 12,
                    from: 'lowLow',
                    to: 'low'
                },
                'N': {
                    startPixel: 38,
                    width: 25,
                    from: 'low',
                    to: 'high'
                },
                'H': {
                    startPixel: 63,
                    width: 12,
                    from: 'high',
                    to: 'highHigh'
                },
                'HH': {
                    startPixel: 76,
                    width: 25,
                    from: 'highHigh',
                    to: 'max'
                }
            },
            positive: {
                'N': {
                    startPixel: 0,
                    width: 25,
                    from: 'zero',
                    to: 'high'
                },
                'H': {
                    startPixel: 26,
                    width: 25,
                    from: 'high',
                    to: 'highHigh'
                },
                'HH': {
                    startPixel: 51,
                    width: 50,
                    from: 'highHigh',
                    to: 'max'
                }
            }
        };

        var service = {
            stateName: stateName,
            options: options,
            procentChartOptions: procentChartOptions,
            procentChartColors: procentChartColors,
            procentWarningChartColors: procentWarningChartColors,
            procentDangerChartColors: procentDangerChartColors,
            selectClass: selectClass,
            calculateRelativeValue: calculateRelativeValue
        };
        return service;

        ////////////

        function stateName(partName) {
            return stateNames[partName];
        }

        function selectClass(deviationEntry) {
            if (deviationEntry == undefined) {
                return '';
            }
            deviationEntry.cssClass = '';
            var deviation = deviationEntry.code;
            if (deviation === 'HH' || deviation === 'LL') {
                deviationEntry.cssClass = 'text-danger';
            } else if (deviation === 'H' || deviation === 'L') {
                deviationEntry.cssClass = 'text-warning';
            }
            return deviationEntry.cssClass;
        }

        function calculateRelativeValue(deviationEntry) {
            if (deviationEntry == undefined) {
                return 0;
            }
            var offset, pixelRange, settings;
            if (deviationEntry.low == 0 && deviationEntry.lowLow == 0) {
                pixelRange = 50;
                offset = 0;
                settings = deviationBarSettings.positive[deviationEntry.code];
            } else {
                pixelRange = 50;
                offset = 25;
                settings = deviationBarSettings.normal[deviationEntry.code];
            }
            var output;

            if(settings){
                var zoneFrom;
                if ('min' == settings.from) {
                    zoneFrom = deviationEntry.lowLow - (deviationEntry.normal - deviationEntry.lowLow);
                } else  if ('zero' == settings.from) {
                    zoneFrom = 0;
                } else {
                    zoneFrom = deviationEntry[settings.from];
                }

                var zoneTo;
                if ('max' == settings.to) {
                    zoneTo = deviationEntry.highHigh + (deviationEntry.highHigh - deviationEntry.normal);
                } else {
                    zoneTo = deviationEntry[settings.to];
                }
                output = (((deviationEntry.value - zoneFrom) / (zoneTo - zoneFrom)) * settings.width) + settings.startPixel;
            }else{
                var rangeSize = deviationEntry.high - deviationEntry.lowLow;
                output = (((deviationEntry.value - deviationEntry.lowLow) / rangeSize) * pixelRange) + offset;
            }

            deviationEntry.relativeValue = (output <= 0 ) ? 1 : (output >= 100) ? 99 : Math.round(output);

            return deviationEntry.relativeValue;
        }
    }
})();
