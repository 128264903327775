(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCaseStudyHeader', caseStudyHeader)
        .controller('CaseStudyHeaderController', CaseStudyHeaderController);

    CaseStudyHeaderController.$inject = ['$q','CaseStudyResource', 'CaseStudyService'];

    function caseStudyHeader() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/infos/case-study/case-study-header.html',
            controller: 'CaseStudyHeaderController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                scope.vm.initCaseStudy(newVal);
            }, true);
            scope.$watch('vm.caseStudyOption', function (newVal) {
                scope.vm.success(newVal);
            }, true);
        }
    }

    function CaseStudyHeaderController($q, CaseStudyResource, CaseStudyService) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.caseStudyStatuses = [{value: 'INCOMPLETE', text: 'webportalApp.CaseStudyStatus.INCOMPLETE'},
            {value: 'VERIFIED', text: 'webportalApp.CaseStudyStatus.VERIFIED'},
            {value: 'PUBLISHED', text: 'webportalApp.CaseStudyStatus.PUBLISHED'}];

        vm.updateCaseStudy = updateCaseStudy;
        vm.initCaseStudy = initCaseStudy;
        vm.success = success;

        ////////////////////////////////

        function initCaseStudy() {
            if (!vm.caseStudy) {
                vm.caseStudy = CaseStudyService.retrieveByPatient(vm.patient);
            }
            if(vm.caseStudy.then){
                vm.caseStudy.then(success);
            }else{
                success(vm.caseStudy);
            }
        }

        function updateCaseStudy() {
            if (vm.caseStudy.pricing === 1) {
                vm.caseStudy.pricing = 'FREE';
            } else if (vm.caseStudy.pricing === 2) {
                vm.caseStudy.pricing = 'PAID';
            }
            return CaseStudyResource.update({id: vm.patient.id}, vm.caseStudy).$promise.then(success);
        }

        function success(data) {
            vm.caseStudy = data;
        }
    }
})();
