(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadVisitLungFunctionController', UploadVisitLungFunctionController);

    UploadVisitLungFunctionController.$inject = ['patient', 'visit', '$scope', '$state', 'VisitHelper', 'ReportHelper', 'Report', 'VisitStatusResource'];

    function UploadVisitLungFunctionController(patient, visit, $scope, $state, VisitHelper, ReportHelper, Report, VisitStatusResource) {
        var vm = this;

        vm.currentStep = 'Lung function';
        vm.visit = visit;
        vm.patient = patient;

        vm.save = save;

        activate(patient, visit);

        //////////

        function activate() {
            VisitHelper.retrievePatient(vm.patient, vm.visit)
                .then(saveOnVM('patient'));

            VisitHelper.retrieveReport(vm.visit)
                .then(saveOnVM('report'))
                .then(ReportHelper.getClinicalParts)
                .then(saveOnVM('parts'));
        }

        function save() {
            vm.isSaving = true;
            vm.report.category = vm.visit.category;

            Report.update(vm.report, onSaveSuccess, onSaveError);

        }


        function onSaveSuccess(result) {
            if (vm.visit.status === 'INCOMPLETE') {
                VisitStatusResource.update({'visitId': vm.visit.id, 'value': 'READY_TO_UPLOAD'}, {});
            }

            $scope.$emit('webportalApp:reportUpdate', result);

            vm.isSaving = false;

            goNext(result);
        }

        function goNext(visit) {
            $state.go('upload.confirm', {patientId: vm.visit.patient.id, visitId: vm.visit.id});
        }


        function onSaveError() {
            vm.isSaving = false;
        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            }
        }
    }
})();
