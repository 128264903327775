(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('ReportDetailPartController', ReportDetailPartController);

    ReportDetailPartController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'entity', 'Visit', 'ReportDetailPartHelperService', 'ReportHelper', 'PartResource', 'Principal', 'CaseStudyService', '$analytics', 'report'];

    function ReportDetailPartController($scope, $rootScope, $state, $filter, entity, Visit, ReportDetailPartHelperService, ReportHelper, PartResource, Principal, CaseStudyService, $analytics, report) {
        var vm = this;
        vm.visit = entity;
        vm.report = report;
        vm.reportPartName = $state.$current.data.part[0];
        vm.partStatuses = ReportHelper.partStatuses;
        vm.options = ReportDetailPartHelperService.options;
        vm.chartColorsWarning = ReportDetailPartHelperService.procentWarningChartColors;
        vm.chartColorsDanger = ReportDetailPartHelperService.procentDangerChartColors;

        vm.load = load;
        vm.showStatus = showStatus;
        vm.updatePart = updatePart;

        initReportParts();

        var unsubscribe = $rootScope.$on('webportalApp:reportUpdate', function (event, result) {
            vm.report = result;
        });
        $scope.$on('$destroy', unsubscribe);
        initCaseStudy();

        $analytics.eventTrack('impression', {category: 'endpoint', label: $state.current.name});

        ////////////

        function initReportParts() {
            vm.report.$promise.then(function () {

                var showAnyStatus = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
                vm.nothingToShow = true;
                vm.reportParts = ReportHelper.getFilteredPartsSortedArray(vm.report, $state.$current.data.part);
                vm.reportParts.forEach(function (item) {
                    if (showAnyStatus || item.status == 'PUBLISHED') {
                        item.show = true;
                        vm.nothingToShow = false;
                    } else {
                        item.show = false;
                    }
                });
                vm.reportPart = vm.reportParts[0];
            });
        }

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function showStatus() {
            var selected = $filter('filter')(vm.partStatuses, {value: vm.reportPart.status});
            return (vm.reportPart.status && selected.length) ? selected[0].text : 'Not set';
        }

        function updatePart() {
            PartResource.update({
                visitId: vm.visit.id,
                type: vm.reportPart.type.name,
                product: vm.reportPart.product
            }, vm.reportPart);
        }

        function initCaseStudy() {
            vm.caseStudy = CaseStudyService.retrieveByVisit(vm.visit);
            vm.caseStudy.then(function (caseStudy) {
                vm.caseStudy = caseStudy;
            })
        }
    }
})();
