(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldFeedback', fldFeedback)
        .controller('FeedbackButtonController', FeedbackButtonController);

    FeedbackButtonController.$inject = ['FeedbackService'];

    function fldFeedback() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
            },
            templateUrl: 'app/components/feedback/feedback-button.html',
            controller: 'FeedbackButtonController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function FeedbackButtonController(FeedbackService) {
        var vm = this;

        vm.open = open;
        vm.feedback = feedback;

        /////////////////////

        function feedback() {
            FeedbackService.open();
        }
    }
})();
