(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitExportController', VisitExportController);

    VisitExportController.$inject = ['$scope', '$rootScope', '$log', 'entity', 'report', 'Visit', 'MyCenter', '$state', 'FeedbackService', 'Principal', '$http', '$analytics'];

    function VisitExportController($scope, $rootScope, $log, entity, report, Visit, MyCenter, $state, FeedbackService, Principal, $http, $analytics) {
        var vm = this;
        vm.visit = entity;
        vm.report = report;
        vm.isCollapsed = true;

        vm.load = load;
        vm.feedback = feedback;
        vm.exportToPdf = exportToPdf;
        vm.print = print;
        vm.download = download;

        var unsubscribe = $rootScope.$on('webportalApp:visitUpdate', function (event, result) {
            vm.visit = result;
        });
        $scope.$on('$destroy', unsubscribe);

        activate();

        ////////////

        function activate() {
            prepareParts();

            vm.visit.$promise.then(function (visit) {
                vm.patient = visit.patient;
                vm.visitUrl = $state.href('visit-detail.lobar', {id: visit.id}, {absolute: true, inherit: false});
                vm.patienUrl = $state.href('patient-detail', {id: vm.patient.id}, {absolute: true, inherit: false});

                vm.now = new Date();
                getMyCenter().then(function () {
                    vm.showPatientInfo = isDoctor() && vm.patient.center.id === vm.myCenter.id;
                });
            });
        }

        function prepareParts() {
            vm.report.$promise.then(function (report) {
                vm.parts = report.parts
                    .filter(isPublished)
                    .filter(someParts)
                    .filter(duplicateDeposition())
                    .sort(byOrder);
            });
        }

        function duplicateDeposition() {
            var previous;

            return function checkDuplicate(part) {
                if (part.type.name === 'AEROSOL_DEPOSITION') {
                    if (!previous && part.binaries.length > 0) {
                        previous = part;
                        return true
                    }
                    return false;
                } else {
                    return true;
                }
            };
        }

        function isPublished(part) {
            return part.status === 'PUBLISHED';
        }

        function someParts(part) {
            return ['SCAN_AXIAL', 'SCAN_CORONAL', 'SCAN_SAGITTAL'].indexOf(part.type.name) === -1;

        }

        function byOrder(part1, part2) {
            return part1.type.order - part2.type.order;
        }

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function getMyCenter() {
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }

        function feedback() {
            FeedbackService.open();
        }

        function isDoctor() {//TODO: fix this
            return Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV']);
        }

        function exportToPdf() {
            $analytics.eventTrack('click', {category: 'export', label: 'pdf'});
            window.print();
        }

        function print() {
            $analytics.eventTrack('click', {category: 'export', label: 'print'});
            window.print();
        }

        function download() {
            $analytics.eventTrack('click', {category: 'export', label: 'csv'});
            var url = '/report/api/0.1/reports/' + vm.visit.id + '.csv';
            $http.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    'accept': 'text/csv;q=0.9,*/*;q=0.8'
                }
            }).then(onSuccess, onError);
        }

        function onError(error) {
            $log.debug(error);
        }

        function onSuccess(result) {
            var blob = new Blob([result.data], {type: 'text/csv'});
            var blobURL = URL.createObjectURL(blob);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, result.headers('filename'));
            } else {
                var a = document.createElement('a');
                a.href = blobURL;
                a.download = result.headers('filename');
                a.target = '_self';
                document.body.appendChild(a);
                a.click();
                //URL.revokeObjectURL(blobURL);
            }
        }
    }
})();
