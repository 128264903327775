(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('NurseDialogController', NurseDialogController);

    NurseDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Nurse', 'User', 'Center'];

    function NurseDialogController($scope, $uibModalInstance, entity, Nurse, User, Center) {
        var vm = this;
        vm.nurse = entity;

        vm.users = User.query();
        vm.centers = Center.query();

        vm.load = load;
        vm.save = save;
        vm.clear = clear;

        ////////////

        function load(id) {
            Nurse.get({id: id}, function (result) {
                vm.nurse = result;
            });

        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:nurseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError() {
            vm.isSaving = false;

        }

        function save() {
            vm.isSaving = true;
            if (vm.nurse.id !== null) {
                Nurse.update(vm.nurse, onSaveSuccess, onSaveError);
            } else {
                Nurse.save(vm.nurse, onSaveSuccess, onSaveError);
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
