(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('OrderPaymentController', OrderPaymentController);

    OrderPaymentController.$inject = ['status', 'Ecom', '$stateParams', '$location', 'Principal', 'AlertService', '$translate'];

    function OrderPaymentController(status, Ecom, $stateParams, $location, Principal, AlertService, $translate) {
        var vm = this;

        vm.status = status;

        Principal.identity().then(function (account) {
            var feedback = {parameters: $location.search()};

            Ecom.update({id: $stateParams.id}, feedback, onSuccess, onError);
        });

        //////////////////////////

        function onSuccess(data) {
            vm.order = data;
        }

        function onError(error) {
            var message = $translate.instant('orderApp.order.feedback.problem');
            AlertService.error(message + ": " + error.data.message);
        }


    }
})();
