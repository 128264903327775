(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('SalesRepDetailController', SalesRepDetailController);

    SalesRepDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SalesRep', 'Customer', 'Order', 'pagingParams'];

    function SalesRepDetailController($scope, $rootScope, $stateParams, previousState, entity, SalesRep, Customer, Order, pagingParams) {
        var vm = this;

        vm.salesRep = entity;
        vm.activeTab = $stateParams.tab * 1;
        vm.previousState = previousState.name;
        vm.pagingParams = pagingParams;

        vm.selectTab = selectTab;
        vm.selectedTab = selectedTab;
        vm.transition = transition;


        var unsubscribe = $rootScope.$on('webportalApp:salesRepUpdate', function (event, result) {
            vm.salesRep = result;
        });
        $scope.$on('$destroy', unsubscribe);


        ////////////////////////


        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.center.id,
                tab: vm.activeTab,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, {location: 'replace'});
        }

        function selectTab(tabName) {
            if (tabName !== vm.activeTab) {
                if (0 === tabName) {
                    vm.predicate = 'code';
                } else {
                    vm.predicate = 'id';
                }
                vm.activeTab = tabName;
            }
            //vm.loadAll();
        }

        function selectedTab(id) {
            return vm.activeTab;
        }
    }
})();
