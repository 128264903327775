(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartDescription', reportDetailPartDescription)
        .controller('ReportDetailPartDescription', ReportDetailPartDescription);

    ReportDetailPartDescription.$inject = ['PartResource'];

    function reportDetailPartDescription() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                reportPart: '=',
                visitId: '='
            },
            templateUrl: 'app/entities/part/report-detail-part-description.html',
            controller: 'ReportDetailPartDescription',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function ReportDetailPartDescription(PartResource) {
        var vm = this;

        vm.updatePart = updatePart;

        ////////////

        function updatePart() {
            PartResource.update({
                visitId: vm.visitId,
                type: vm.reportPart.type.name,
                product: vm.reportPart.product
            }, vm.reportPart);
        }
    }
})();
