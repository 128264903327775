(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldEBookUrls', eBookUrls)
        .controller('EBookUrlsController', EBookUrlsController);

    EBookUrlsController.$inject = ['$log', '$http'];

    function eBookUrls() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {},
            template: '<span ng-repeat="url in vm.ebookUrls"><a class="btn btn-link" ng-click="vm.download(url)"><i class="fa fa-download"></i> {{url.type}}</a> </span>',
            controller: 'EBookUrlsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /*scope.$watch('vm.caseStudyOption', function (newVal) {
             scope.vm.prepareBanners(newVal);
             }, true);*/
        }
    }

    function EBookUrlsController($log, $http) {
        var vm = this;

        vm.ebookUrls = [
            {'type': 'pdf', 'hash': 'Ym9va3MtRlJJcGFydDEucGRm'}
            //, {'type': 'mobi', 'hash': 'Ym9va3MtRlJJcGFydDEubW9iaQ=='}
            , {'type': 'epub', 'hash': 'Ym9va3MtRlJJcGFydDEuZXB1Yg=='}
        ];

        vm.download = download;


        ///////////////////////////////////////

        function download(item) {
            var url = 'media/0.1/books/book.' + item.type + '?hash=' + item.hash;
            $http.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    'accept': 'application/' + item.type + ';q=0.9,*/*;q=0.8'
                }
            }).then(onSuccess, onError);
        }

        function onError(error) {
            $log.debug(error);
        }

        function onSuccess(result) {
            var blob = new Blob([result.data]);
            var blobURL = URL.createObjectURL(blob);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, result.headers('content-disposition'));
            } else {
                var a = document.createElement('a');
                a.href = blobURL;
                a.download = result.headers('content-disposition');
                a.target = '_self';
                document.body.appendChild(a);
                a.click();
                //URL.revokeObjectURL(blobURL);
            }
        }

    }
})();
