(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('DoctorDialogController', DoctorDialogController);

    DoctorDialogController.$inject = ['$scope', '$uibModalInstance', '$q', 'entity', 'Doctor', 'User', 'Center'];

    function DoctorDialogController($scope, $uibModalInstance, $q, entity, Doctor, User, Center) {
        var vm = this;
        // Drop down list shows all centers
        vm.centers = Center.query({'size': 10000});
        vm.doctor = entity;

        vm.load = load;
        vm.save = save;
        vm.clear = clear;

        vm.users = User.query({'unlinked': true, 'role': 'ROLE_DOCTOR_RESEARCHER', 'size': 10000}); //TODO: multiple roles
        $q.all([vm.doctor.$promise, vm.users.$promise]).then(function () {
            if (entity.user) {
                // on edit: add current user to dropdown-box of users
                vm.users.push(entity.user);
            }
        });

        ////////////

        function load(id) {
            Doctor.get({id: id}, function (result) {
                vm.doctor = result;
            });

        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:doctorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError() {
            vm.isSaving = false;

        }

        function save() {
            vm.isSaving = true;
            if (vm.doctor.id !== null) {
                Doctor.update(vm.doctor, onSaveSuccess, onSaveError);
            } else {
                Doctor.save(vm.doctor, onSaveSuccess, onSaveError);
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
