(function() {
    'use strict';

    angular
        .module('webportalApp')
        .factory('FeedbackResource', FeedbackResource);

    FeedbackResource.$inject = ['$resource'];

    function FeedbackResource($resource) {
        var service = $resource('api/feedback', {}, {
            'save': { method: 'POST'}
        });

        return service;
    }
})();

