(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadPatientController', UploadPatientController);

    UploadPatientController.$inject = ['patient', 'UploadSession', 'Patient', 'Center', 'Principal', '$scope', '$translate', '$state', '$location', '$stateParams'];

    function UploadPatientController(patient, UploadSession, Patient, Center, Principal, $scope, $translate, $state, $location, $stateParams) {
        var vm = this;

        vm.activeTab = 0;
        vm.centerRequired = Principal.hasAnyAuthority(["ROLE_DATA_MANAGER"]);
        vm.patient = patient;
        vm.selectTab = selectTab;
        vm.selectedTab = selectedTab;
        vm.select = select;
        vm.save = save;
        vm.checkUniqueExternalId = checkUniqueExternalId;

        activate();

        //////////

        function activate() {
            Center.query({size: 500}).$promise
                .then(saveOnVM('centers'))
                .then(initNewPatient);

            preparePatientsDropDown();
        }

        function preparePatientsDropDown() {
            Patient.query({size: 500, sort: 'createdDate,desc'}).$promise
                .then(saveOnVM('patients'))
                .then(addDisplayProperties)
                .then(uniqueExternalIds)
                .then(saveOnVM('uniqueExternalIds'));
        }

        function addDisplayProperties(patients) {
            patients.forEach(addDisplayProperty);

            return patients;
        }

        function addDisplayProperty(patient) {
            var ethnicity = $translate.instant('webportalApp.Race.' + patient.race);
            var gender = $translate.instant('webportalApp.Gender.' + patient.sex);
            if (Principal.hasAnyAuthority(["ROLE_DOCTOR_MEDDEV"])) {
                patient['display'] = (patient.externalId ? patient.externalId : 'n/a');
            } else {
                patient['display'] = (patient.externalId ? patient.externalId : 'n/a') + ': ' + ethnicity + ' - ' + gender;
            }
        }

        function initNewPatient(centers) {
            if (Principal.hasAnyAuthority(["ROLE_DOCTOR_MEDDEV"])) {
                vm.newPatient = {type: 'anonymous', sex: "FEMALE", race: "MALAYAN"};
            }
            if (centers.length == 1) {
                vm.newPatient.center = centers[0];
            }
        }

        function selectTab(tabName) {
            if (tabName !== vm.activeTab) {
                vm.activeTab = tabName;
            }
        }

        function selectedTab(id) {
            return vm.activeTab;
        }

        function select() {
            setAndGoNext(vm.patient);
        }

        function save() {
            vm.isSaving = true;
            Patient.save(vm.newPatient, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:patientUpdate', result);

            vm.isSaving = false;

            setAndGoNext(result);
        }

        function setAndGoNext(patient) {
            UploadSession.setPatient(patient)
                .then(function () {
                    $state.go('upload.visit', {patientId: patient.id, visitId: $stateParams.visitId});
                });
        }


        function onSaveError() {

            vm.isSaving = false;
        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            };
        }

        function uniqueExternalIds(patients) {
            return patients
                .map(function (patient) {
                    return {externalId: patient.externalId, centerId: patient.center.id};
                });
        }

        function checkUniqueExternalId(editForm) {
            if (vm.newPatient.externalId) {
                var filtered = vm.uniqueExternalIds.filter(function (patientCenter) {
                    if (vm.newPatient.center) {
                        return patientCenter.externalId === vm.newPatient.externalId && patientCenter.centerId === parseInt(vm.newPatient.center.id);
                    } else {
                        return patientCenter.externalId === vm.newPatient.externalId;
                    }
                });
                var unique = filtered.length === 0;

                editForm.externalId.$setValidity('uniqueExternalId', unique);
            }
        }
    }
})();
