(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailVisitGraph', reportDetailVisitGraph)
        .controller('ReportDetailVisitGraphController', ReportDetailVisitGraphController);

    ReportDetailVisitGraphController.$inject = ['ReportDetailPartHelperService', 'ReportHelper', '$translate'];

    function reportDetailVisitGraph() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                report: '=',
                partName: '@',
                yAxis: '@',
                zones: '@'
            },
            templateUrl: 'app/entities/report/report-detail-visit-graph.html',
            controller: 'ReportDetailVisitGraphController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope) {
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.prepareGraph(scope.vm.partName, newValue);
                }
            }, true);
        }
    }

    function ReportDetailVisitGraphController(ReportDetailPartHelperService, ReportHelper, $translate) {
        var vm = this;

        vm.graph = {};
        vm.zoneArray = vm.zones.split(',');
        var shapes = {
            'UL': 'triangle-up',
            'LL': 'circle',
            'CENTRAL': 'circle'
        };


        window.onafterprint = function () {
            //console.debug('afterPrint');
            vm.api.refresh();
        };

        vm.prepareGraph = prepareGraph;

        /////////////////////


        function extractDataPoints(partName, report) {
            var lungFunctionPart = ReportHelper.getPart(report, 'LUNG_FUNCTION');
            var dataPoints = [];

            if (lungFunctionPart && lungFunctionPart.entries) {
                lungFunctionPart.entries.filter(function (item) {
                    return item.type.name === 'FVC_PRO_PRED';
                }).forEach(function (item) {
                    dataPoints.push(item);
                });
            }

            var part = ReportHelper.getPart(report, partName);
            if (part && part.zoneEntries) {
                part.zoneEntries
                    .filter(filterItem).forEach(function (item) {
                    dataPoints.push(item);
                });
            }

            return dataPoints;
        }

        function filterItem(item) {
            return vm.zoneArray.indexOf(item.zone) > -1;
        }

        function prepareGraph(partName, report) {
            var dataPoints = extractDataPoints(partName, report);
            if (dataPoints.length === vm.zoneArray.length + 1) {
                generateGraphOptionsAndData(dataPoints);
            } else {
                console.warn('Not enough dataPoints available for generating graph: ' + vm.zoneArray.length);
                console.debug(dataPoints);
            }
        }

        /**
         * If 2 or more lungZones need to be displayed on the same graph, the breakpoint values need to be normalized:
         *  - normal is never really 100%
         *  - lowLow, low, high and highHigh are different for each lungZone
         */
        function normalizeDataPoints(dataPoints) {

            var fvcDataPoint = dataPoints[0].deviationEntry;
            var yNormal = vm.zoneArray.length === 1 ? dataPoints[1].deviationEntry.normal : 100;

            var normalizedDataPoints = {
                x: {
                    min: 0,
                    max: fvcDataPoint.highHigh,
                    normal: fvcDataPoint.normal,
                    lowLow: fvcDataPoint.lowLow,
                    highHigh: fvcDataPoint.highHigh,
                    value: fvcDataPoint.value,
                    code: fvcDataPoint.code,
                },
                y: {
                    min: 0,
                    max: 100,
                    normal: yNormal,
                    lowLow: -1,
                    highHigh: 9999
                }
            };

            var extraSpacing = 15;
            if (normalizedDataPoints.x.max < normalizedDataPoints.x.value + extraSpacing) {
                normalizedDataPoints.x.max = normalizedDataPoints.x.value + extraSpacing;
            }

            vm.zoneArray.forEach(function (zone, index) {
                var dataPoint = dataPoints[index + 1].deviationEntry;

                normalizedDataPoints.y[zone + 'Code'] = dataPoint.code;

                var normalDelta = yNormal - dataPoint.normal;
                normalizedDataPoints.y[zone + 'Value'] = dataPoint.value + normalDelta;

                //keep safest breakpoints LL and HH
                if (normalizedDataPoints.y.lowLow < dataPoint.lowLow + normalDelta) {
                    normalizedDataPoints.y.lowLow = dataPoint.lowLow + normalDelta;
                }
                if (normalizedDataPoints.y.highHigh > dataPoint.highHigh + normalDelta) {
                    normalizedDataPoints.y.highHigh = dataPoint.highHigh + normalDelta;
                }

                //set graph max
                normalizedDataPoints.y.max = normalizedDataPoints.y.highHigh;
                if (normalizedDataPoints.y.max < normalizedDataPoints.y[zone + 'Value'] + extraSpacing) {
                    normalizedDataPoints.y.max = normalizedDataPoints.y[zone + 'Value'] + extraSpacing;
                }
            });

            return normalizedDataPoints;
        }

        function generateGraphOptionsAndData(dataPoints) {

            var normalizedDataPoints = normalizeDataPoints(dataPoints);

            //console.debug(normalizedDataPoints);

            vm.graph.options = {
                chart: {
                    type: 'multiChart',
                    height: 170,
                    width: 340,
                    margin: {
                        top: 5,
                        right: 90,
                        bottom: 20,
                        left: 100
                    },
                    color: colors(normalizedDataPoints),
                    //useInteractiveGuideline: true,
                    duration: 500,
                    xAxis: {
                        axisLabel: 'FVC (%p)',
                        tickFormat: tickFormat,
                        domain: xDomain(normalizedDataPoints),
                        tickValues: xTickValues(normalizedDataPoints),
                        showMaxMin: false
                    },
                    yAxis1: {
                        axisLabel: vm.yAxis,
                        axisLabelDistance: '-15',
                        tickFormat: tickFormat,
                        tickValues: yTickValues(normalizedDataPoints),
                        showMaxMin: false,
                        rotateYLabel: false
                    },
                    showLegend: false,
                    tooltip: {
                        enabled: true,
                        contentGenerator: generateTooltip
                    },
                    yDomain: yDomain(normalizedDataPoints),
                    scatters1: {
                        xDomain: xDomain(normalizedDataPoints),
                        yDomain: yDomain(normalizedDataPoints)
                    },
                    callback: function () {
                        var yAxisLabels = d3.selectAll('.nv-y1 .nv-axislabel');
                        //console.debug(yAxisLabels);
                        yAxisLabels.attr('y', 10);
                        yAxisLabels.attr('x', -30);
                        //console.debug(yAxisLabels.attr('y'));

                        var xAxisLabels = d3.selectAll('.nv-x .nv-axislabel');
                        //console.debug(yAxisLabels);
                        xAxisLabels.attr('y', 0);
                        xAxisLabels.attr('x', 185);
                    }
                }
            };

            vm.graph.data = generateData(normalizedDataPoints);

            console.debug('generateGraphOptionsAndData Done');
        }

        function generateTooltip(obj) {
            if (vm.zoneArray.indexOf(obj.series[0].key) > -1) {
                //console.debug(obj);
                return '<div class="chart-tooltip">' +
                    '<p class="title" style="font-weight: bolder;border-bottom: 1px solid black">' + $translate.instant('reportApp.report.medDev.friParams.' + obj.series[0].key) + '</p>' +
                    '<p>FVC: ' + (Math.round(obj.point.x * 100.00) / 100) + '</p>' +
                    '<p>' + $translate.instant('webportalApp.PartType.' + vm.partName + '.symbol') + ': ' + (Math.round(obj.point.y * 100.00) / 100) + '</p>' +
                    '<div>';
            }
        }

        function generateData(normalizedDataPoints) {

            var data = [];

            vm.zoneArray.forEach(function (zone) {
                data.push(lungZoneDataPoints(normalizedDataPoints, zone));
            });

            data = data.concat(normalLines(normalizedDataPoints));
            data = data.concat(zones(normalizedDataPoints));

            //console.debug(data);
            return data;
        }

        function lungZoneDataPoints(normalizedDataPoints, zone) {

            return {
                type: 'scatter',
                key: zone,
                yAxis: 1,
                values: [{
                    x: normalizedDataPoints.x.value,
                    y: normalizedDataPoints.y[zone + 'Value'],
                    shape: shapes[zone]
                }]
            };
        }

        function normalLines(normalizedDataPoints) {

            var normalLines = [];

            normalLines.push({
                type: 'line',
                key: 'normal line',
                yAxis: 1,
                values: [
                    {x: 0, y: normalizedDataPoints.y.normal},
                    {x: normalizedDataPoints.x.max, y: normalizedDataPoints.y.normal}]
            });

            normalLines.push({
                type: 'line',
                key: 'normal line FVC',
                yAxis: 1,
                values: [
                    {x: normalizedDataPoints.x.normal, y: 0},
                    {x: normalizedDataPoints.x.normal, y: normalizedDataPoints.y.max}]
            });

            return normalLines;
        }

        function zones(normalizedDataPoints) {
            var zones = [];

            var xZones = [{from: normalizedDataPoints.x.min, to: normalizedDataPoints.x.lowLow}];
            if (normalizedDataPoints.x.max > normalizedDataPoints.x.highHigh) {
                xZones.push({from: normalizedDataPoints.x.lowLow + 0.09, to: normalizedDataPoints.x.highHigh - 0.09});
                xZones.push({from: normalizedDataPoints.x.highHigh, to: normalizedDataPoints.x.max});
            } else {
                xZones.push({from: normalizedDataPoints.x.lowLow + 0.09, to: normalizedDataPoints.x.max});
            }
            //console.debug('xZones');
            //console.debug(xZones);

            var yZones = [{delta: normalizedDataPoints.y.lowLow - normalizedDataPoints.y.min}];
            if (normalizedDataPoints.y.max > normalizedDataPoints.y.highHigh) {
                yZones.push({delta: normalizedDataPoints.y.highHigh - normalizedDataPoints.y.lowLow});
                yZones.push({delta: normalizedDataPoints.y.max - normalizedDataPoints.y.highHigh});
            } else {
                yZones.push({delta: normalizedDataPoints.y.max - normalizedDataPoints.y.lowLow + 0.09});
            }

            //console.debug('yZones');
            //console.debug(yZones);

            var zone = {
                type: 'area',
                key: 'LL',
                yAxis: 1,
                values: [
                    {x: xZones[0].from, y: yZones[0].delta},
                    {x: xZones[0].to, y: yZones[0].delta},
                    {x: xZones[1].from, y: 0},
                    {x: xZones[1].to, y: 0}
                ]
            };
            if (xZones[2]) {
                zone.values.push({x: xZones[2].from, y: yZones[0].delta});
                zone.values.push({x: xZones[2].to, y: yZones[0].delta});
            }
            zones.push(zone);

            zone = {
                type: 'area',
                key: 'L',
                yAxis: 1,
                values: [
                    {x: xZones[0].from, y: yZones[1].delta},
                    {x: xZones[0].to, y: yZones[1].delta},
                    {x: xZones[1].from, y: yZones[0].delta},
                    {x: xZones[1].to, y: yZones[0].delta}
                ]
            };
            if (xZones[2]) {
                zone.values.push({x: xZones[2].from, y: yZones[1].delta});
                zone.values.push({x: xZones[2].to, y: yZones[1].delta});
            }
            zones.push(zone);

            zone = {
                type: 'area',
                key: 'N',
                yAxis: 1,
                values: [
                    {x: xZones[0].from, y: 0},
                    {x: xZones[0].to, y: 0},
                    {x: xZones[1].from, y: yZones[1].delta},
                    {x: xZones[1].to, y: yZones[1].delta}
                ]
            };
            if (xZones[2]) {
                zone.values.push({x: xZones[2].from, y: 0});
                zone.values.push({x: xZones[2].to, y: 0});
            }
            zones.push(zone);

            if (yZones[2]) {
                zone = {
                    type: 'area',
                    key: 'H',
                    yAxis: 1,
                    values: [
                        {x: xZones[0].from, y: 0},
                        {x: xZones[0].to, y: 0},
                        {x: xZones[1].from, y: yZones[2].delta},
                        {x: xZones[1].to, y: yZones[2].delta}
                    ]
                };
                if (xZones[2]) {
                    zone.values.push({x: xZones[2].from, y: 0});
                    zone.values.push({x: xZones[2].to, y: 0});
                }
                zones.push(zone);

                zone = {
                    type: 'area',
                    key: 'HH',
                    yAxis: 1,
                    values: [
                        {x: xZones[0].from, y: yZones[2].delta},
                        {x: xZones[0].to, y: yZones[2].delta},
                        {x: xZones[1].from, y: 0},
                        {x: xZones[1].to, y: 0}
                    ]
                };
                if (xZones[2]) {
                    zone.values.push({x: xZones[2].from, y: yZones[2].delta});
                    zone.values.push({x: xZones[2].to, y: yZones[2].delta});
                }
                zones.push(zone);
            }
            return zones;
        }


        function xTickValues(normalizedDataPoints) {
            return tickValues(normalizedDataPoints.x.max);
        }

        function yTickValues(normalizedDataPoints) {
            return tickValues(normalizedDataPoints.y.max);
        }

        function tickValues(max) {
            var tickValues = [];
            var increment = max < 140 ? 20 : max < 220 ? 25 : 50;
            for (var current = 0; current < max; current += increment) {
                tickValues.push(current);
            }
            return tickValues;
        }

        function xDomain(normalizedDataPoints) {
            return [normalizedDataPoints.x.min, normalizedDataPoints.x.max];
        }

        function yDomain(normalizedDataPoints) {
            return [normalizedDataPoints.y.min, normalizedDataPoints.y.max];
        }

        function tickFormat(d) {
            return d3.format('.f')(d);
        }

        function colors(normalizedDataPoints) {
            var defaultColor = 'rgb(102, 102, 102)';
            var warningColor = 'rgb(255, 0, 0)';
            var hundredPercentColor = 'rgb(211,211,211)';
            var normalColor = 'rgba(0, 255, 0, 0.2)';
            var lowColor = 'rgba(255, 255, 0, 0.2)';
            var lowLowColor = 'rgba(255, 0, 0, 0.2)';

            var colors = [];
            vm.zoneArray.forEach(function (zone) {
                if ((normalizedDataPoints.x.code === 'HH' || normalizedDataPoints.x.code === 'LL') &&
                    (normalizedDataPoints.y[zone + 'Code'] === 'HH' || normalizedDataPoints.y[zone + 'Code'] === 'LL')) {
                    colors.push(warningColor);
                } else {
                    colors.push(defaultColor);
                }
            });

            colors.push(hundredPercentColor, hundredPercentColor, lowLowColor, lowColor, normalColor, lowColor, lowLowColor);

            return colors;
        }
    }
})();
