(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartTitle', reportDetailPartAirwayResistanceData)
        .controller('ReportDetailPartTitleController', ReportDetailPartTitleController);

    ReportDetailPartTitleController.$inject = ['ReportDetailPartHelperService', 'PartResource', 'ReportHelper'];

    function reportDetailPartAirwayResistanceData() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                partName: '@',
                visitId: '=',
                category: '='
            },
            templateUrl: 'app/entities/part/report-detail-part-title.html',
            controller: 'ReportDetailPartTitleController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newValue) {
                if (newValue) {
                    if (newValue.type) {
                        if (newValue.type.name) {
                            scope.vm.prepare(newValue.type.name);
                        } else {
                            scope.vm.prepare(newValue.type);
                        }
                    }
                }
            }, true);
        }
    }

    function ReportDetailPartTitleController(ReportDetailPartHelperService, PartResource, ReportHelper) {
        var vm = this;

        vm.prepare = prepare;
        vm.updatePart = updatePart;

        prepare(vm.partName);

        ////////////

        function prepare(partName) {
            if (vm.visitId) { //not on compare pages
                vm.model.status = vm.model.status ? vm.model.status : 'NEW';
            }
            if (partName) {
                vm.titleKey = 'webportalApp.PartType.' + partName;
                vm.symbolKey = vm.titleKey + ".symbol";
                vm.imgClass = ReportDetailPartHelperService.stateName(partName);
            }
            vm.partStatuses = ReportHelper.getPartStatuses(vm.category);
        }

        function updatePart() {
            PartResource.update({
                visitId: vm.visitId,
                type: vm.model.type.name,
                product: vm.model.product
            }, vm.model);
        }

    }
})();
