(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('ReportDetailPartDataController', ReportDetailPartDataController);

    ReportDetailPartDataController.$inject = ['ReportDetailPartHelperService'];

    function ReportDetailPartDataController(ReportDetailPartHelperService) {
        var vm = this;
        vm.chartOptions = ReportDetailPartHelperService.procentChartOptions;
        vm.chartColors = ReportDetailPartHelperService.procentChartColors;

        vm.prepareCharts = prepareCharts;

        ////////////


        function prepareCharts(reportPart) {
            vm.reportPart = reportPart;
            if (reportPart && reportPart.zoneEntries) {
                reportPart.zoneEntries.forEach(function (entry) {
                    if (entry.percentage) {
                        entry["chartData"] = [entry.percentage, 100 - entry.percentage];
                        entry["chartLabels"] = ['one', 'two'];
                    }
                    ReportDetailPartHelperService.calculateRelativeValue(entry.deviationEntry);
                    ReportDetailPartHelperService.selectClass(entry.deviationEntry);
                });
            }
            vm.precision = 1;
            if (vm.reportPart.type.name === 'SPECIFIC_AIRWAY_RADIUS_TLC') {
                vm.precision = 3;
            }
        }
    }
})();
