(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitDialogController', VisitDialogController);

    VisitDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Visit', 'Report', 'Pathology', 'ReportHelper', '$log', 'Principal'];

    function VisitDialogController($scope, $uibModalInstance, entity, Visit, Report, Pathology, ReportHelper, $log, Principal) {
        var vm = this;

        vm.visit = entity;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;
        vm.confirmationStepDisabled = false;
        vm.currentStep = 'General';

        vm.load = load;
        vm.save = save;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.formErrors = formErrors;

        vm.visit.$promise.then(function () {
            vm.confirmationStepDisabled = vm.visit.status !== 'INCOMPLETE';
            getReport().then(function () {
                vm.parts = ReportHelper.getClinicalParts(vm.report);
            });
            vm.pathologyOptions = Pathology.getPathologyOptions(vm.visit.type, vm.visit.category);
        });

        ////////////

        function getReport() {
            var params = {
                id: vm.visit.id,
                partType: ReportHelper.getClinicalPartNames(vm.visit.type, vm.visit.category)
            };
            return Report.get(params).$promise.then(function(data){
                vm.report = data;
                //$log.debug('Report retrieved');
                return vm.report;
            });
        }

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:visitUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            Visit.update(vm.visit, onSaveSuccess, onSaveError);
            vm.report.category = vm.visit.category;
            Report.update(vm.report);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function formErrors(form) {
            var errors = [];
            for (var key in form.$error) {
                //errors.push(key + "=" + form.$error);
                for (var index = 0; index < form.$error[key].length; index++) {
                    errors.push(form.$error[key][index].$name + ' is required.');
                }
            }
            //$log.debug(vm.currentStep);
            if (errors.length > 0) {
                $log.debug("Form Has Errors");
                $log.debug(errors);
            } else {
                $log.debug("Form Has no Errors; invalid = " + form.$invalid + ": isSaving = " + vm.isSaving);
            }
        }
    }
})();
