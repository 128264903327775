(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitCategoryTag', visitCategoryTag)
        .controller('VisitCategoryTagController', VisitCategoryTagController);

    VisitCategoryTagController.$inject = ['Report', 'Principal'];

    function visitCategoryTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '=',
                category: '=?',
                hideIcon: '@',
                hideLabel: '@',
                clickable: '@'
            },
            templateUrl: 'app/entities/visit/visit-category-tag.html',
            controller: 'VisitCategoryTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.visit', function (newVal) {
                if (newVal) {
                    scope.vm.onVisitChange();
                }
            }, true);
        }
    }

    function VisitCategoryTagController(Report, Principal) {
        var vm = this;
        vm.enableClickThrough = false;

        vm.onVisitChange = onVisitChange;

        ////////////////

        function onVisitChange() {
            if (vm.visit.$promise) {
                vm.visit.$promise.then(updateCategoryAndRetrieveReport);
            } else {
                updateCategoryAndRetrieveReport();
            }
        }

        function updateCategoryAndRetrieveReport() {
            vm.category = vm.visit.category;

            if (vm.category == 'MED_DEV') {
                Report.get({id: vm.visit.id}).$promise.then(function (result) {
                    vm.report = result;

                    vm.enableClickThrough = vm.clickable && (Principal.hasAnyAuthority(['ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER']) || correctReportStatus());
                });
            }
        }

        function correctReportStatus() {
            return vm.report.status != 'INCOMPLETE' && vm.report.status != 'UPLOADED' && vm.report.status != 'VERIFIED';
        }
    }
})();
