(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldUploadPatientVisitHeaderTag', uploadPatientVisitHeaderTag)
        .controller('UploadPatientVisitHeaderTagController', UploadPatientVisitHeaderTagController);

    UploadPatientVisitHeaderTagController.$inject = ['$state', '$stateParams'];

    function uploadPatientVisitHeaderTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                visit: '=',
                newPatient: '=',
                newVisit: '='
            },
            templateUrl: 'app/entities/upload/upload-patient-visit-header-tag.html',
            controller: 'UploadPatientVisitHeaderTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function UploadPatientVisitHeaderTagController($state, $stateParams) {
        var vm = this;

        vm.goToPatient = goToPatient;
        vm.goToVisit = goToVisit;

        ////////////////

        function goToPatient() {
            $state.go('upload.patient', $stateParams);
        }

        function goToVisit() {
            $state.go('upload.visit', $stateParams);
        }

    }
})();
