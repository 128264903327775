(function() {
    'use strict';

    angular
        .module('webportalApp')
        .controller('OrderDialogController', OrderDialogController);

    OrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Order', 'Payment', 'Invoice', 'Item', 'Customer'];

    function OrderDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Order, Payment, Invoice, Item, Customer) {
        var vm = this;

        vm.customerOrder = entity;
        vm.clear = clear;
        vm.save = save;
        vm.payments = Payment.query({filter: 'order-is-null'});
        $q.all([vm.customerOrder.$promise, vm.payments.$promise]).then(function() {
            if (!vm.customerOrder.payment || !vm.customerOrder.payment.id) {
                return $q.reject();
            }
            return Payment.get({id : vm.customerOrder.payment.id}).$promise;
        }).then(function(payment) {
            vm.payments.push(payment);
        });
        vm.invoices = Invoice.query({filter: 'order-is-null'});
        $q.all([vm.customerOrder.$promise, vm.invoices.$promise]).then(function() {
            if (!vm.customerOrder.invoice || !vm.customerOrder.invoice.id) {
                return $q.reject();
            }
            return Invoice.get({id : vm.customerOrder.invoice.id}).$promise;
        }).then(function(invoice) {
            vm.invoices.push(invoice);
        });
        vm.items = Item.query();
        vm.customers = Customer.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customerOrder.id !== null) {
                Order.update(vm.customerOrder, onSaveSuccess, onSaveError);
            } else {
                Order.save(vm.customerOrder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('webportalApp:customerOrderUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
