(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Pathology', Pathology);

    Pathology.$inject = ['DateUtils'];

    function Pathology(DateUtils) {
        var pathologyOptions = [
            {key: 'ASTHMA', value: 'webportalApp.Pathology.ASTHMA', type: 'PNEUMO'},
            {key: 'COPD', value: 'webportalApp.Pathology.COPD', type: 'PNEUMO'},
            {key: 'IPF', value: 'webportalApp.Pathology.IPF', type: 'PNEUMO'},
            {key: 'CF', value: 'webportalApp.Pathology.CF', type: 'PNEUMO'},
            {key: 'CANCER', value: 'webportalApp.Pathology.CANCER', type: 'PNEUMO'},
            {key: 'OTHER', value: 'webportalApp.Pathology.OTHER', type: 'PNEUMO'},
            {key: 'OSA', value: 'webportalApp.Pathology.OSA', type: 'SLEEP'},
            {key: 'SNORRING', value: 'webportalApp.Pathology.SNORRING', type: 'SLEEP'},
            {key: 'OTHER', value: 'webportalApp.Pathology.OTHER', type: 'SLEEP'},
            {key: 'OTHER', value: 'webportalApp.Pathology.OTHER', type: 'TRANSPLANT'}
        ];
        return {
            pathologyOptions: pathologyOptions,
            getPathologyOptions: getPathologyOptions
        };

        ////////////////

        function getPathologyOptions(type, category) {
            var filteredByType = pathologyOptions.filter(function (item) {
                return item.type == type;
            });
            if (category == 'RESEARCH') {
                return filteredByType
            } else {
                return pathologyOptions.filter(function (item) {
                    return item.key == 'IPF';
                });
            }
        }
    }
})();
