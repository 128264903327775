(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadConfirmController', UploadConfirmController);

    UploadConfirmController.$inject = ['patient', 'visit', '$scope', 'VisitHelper', 'ReportHelper', 'Report', 'VisitStatusResource', 'MediaEventResource', 'UploadSession', '$state', 'UploadService'];

    function UploadConfirmController(patient, visit, $scope, VisitHelper, ReportHelper, Report, VisitStatusResource, MediaEventResource, UploadSession, $state, UploadService) {
        var vm = this;

        vm.visit = visit;
        vm.patient = patient;
        vm.confirmEnabled = UploadSession.getUploadSuccess();

        vm.save = save;

        activate();

        //////////

        function activate() {

            if (!UploadSession.sessionId) {
                //$state.go('upload.init');
            }

            VisitHelper.retrievePatient(vm.patient, vm.visit)
                .then(saveOnVM('patient'));

            VisitHelper.retrieveReport(visit)
                .then(saveOnVM('report'))
                .then(ReportHelper.getClinicalParts)
                .then(saveOnVM('parts'));

            $scope.$on('webportalApp:dicomUploaded', function () {
                console.debug('message received');
                vm.confirmEnabled = true;
            });
        }

        function save() {
            vm.isSaving = true;

            var lastChunck = UploadSession.getLastChunck();
            lastChunck.visitId = vm.visit.id;
            lastChunck.patientId = vm.patient.id;
            UploadService.finishFile({}, lastChunck).$promise.then(function () {
                VisitStatusResource.update({
                    'visitId': vm.visit.id,
                    'value': 'UPLOADED'
                }, {}).$promise.then(function () {
                    MediaEventResource.create({}, {
                        'type': 'DICOM_UPLOADED',
                        'content': vm.visit.id
                    }).$promise.then(function () {
                        UploadSession.sessionEnded();
                        $state.go('patient-detail', {id: vm.patient.id});
                    });
                });
            })

        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            }
        }
    }
})();
