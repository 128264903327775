(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitTypeTag', visitTypeTag)
        .controller('VisitTypeTagController', VisitTypeTagController);

    //VisitTypeTagController.$inject = [];

    function visitTypeTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                type: '=',
                hideIcon: '@',
                hideLabel: '@'
            },
            templateUrl: 'app/entities/visit/visit-type-tag.html',
            controller: 'VisitTypeTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function VisitTypeTagController() {
        var vm = this;

        ////////////////
    }
})();
