(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CaseStudyHomeController', CaseStudyHomeController);

    CaseStudyHomeController.$inject = ['Pathology'];

    function CaseStudyHomeController(Pathology) {
        var vm = this;

        vm.pathologyOptions = Pathology.pathologyOptions;

    }
})();
