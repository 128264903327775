(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PatientDialogController', PatientDialogController);

    PatientDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Patient', 'Center', 'Principal', 'VisitCategory', '$log', 'Visit', '$timeout'];

    function PatientDialogController($scope, $uibModalInstance, entity, Patient, Center, Principal, VisitCategory, $log, Visit, $timeout) {
        var vm = this;
        vm.patient = entity;
        vm.centerRequired = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.birthDate = false;
        vm.isSaving = false;

        vm.load = load;
        vm.save = save;
        vm.clear = clear;
        vm.formErrors = formErrors;
        vm.checkUniqueExternalId = checkUniqueExternalId;

        activate();

        ////////////

        function load(id) {
            Patient.get({id: id}, function (result) {
                vm.patient = result;
            });
        }

        function activate() {
            if (vm.patient.$promise) {
                vm.patient.$promise.then(activatePatient);
            } else {
                activatePatient(vm.patient);
            }

            Principal.hasAuthority('ROLE_DOCTOR_RESEARCHER').then(function (result) {
                vm.birthDateRequired = result && vm.patient.type === 'normal';
            });
            Center.query({size: 500}).$promise
                .then(saveOnVM('centers'));
            Patient.query({size: 500}).$promise
                .then(uniqueExternalIds)
                .then(saveOnVM('uniqueExternalIds'));
        }

        function activatePatient(patient) {
            vm.patient.type = initType(patient);

            if (patient.id) {
                Visit.query({
                    patientId: patient.id,
                    page: 0,
                    size: 1
                }).$promise.then(function (visits) {
                    vm.hasVisits = visits.length > 0;
                });
            } 

            else if (Principal.hasAnyAuthority(["ROLE_DOCTOR_MEDDEV"])) {
                vm.patient.race = "MALAYAN";
                vm.patient.sex = "FEMALE";
            }

            else {
                vm.hasVisits = false;
            }

        }

        function initType(patient) {
            var type = 'anonymous';
            if (patient.info && patient.info.user) {
                type = 'normal';
            }
            return type;
        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:patientUpdate', result);

            $uibModalInstance.close(result);
            vm.isSaving = false;
        }


        function onSaveError() {

            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            var patientCopy = vm.patient;
            if (vm.patient.type === 'casestudy') {
                patientCopy.caseStudy = {status: 'INCOMPLETE'};
            } else if (vm.patient.type === 'anonymous') {
                if (patientCopy.info) {
                    patientCopy.info.user = undefined;
                }
            }
            if (patientCopy.id !== null) {
                Patient.update(patientCopy, onSaveSuccess, onSaveError);
            } else {
                Patient.save(patientCopy, onSaveSuccess, onSaveError);
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');

        }

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function formErrors(form) {
            var errors = [];
            $log.debug(form.$error);
            for (var key in form.$error) {
                //errors.push(key + "=" + form.$error);
                for (var index = 0; index < form.$error[key].length; index++) {
                    errors.push(form.$error[key][index].$name + ' is required.');
                }
            }
            //$log.debug(vm.currentStep);
            if (errors.length > 0) {
                $log.debug('Form Has Errors');
                $log.debug(errors);
            } else {
                $log.debug('Form Has no Errors; invalid = ' + form.$invalid + ': isSaving = ' + vm.isSaving);
            }
        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            };
        }

        function uniqueExternalIds(patients) {
            return patients
                .map(function (patient) {
                    return {externalId: patient.externalId, centerId: patient.center.id};
                });
        }

        function checkUniqueExternalId() {
            if (vm.patient.externalId) {
                var filtered = vm.uniqueExternalIds.filter(function (patientCenter) {
                    if (vm.patient.center) {
                        return patientCenter.externalId === vm.patient.externalId && patientCenter.centerId === parseInt(vm.patient.center.id);
                    } else {
                        return patientCenter.externalId === vm.patient.externalId;
                    }
                });
                var unique = filtered.length === 0;

                $scope.editForm.externalId.$setValidity('unique', unique);
            }
        }
    }
})();
