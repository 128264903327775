(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldRowLink', fldRowLink);

    fldRowLink.$inject = ['Principal'];

    function fldRowLink(Principal) {
        var directive = {
            restrict: 'A',
            link: link
        };
        return directive;

        function link($scope, elem, attrs) {

            var target = findTarget(elem);
            if (target) {
                elem.addClass("hand");

                var colums = angular.element(elem[0].querySelectorAll('td'));
                for (var i = 0; i < colums.length; i++) {
                    var td = colums[i];
                    if (td.className.indexOf("row-link-skip") !== -1 || td.className.indexOf("row-link-target") !== -1) {
                        //console.log("skip");
                    } else {
                        //console.log(td);
                        var tdElement = angular.element(td);
                        tdElement.bind("click", function (e) {
                            //console.log(target);
                            target.click();
                        });
                    }
                }
            }
        }

        function findTarget(element) {
            var linkTargets = element[0].querySelectorAll('.row-link-target');
            if (linkTargets.length > 0) {
                for (var i = 0; i < linkTargets.length; i++) {
                    var target = linkTargets[i];
                    if (target.getAttribute("has-any-authority") && target.getAttribute("has-any-authority").length > 0) {
                        //console.log('With authorities: '+target.getAttribute("has-any-authority"));
                        var authorities = target.getAttribute("has-any-authority").replace(/\s+/g, '').split(',');
                        if (Principal.hasAnyAuthority(authorities)) {
                            return angular.element(target);
                        } else {
                            //console.debug('Wrong authorities:' + authorities);
                        }
                    } else {
                        //console.debug('Without authorities');
                        return angular.element(target);
                    }
                }
            }
            return null;
        }


    }
}());
