(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartDataSwitch', reportDetailPartDataSwitch)
        .controller('ReportDetailPartDataSwitchController', ReportDetailPartDataSwitchController);


    ReportDetailPartDataSwitchController.$inject = [];

    function reportDetailPartDataSwitch() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                report: '='
            },
            templateUrl: 'app/entities/part/report-detail-part-data-switch.html',
            controller: 'ReportDetailPartDataSwitchController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newValue) {
                if (newValue) {

                }
            }, true);
        }
    }


    function ReportDetailPartDataSwitchController() {
        var vm = this;
        ////////////


    }
})();
