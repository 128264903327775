(function() {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Ecom', Ecom);

    Ecom.$inject = ['$resource'];

    function Ecom ($resource) {
        var resourceUrl =  'order/api/0.1/ecom/orders/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
