(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailLungFunction', reportDetailLungFunction)
        .controller('ReportDetailLungFunctionController', ReportDetailLungFunctionController);

    ReportDetailLungFunctionController.$inject = ['ReportDetailPartHelperService'];

    function reportDetailLungFunction() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                lungFunctions: '='
            },
            templateUrl: 'app/entities/report/report-detail-lung-function.html',
            controller: 'ReportDetailLungFunctionController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function ReportDetailLungFunctionController(ReportDetailPartHelperService) {
        var vm = this;


        /////////////////////


    }
})();
