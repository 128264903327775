(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CenterDetailController', CenterDetailController);

    CenterDetailController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'entity', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Center', 'Patient', 'Doctor'];

    function CenterDetailController($scope, $rootScope, $state, $stateParams, entity, ParseLinks, AlertService, pagingParams, paginationConstants, Center, Patient, Doctor) {
        var vm = this;
        vm.center = entity;
        vm.activeTab = $stateParams.tab * 1;
        vm.reverse = pagingParams.ascending;
        vm.predicate = pagingParams.predicate;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.selectTab = selectTab;
        vm.selectedTab = selectedTab;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.load = load;

        var unsubscribe = $rootScope.$on('webportalApp:centerUpdate', function (event, result) {
            vm.center = result;
        });
        $scope.$on('$destroy', unsubscribe);

        ////////////

        function load(id) {
            Center.get({id: id}, function (result) {
                vm.center = result;
            });
        }

        function loadAll() {
            vm.center.$promise.then(function () {
                if (0 === vm.activeTab) {
                    Patient.query({
                        centerId: vm.center.id,
                        page: pagingParams.page - 1,
                        size: paginationConstants.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                } else {
                    Doctor.query({
                        centerId: vm.center.id,
                        page: pagingParams.page - 1,
                        size: paginationConstants.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }
            });
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                if (0 === vm.activeTab) {
                    vm.patients = data;
                } else {
                    vm.doctors = data;
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.center.id,
                tab: vm.activeTab,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, {location: 'replace'});
        }

        function selectTab(tabName) {
            if (tabName !== vm.activeTab) {
                if (0 === tabName) {
                    vm.predicate = 'id';
                } else {
                    vm.predicate = 'user.lastName';
                }
                vm.activeTab = tabName;
            }
            vm.loadAll();
        }

        function selectedTab(id) {
            return vm.activeTab;
        }
    }
})();
