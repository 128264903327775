(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailVisitGraphs', reportDetailVisitGraphs)
        .controller('ReportDetailVisitGraphsController', ReportDetailVisitGraphsController);

    ReportDetailVisitGraphsController.$inject = ['ReportDetailPartHelperService', 'ReportHelper'];

    function reportDetailVisitGraphs() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                report: '='
            },
            templateUrl: 'app/entities/report/report-detail-visit-graphs.html',
            controller: 'ReportDetailVisitGraphsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function ReportDetailVisitGraphsController(ReportDetailPartHelperService, ReportHelper) {
        var vm = this;



        /////////////////////

    }
})();
