(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('DataEntryController', DataEntryController);

    DataEntryController.$inject = ['DataEntry', 'DataEntrySearch'];

    function DataEntryController(DataEntry, DataEntrySearch) {
        var vm = this;
        vm.dataEntries = [];

        vm.loadAll = loadAll;
        vm.search = search;

        vm.loadAll();

        ////////////

        function loadAll() {
            DataEntry.query(function (result) {
                vm.dataEntries = result;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            DataEntrySearch.query({query: vm.searchQuery}, function (result) {
                vm.dataEntries = result;
            });
        }
    }
})();
