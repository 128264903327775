(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CouponCodeDeleteController', CouponCodeDeleteController);

    CouponCodeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CouponCode', 'salesRepId'];

    function CouponCodeDeleteController($uibModalInstance, entity, CouponCode, salesRepId) {
        var vm = this;

        vm.couponCode = entity;
        vm.salesRepId = salesRepId;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        /////////////////////////////

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            CouponCode.delete({salesRepId: vm.salesRepId, id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
