//importScripts('/bower_components/jszip/dist/jszip.js');

self.addEventListener('message', function(e) {
    //console.debug('Worker: onMessage!');
    var file = e.data;
    var reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = (function (file) {
        return function (e) {
            var zip = new JSZip();
            zip.file(file.name, e.target.result);

            zip.generateAsync({type: "blob", compression: 'DEFLATE', compressionOptions : {level:1}})
                .then(function (blob) {
                    var relativePath = file.webkitRelativePath || file.name;
                    //console.debug('Worker blob: ' + blob);
                    self.postMessage({'blob': blob, 'fileName': relativePath + '.zip'});
                });
        };
    })(file);

    reader.readAsArrayBuffer(file);
}, false);

//console.debug('Worker initialized!');
