(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sales-rep', {
                parent: 'orders',
                url: '/sales-rep?page&sort&search',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER'],
                    pageTitle: 'orderApp.salesRep.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/orders/sales-rep/sales-reps.html',
                        controller: 'SalesRepController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRep');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-rep-detail', {
                parent: 'orders',
                url: '/sales-rep/{id}',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER'],
                    pageTitle: 'orderApp.salesRep.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/orders/sales-rep/sales-rep-detail.html',
                        controller: 'SalesRepDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tab: {
                        value: '0',
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesRep');
                        $translatePartialLoader.addPart('couponCode');
                        $translatePartialLoader.addPart('order');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'SalesRep', function ($stateParams, SalesRep) {
                        return SalesRep.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'sales-rep',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('sales-rep-detail.edit', {
                parent: 'sales-rep-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/sales-rep/sales-rep-dialog.html',
                        controller: 'SalesRepDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['SalesRep', function (SalesRep) {
                                return SalesRep.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep.new', {
                parent: 'sales-rep',
                url: '/new',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/sales-rep/sales-rep-dialog.html',
                        controller: 'SalesRepDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    login: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('sales-rep', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('sales-rep', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep.edit', {
                parent: 'sales-rep',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/sales-rep/sales-rep-dialog.html',
                        controller: 'SalesRepDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['SalesRep', function (SalesRep) {
                                return SalesRep.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('sales-rep', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('sales-rep', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep.delete', {
                parent: 'sales-rep',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/sales-rep/sales-rep-delete-dialog.html',
                        controller: 'SalesRepDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['SalesRep', function (SalesRep) {
                                return SalesRep.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('sales-rep', null, {location: 'replace', reload: true});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep-detail.new-coupon-code', {
                parent: 'sales-rep-detail',
                url: '/coupon-code/new',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/coupon-code/coupon-code-dialog.html',
                        controller: 'CouponCodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    code: null,
                                    validUntil: null,
                                    id: null
                                };
                            },
                            salesRepId: ['$stateParams', function ($stateParams) {
                                return $stateParams.id;
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('^', null, {location: 'replace', reload: true});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep-detail.coupon-code-edit', {
                parent: 'sales-rep-detail',
                url: '/coupon-code/{couponCodeId}/edit',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/coupon-code/coupon-code-dialog.html',
                        controller: 'CouponCodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CouponCode', function (CouponCode) {
                                return CouponCode.get({
                                    salesRepId: $stateParams.id,
                                    id: $stateParams.couponCodeId
                                }).$promise;
                            }],
                            salesRepId: ['$stateParams', function ($stateParams) {
                                return $stateParams.id;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {location: 'replace', reload: true});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('sales-rep-detail.coupon-code-delete', {
                parent: 'sales-rep-detail',
                url: '/coupon-code/{couponCodeId}/delete',
                data: {
                    authorities: ['ROLE_ORDER_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/orders/coupon-code/coupon-code-delete-dialog.html',
                        controller: 'CouponCodeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CouponCode', function (CouponCode) {
                                return CouponCode.get({
                                    salesRepId: $stateParams.id,
                                    id: $stateParams.couponCodeId
                                }).$promise;
                            }],
                            salesRepId: ['$stateParams', function ($stateParams) {
                                return $stateParams.id;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {location: 'replace', reload: true});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            });
    }

})();
