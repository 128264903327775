(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCompareTiles', compareTiles)
        .controller('CompareTilesController', CompareTilesController);

    CompareTilesController.$inject = ['$window', 'visits', '$state', '$scope', '$rootScope'];

    function compareTiles() {
        var directive = {
            link: link,
            scope: {},
            restrict: 'E',
            templateUrl: 'app/entities/compare/compare-tiles.html',
            controller: 'CompareTilesController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function CompareTilesController($window, visits, $state, $scope, $rootScope) {
        var vm = this;
        vm.visits = visits;
        vm.current = $state.$current.data.part ? $state.$current.data.part[0] : 'Detail';
        vm.tiles = ['LOBAR_VOLUMES_TLC', 'INTERNAL_FLOW_DISTRIBUTION', 'AIRWAY_VOLUME_TLC', 'AIRWAY_RESISTANCE_FUNCTIONAL_TLC', 'AIR_TRAPPING',
            'AIRWAY_WALL_THICKNESS', 'BLOOD_VESSEL_DENSITY', 'FIBROSIS', 'EMPHYSEMA', 'AEROSOL_DEPOSITION', 'NODULES', 'VENTILATION_PERFUSION',
            'SPIROMETRIE', 'LUNG_VOLUMES', 'AIRWAY_RESISTANCE_CLINICAL', 'DIFFUSION_CAPACITY', 'PNEUMO_INFO', 'SCAN_AXIAL'
        ];

        activate();

        ////////////

        function activate() {
            vm.visits.$promise.then(function (data) {
                scrollToCurrentTile();
            });

            var unsubscribe = $rootScope.$on('webportalApp:tileStateChange', function (event, result) {
                vm.current = result;
            });
            $scope.$on('$destroy', unsubscribe);
        }

        function scrollToCurrentTile() {
            var scroller = angular.element('#scroller')[0];

            scroller.scrollLeft = calcScrollPosition();
        }

        function calcScrollPosition() {
            var currentPartIndex = findCurrentPartIndex();
            var scrollWidth = 64 * vm.tiles.length;
            if (currentPartIndex == vm.tiles.length - 1) {
                return 9999;
            } else {
                var viewWidth = $window.innerWidth;
                var z = ((scrollWidth) - viewWidth) * (currentPartIndex) / vm.tiles.length;
                console.debug('index: ' + currentPartIndex + '; size: ' + vm.tiles.length + '; viewWidth: ' + viewWidth + '; srcollWidth: ' + (scrollWidth) + '; z: ' + z);
                return z;
            }
        }

        function findCurrentPartIndex() {
            var index = 0;
            for (; index < vm.tiles.length; index++) {
                var name = vm.tiles[index];
                if (name == vm.current) {
                    return index;
                }
            }
            return index;
        }

    }
})();
