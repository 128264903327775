(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPatientDetailLeft', patientDetailLeft)
        .controller('PatientDetailLeftController', PatientDetailLeftController);

    PatientDetailLeftController.$inject = ['$scope', 'MyCenter', 'Principal', 'Patient'];

    function patientDetailLeft() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/entities/patient/patient-detail-left.html',
            controller: 'PatientDetailLeftController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                if (newVal) {
                    scope.vm.refreshShowPatientInfo();
                }
            }, true);
        }
    }

    function PatientDetailLeftController($scope, MyCenter, Principal, Patient) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.refreshShowPatientInfo = refreshShowPatientInfo;
        vm.updatePatient = updatePatient;

        refreshShowPatientInfo();

        ////////////

        function refreshShowPatientInfo() {
            vm.patient.$promise.then(function () {
                if (isDoctor()) {
                    getMyCenter().then(function () {
                        vm.showPatientInfo = vm.myCenter.id === vm.patient.center.id;
                        vm.showEditButton = true;
                    });
                }
                if (!vm.patient.info) {
                    vm.showEditButton = true;
                }
            });


        }

        function isDoctor() {
            return Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV']);//TODO: fix this
        }

        function getMyCenter() {
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }

        function updatePatient() {
            Patient.update(vm.patient, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:patientUpdate', result);

            vm.patient = result;
            vm.isSaving = false;
        }

        function onSaveError() {

            vm.isSaving = false;
        }
    }
})();
