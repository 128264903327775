(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCompareTile', compareTile)
        .controller('CompareTileController', CompareTileController);

    CompareTileController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'Principal', 'DeviceService', 'ReportHelper', 'ReportDetailPartHelperService', '$stateParams'];

    function compareTile() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                disabled: '=',
                name: '@',
                captionKey: '@',
                tileClass: '@',
                current: '=',
            },
            templateUrl: 'app/entities/compare/compare-tile.html',
            controller: 'CompareTileController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watchGroup(['vm.model', 'vm.current'], function (newValues) {
                if (newValues[0] && newValues[1]) {
                    scope.vm.refreshCurrent();
                }
            }, true);

        }
    }

    function CompareTileController($scope, $rootScope, $state, $filter, Principal, DeviceService, ReportHelper, ReportDetailPartHelperService, $stateParams) {
        var vm = this;
        vm.caption = translateCaptionKey();
        vm.imgClass = ReportDetailPartHelperService.stateName(vm.name);
        vm.showTooltips = !DeviceService.isTouch();
        //vm.disabled = true;
        vm.isCurrent = vm.current === vm.name;

        var unsubscribe = $rootScope.$on('webportalApp:tileStateChange', function (event, result) {
            if (result == vm.name) {
                vm.isCurrent = true;
            } else {
                vm.isCurrent = false;
            }
        });
        $scope.$on('$destroy', unsubscribe);

        vm.transition = transition;

        ////////////

        function translateCaptionKey() {
            //console.log(vm.caption + ' ' + vm.captionKey);
            var translateKey = vm.captionKey ? vm.captionKey : 'webportalApp.PartType.' + vm.name;

            return $filter('translate')(translateKey);
        }

        function transition() {
            if (!vm.disabled) {
                var config = {};
                if (vm.current !== 'Detail') {
                    config = {location: 'replace'};
                }
                $state.transitionTo(fullStateName(), {
                    patientId: $stateParams.patientId,
                    visitIds: $stateParams.visitIds
                }, config);
                $scope.$emit('webportalApp:tileStateChange', vm.name)
            }
        }

        function fullStateName() {
            return 'compare-' + ReportDetailPartHelperService.stateName(vm.name);
        }
    }
})();
