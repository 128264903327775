(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadVisitGeneralController', UploadVisitGeneralController);

    UploadVisitGeneralController.$inject = ['patient', 'visit', 'UploadSession', 'Patient', 'Visit', 'Principal', '$scope', '$translate', '$state', '$location', '$filter', 'VisitHelper', 'Doctor'];

    function UploadVisitGeneralController(patient, visit, UploadSession, Patient, Visit, Principal, $scope, $translate, $state, $location, $filter, VisitHelper, Doctor) {
        var vm = this;

        vm.visit = visit;
        vm.patient = patient;

        vm.save = save;

        activate();

        //////////

        function activate() {
            VisitHelper.retrievePatient(vm.patient, vm.visit)
                .then(saveOnVM('patient'));
        }

        function save() {
            vm.isSaving = true;
            vm.visit.pathology = 'IPF';

            Visit.update(vm.visit, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:visitUpdate', result);

            vm.isSaving = false;
            setAndGoNext(result);
        }

        function setAndGoNext(visit) {
            UploadSession.setVisit(visit)
                .then(function () {
                    $state.go('upload.visit-lung-function', {patientId: visit.patient.id, visitId: visit.id});
                });
        }


        function onSaveError() {
            vm.isSaving = false;
        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            }
        }
    }
})();
