(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldDataNotAvailable', dataNotAvailable)
        .controller('DataNotAvailableController', DataNotAvailableController);

    DataNotAvailableController.$inject = ['$state'];

    function dataNotAvailable() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                reportPart: '='
            },
            templateUrl: 'app/entities/part/data-not-available.html',
            controller: 'DataNotAvailableController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function DataNotAvailableController($state) {
        var vm = this;

        vm.refreshPage = refreshPage;

        ///////////////

        function refreshPage() {
            $state.reload();
        }

    }
})();
