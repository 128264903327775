(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCaseStudyTitle', caseStudyTitle)
        .controller('CaseStudyTitleController', CaseStudyTitleController);

    CaseStudyTitleController.$inject = ['CaseStudyService'];

    function caseStudyTitle() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/infos/case-study/case-study-title.html',
            controller: 'CaseStudyTitleController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                scope.vm.initCaseStudy(newVal);
            }, true);
            scope.$watch('vm.caseStudyOption', function (newVal) {
                scope.vm.successQuery(newVal);
            }, true);
        }
    }

    function CaseStudyTitleController(CaseStudyService) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.showDescription = false;

        vm.initCaseStudy = initCaseStudy;
        vm.successQuery = successQuery;

        ////////////////////////////////

        function initCaseStudy(patient) {
            if (patient) {
                vm.patient = patient;
                if (!vm.caseStudy) {
                    vm.caseStudy = CaseStudyService.retrieveByPatient(vm.patient);
                }
                if (vm.caseStudy.then) {
                    vm.caseStudy.then(successQuery);
                } else {
                    successQuery(vm.caseStudy);
                }
            }
        }

        function successQuery(data) {
            if (data) {
                vm.caseStudy = data;
                vm.title = data.title;
                vm.description = data.description;
            }
        }
    }
})();
