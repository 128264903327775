(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('NurseDetailController', NurseDetailController);

    NurseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Nurse', 'User', 'Center'];

    function NurseDetailController($scope, $rootScope, $stateParams, entity, Nurse, User, Center) {
        var vm = this;
        vm.nurse = entity;

        vm.load = function (id) {
            Nurse.get({id: id}, function (result) {
                vm.nurse = result;
            });
        };
        var unsubscribe = $rootScope.$on('webportalApp:nurseUpdate', function (event, result) {
            vm.nurse = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
