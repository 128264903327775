(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PrivacypolicyController', PrivacypolicyController);

    PrivacypolicyController.$inject = ['$scope', 'Principal', '$stateParams'];

    function PrivacypolicyController($scope, Principal, $stateParams) {
        var vm = this;


    }
})();
