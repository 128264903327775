(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('spinOnce', spinOnce);

    function spinOnce() {
        var directive = {
            restrict: 'C',
            link: function (scope, element, attrs) {
                var done = false;

                element.bind('animationiteration', function ($event) {
                    if (done) {
                        $($event.target).removeClass('fa-spin');
                    }
                });

                scope.$on('cfpLoadingBar:completed', function ($event) {
                    //console.log('completed');
                    done = true;
                });

                setTimeout(function () {
                    done = true;
                }, 5000);
            }
        };
        return directive;
    }
})();
