(function() {
    'use strict';

    var jhiAlert = {
        templateUrl: 'app/components/alert/alert.html',
        controller: jhiAlertController
    };

    angular
        .module('webportalApp')
        .component('jhiAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService'];

    function jhiAlertController($scope, AlertService) {
        var vm = this;

        vm.alerts = AlertService.get();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
