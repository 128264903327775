(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitDetailLeft', visitDetailLeft)
        .controller('VisitDetailLeftController', VisitDetailLeftController);

    VisitDetailLeftController.$inject = ['MyCenter', 'Principal', 'IdsNavigationService', 'VisitStatusResource'];

    function visitDetailLeft() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/entities/visit/visit-detail-left.html',
            controller: 'VisitDetailLeftController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.visit', function (newVal) {
                if (newVal) {
                    scope.vm.refreshShowPatientRelations();
                }
            }, true);
        }
    }

    function VisitDetailLeftController(MyCenter, Principal, VisitStatusResource) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.showPatientRelations = Principal.hasAnyAuthority(['ROLE_DATA_VIEWER', 'ROLE_DATA_MANAGER']);
        vm.allowEdit = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);

        vm.refreshShowPatientRelations = refreshShowPatientRelations;
        vm.updateStatus = updateStatus;

        ////////////

        function refreshShowPatientRelations() {
            if (!vm.showPatientRelations) {
                vm.visit.$promise.then(function () {
                    var correctStatus = vm.visit.status === 'INCOMPLETE' || vm.visit.status === 'READY_TO_UPLOAD' || vm.visit.status === 'UPLOADED';
                    getMyCenter().then(function () {
                        var myVisit = vm.visit.patient.center.id === vm.myCenter.id;
                        vm.showPatientRelations = myVisit;
                        vm.allowEdit = myVisit && correctStatus;
                    });
                });
            }
        }

        function getMyCenter(){
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }

        function updateStatus(newStatus){
            vm.visit.status = newStatus;
            VisitStatusResource.update({'visitId': vm.visit.id, 'value': newStatus}, {});
        }
    }
})();
