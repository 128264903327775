(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldFilter', fldFilter)
        .controller('FilterController', FilterController);

    function fldFilter() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                filters: '=',
                resetState: '@',
                transition: '=',
                hideIfMissing: '@'
            },
            templateUrl: 'app/components/table/filter.html',
            controller: 'FilterController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    FilterController.$inject = [];

    function FilterController() {
        var vm = this;

        vm.removeFilter = removeFilter;
        vm.hideFilter = vm.hideIfMissing && vm.filters.length == 0;

        ///////////////

        function removeFilter(value) {
            var index;
            var found = -1;
            for (index = 0; index < vm.filters.length; index++) {
                if (vm.filters[index].value === value) {
                    found = index;
                    break;
                }
            }
            if (found !== -1) {
                vm.filters.splice(found, 1);
            }
            vm.transition();
        }

    }
})();
