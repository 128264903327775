(function() {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('book', {
            parent: 'infos',
            url: '/book',
            data: {
                pageTitle: 'webportalApp.book.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/infos/book/books.html',
                    controller: 'BookController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('book');
                    return $translate.refresh();
                }]
            }
        })
            .state('book.preview', {
                parent: 'book',
                url: '/preview',
                data: {
                    pageTitle: 'webportalApp.book.home.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/infos/book/book-preview.html',
                        controller: 'BookPreviewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg'
                    }).result.then(function () {
                        $state.go('^', null, {location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
        .state('book.buy', {
            parent: 'book',
            url: '/{id}/buy?quantity',
            data: {
                pageTitle: 'webportalApp.book.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/infos/book/book-buy.html',
                    controller: 'BookBuyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                quantity: {
                    value: '1',
                    squash: true
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('book');
                    $translatePartialLoader.addPart('login');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('customer');
                    $translatePartialLoader.addPart('address');
                    $translatePartialLoader.addPart('order');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                    if ('new' !== $stateParams.id) {
                        return Product.get({id: $stateParams.id});
                    }
                }],
                quantity: ['$stateParams', function ($stateParams) {
                    return $stateParams.quantity;
                }]
            }
        });
    }

})();
