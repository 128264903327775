(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldUpOrDown', upOrDown)
        .controller('UpOrDownController', UpOrDownController);

    UpOrDownController.$inject = ['$scope', 'CompareConfig'];

    function upOrDown() {
        var directive = {
            link: link,
            scope: {
                prev: '=',
                curr: '=',
                showDelta: '=',
                compare: '='
            },
            restrict: 'E',
            templateUrl: 'app/entities/compare/up-or-down.html',
            controller: 'UpOrDownController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function UpOrDownController($scope, CompareConfig) {
        var vm = this;

        vm.propertyName = CompareConfig.propertyName(vm.compare);
        vm.propertyValue = getProperty(vm.curr);
        vm.precision = CompareConfig.precision(vm.compare);

        if (getProperty(vm.prev) && getProperty(vm.curr)) {
            vm.delta = getProperty(vm.curr) - getProperty(vm.prev);
            vm.up = vm.delta > 0;
            vm.down = vm.delta < 0;
            vm.deltaProcent = 100 * vm.delta / getProperty(vm.prev);
            /*
                        var prevDeviation = vm.prev.deviationEntry;
                        var currDeviation = vm.curr.deviationEntry;
                        if (prevDeviation && currDeviation) {
                            var prevDistance = Math.abs(prevDeviation.normal - prevDeviation.value);
                            var currDistance = Math.abs(currDeviation.normal - currDeviation.value);
                            vm.isBetter = prevDistance > currDistance;
                            vm.isWorse = prevDistance < currDistance;
                        }*/
        } else {
            vm.up = false;
            vm.down = false;
            vm.isBetter = false;
            vm.isWorse = false;
        }

        ////////////////////

        function getProperty(elem) {
            if (elem) {
                return elem[vm.propertyName];
            } else {
                return elem;
            }
        }

    }
})();
