(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PatientNestedController', PatientNestedController);

    PatientNestedController.$inject = ['centerId', 'Patient', 'Principal', 'ParseLinks', 'paginationConstants'];

    function PatientNestedController(centerId, Patient, Principal, ParseLinks, paginationConstants) {
        var vm = this;
        vm.centerId = centerId;
        vm.patients = [];
        vm.reverse = true;
        vm.predicate = '';
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.refresh = refresh;

        if (Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV'])) {
                vm.predicate = 'info.user.lastName';
        }
        vm.loadAll();

        ////////////

        function loadAll() {
            if ('new' !== vm.centerId) {
                Patient.query({
                    centerId: vm.centerId,
                    page: vm.page - 1,
                    size: 20,
                    sort: [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'), 'id']
                }, function (result, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.patients = result;
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function refresh() {
            vm.loadAll();
        }
    }
})();
