(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldAuditDetail', auditDetails)
        .controller('AuditDetailsController', AuditDetailsController);

    AuditDetailsController.$inject = ['$log'];

    function auditDetails() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '='
            },
            templateUrl: 'app/components/audit/audit-detail.html',
            controller: 'AuditDetailsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newVal) {
                //console.log(newVal);
            }, true);
        }
    }

    function AuditDetailsController($log) {
        var vm = this;


        ///////////////////////////////////////

    }
})();
