(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitsByModifier', visitsByModifier)
        .controller('VisitsByModifierController', VisitsByModifierController);

    VisitsByModifierController.$inject = ['$state', 'Visit', 'ParseLinks', 'AlertService', 'paginationConstants', 'Principal'];

    function visitsByModifier() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                login: '='
            },
            templateUrl: 'app/entities/visit/visits-by-modifier.html',
            controller: 'VisitsByModifierController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function VisitsByModifierController($state, Visit, ParseLinks, AlertService, paginationConstants, Principal) {
        var vm = this;

        vm.visitsShowDoctor = true;
        vm.visitsShowPatient = true;
        vm.itemsPerPage = 5;
        vm.dateWarning = new Date();
        vm.dateError = new Date();

        vm.transition = transition;
        vm.getClass = getClass;

        loadAll();
        vm.dateWarning.setDate(vm.dateWarning.getDate()-2);
        vm.dateError.setDate(vm.dateWarning.getDate()-1);

        ////////////

        function loadAll() {
            if (Principal.hasAnyAuthority(['ROLE_DOCTOR_MEDDEV'])) {
                Visit.query({
                    page: 0,
                    size: 3,
                    sort: ['lastModifiedDate,desc']
                }, onSuccess, onError);
            }
            else if (Principal.hasAnyAuthority(['ROLE_DATA_MANAGER', 'ROLE_DOCTOR_RESEARCHER'])) {
                Visit.query({
                    lastModifiedBy: vm.login,
                    page: 0,
                    size: 3,
                    sort: ['lastModifiedDate,desc']
                }, onSuccess, onError);
            }
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;

            vm.visits = data;

        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.master.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, {location: 'replace'});
        }

        function getClass(modifiedDate){
            if(vm.dateError.getTime() > new Date(modifiedDate).getTime()){
                return 'text-danger bolder';
            }else if(vm.dateWarning.getTime() > new Date(modifiedDate).getTime()){
                return 'text-warning bolder';
            }else {
                return '';
            }
        }

    }
})();
