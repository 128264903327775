(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('BookPreviewController', BookPreviewController);

    BookPreviewController.$inject = ['$uibModalInstance', '$timeout'];

    function BookPreviewController($uibModalInstance, $timeout) {
        var vm = this;

        vm.clear = clear;

        $timeout(embedPdf);

        ////////////////////////////

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function embedPdf() {
            var options = {
                pdfOpenParams: {
                    view: 'FitV'
                }
                //,PDFJS_URL: "https://pdfobject.com/pdfjs/web/viewer.html"
            };

            PDFObject.embed('media/0.1/books/preview.pdf?hash=Ym9va3MtRlJJcGFydDFfcHJldmlldy5wZGY=', '#pdfPlaceholder', options);
        }

    }
})();
