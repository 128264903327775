(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('setClassWhenAtTop', setClassWhenAtTop);

    setClassWhenAtTop.$inject = ['$window'];

    function setClassWhenAtTop($window) {
        var $win = angular.element($window); // wrap window object as jQuery object
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var offsetTop = element.prop('offsetTop'); // get element's offset top relative to document

            $win.on('scroll', function (e) {
                var fixedNavigationElement = angular.element('#fixed-navigation');
                if ($window.pageYOffset >= offsetTop) { // scrolling...
                    fixedNavigationElement.removeClass('hidden fadeOutUp');
                    fixedNavigationElement.addClass('fadeInDown');

                } else { // at top of page
                    fixedNavigationElement.addClass('fadeOutUp');
                }
            });
        }
    }
})();
