(function() {
    'use strict';

    angular
        .module('webportalApp')
        .factory('Redeem', Redeem);

    Redeem.$inject = ['$resource'];

    function Redeem ($resource) {
        var service = $resource('api/account/redeem', {}, {
            'update': { method: 'PUT'}
        });

        return service;
    }
})();
