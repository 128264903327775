(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('doctor', {
                parent: 'entity',
                url: '/doctor?page&sort&search',
                data: {
                    authorities: ['ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.doctor.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/doctor/doctors.html',
                        controller: 'DoctorController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('doctor');
                        return $translate.refresh();
                    }]
                }
            })
            .state('doctor-detail', {
                parent: 'entity',
                url: '/doctor/{id}',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.doctor.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/doctor/doctor-detail.html',
                        controller: 'DoctorDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tab: {
                        value: '0',
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {


                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');

                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Doctor', function ($stateParams, Doctor) {
                        if ('new' !== $stateParams.id) {
                            return Doctor.get({id: $stateParams.id});
                        }
                    }]
                }
            })
            .state('doctor.new', {
                parent: 'doctor',
                url: '/new',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/doctor/doctor-dialog.html',
                        controller: 'DoctorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('doctor', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('doctor', null, {location: 'replace'});
                    });
                }]
            })
            .state('doctor-detail.listEdit', {
                parent: 'doctor-detail',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/doctor/doctor-dialog.html',
                        controller: 'DoctorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Doctor', function (Doctor) {
                                return Doctor.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('doctor', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('doctor', null, {location: 'replace'});
                    });
                }]
            })
            .state('doctor-detail.edit', {
                parent: 'doctor-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/doctor/doctor-dialog.html',
                        controller: 'DoctorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Doctor', function (Doctor) {
                                return Doctor.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('doctor.delete', {
                parent: 'doctor',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/doctor/doctor-delete-dialog.html',
                        controller: 'DoctorDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Doctor', function (Doctor) {
                                return Doctor.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('doctor', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            });
    }

})();
