(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldOrderStatusTag', orderStatusTag)
        .controller('OrderStatusTagController', OrderStatusTagController);

    OrderStatusTagController.$inject = [];

    function orderStatusTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                status: '=',
                hideIcon: '@',
                hideLabel: '@'
            },
            templateUrl: 'app/orders/order/order-status-tag.html',
            controller: 'OrderStatusTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function OrderStatusTagController() {
        var vm = this;

        vm.statusList = [{name: 'INCOMPLETE', icon: 'fa-ban', class: 'text-warning'},
            {name: 'ACKNOWLEDGED', icon: 'fa-check-circle-o', class: 'text-warning'},
            {name: 'PAID', icon: 'fa-money', class: 'text-success'},
            {name: 'CANCELED', icon: 'fa-ban', class: 'text-muted'},
            {name: 'IN_PROGRESS', icon: 'fa-hourglass-half', class: 'text-info'},
            {name: 'FULFILLED', icon: 'fa-check-square-o', class: 'text-success'}];

        ////////////////
    }
})();
