(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('goToCompare', goToCompare)
        .controller('GoToCompareController', GoToCompareController);

    GoToCompareController.$inject = ['$state', 'IdsNavigationService'];

    function goToCompare() {
        var directive = {
            link: link,
            restrict: 'A',
            scope: {
                patientId: '@patientId'
            },
            controller: 'GoToCompareController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            element.bind("click", function (e) {
                scope.vm.goToVisitCompare();
            });
        }
    }

    function GoToCompareController($state, IdsNavigationService) {
        var vm = this;

        vm.goToVisitCompare = goToVisitCompare;

        ////////////

        function goToVisitCompare() {
            IdsNavigationService.retrieveObjects(vm.patientId)
                .then(function (data) {
                    var visitIds = transformData(data);

                    $state.go(resolveStateName(), {patientId: vm.patientId, visitIds: visitIds});
                });
        }

        function transformData(data) {
            return data.map(function (visit) {
                return visit.id
            }).join(",");
        }

        function resolveStateName() {
            var stateName = $state.current.name.replace('visit-detail.', 'compare-');
            if ($state.get().map(function (x) {
                    return x.name;
                }).indexOf(stateName) == -1) {
                stateName = 'compare-lobar';
            } else if (stateName.indexOf('compare') == -1) {
                stateName = 'compare-lobar';
            }
            return stateName;
        }
    }
})();
