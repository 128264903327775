(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('MediaEventResource', MediaEventResource);

    MediaEventResource.$inject = ['$resource'];

    function MediaEventResource($resource) {
        var resourceUrl = 'media/api/events';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST'
            }
        });
    }
})();
