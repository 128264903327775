(function() {
    'use strict';

    angular
        .module('webportalApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'mgo-angular-wizard',
            'truncate',
            'ngAnimate',
            'iso-3166-country-codes',
            //'angular.img',
            'xeditable',
            'flow',
            'chart.js',
            'nvd3',
            'angulartics',
            'angulartics.google.analytics',
            'ngTagsInput',
            'ng-drag-scroll'
        ])
        .run(run);


    run.$inject = ['stateHandler', 'translationHandler', 'editableOptions', 'flowFactory'];

    function run(stateHandler, translationHandler, editableOptions, flowFactoryProvider) {
        stateHandler.initialize();
        translationHandler.initialize();
        editableOptions.theme = 'bs3';
        editableOptions.icon_set = 'font-awesome';
        flowFactoryProvider.defaults = {
            target: '/media/api/0.1/upload',
            permanentErrors: [500, 501],
            maxChunkRetries: 4,
            prioritizeFirstAndLastChunk: true,
            simultaneousUploads: 4,
            chunkSize: 1 * 1024 * 1024,
            forceChunkSize: true,
            testChunks: true
        };
    }
})();
