(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient', {
                parent: 'entity',
                url: '/patient?page&sort&search',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.patient.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patients.html',
                        controller: 'PatientController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-detail', {
                parent: 'entity',
                url: '/patient/{id}',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.patient.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patient-detail.html',
                        controller: 'PatientDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tab: {
                        value: '0',
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');


                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('dataCategoryType');
                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('caseStudyStatus');
                        $translatePartialLoader.addPart('category');
                        $translatePartialLoader.addPart('report');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        if ('new' !== $stateParams.id) {
                            return Patient.get({id: $stateParams.id});
                        }
                    }]
                }
            })
            .state('patient.new', {
                parent: 'patient',
                url: '/new',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient/patient-dialog.html',
                        controller: 'PatientDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    sex: null,
                                    race: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('patient', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('patient', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient-detail.listEdit', {
                parent: 'patient-detail',
                url: '/listEdit',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient/patient-dialog.html',
                        controller: 'PatientDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Patient', function (Patient) {
                                return Patient.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('patient', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient-detail.edit', {
                parent: 'patient-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient/patient-dialog.html',
                        controller: 'PatientDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Patient', function (Patient) {
                                return Patient.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('patient-detail', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient.delete', {
                parent: 'patient',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient/patient-delete-dialog.html',
                        controller: 'PatientDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Patient', function (Patient) {
                                return Patient.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient-detail.new-visit', {
                parent: 'patient-detail',
                url: '/visit/new',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog-new.html',
                        controller: 'VisitDialogNewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: 'new', patientId: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('patient-detail.edit-visit', {'visitId': result.id}, {location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient-detail.edit-visit', {
                parent: 'patient-detail',
                url: '/visit/{visitId}/edit',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog.html',
                        controller: 'VisitDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.visitId});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('patient-detail.upload', {
                parent: 'patient-detail',
                url: '/visit/{visitId}/upload',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog-upload.html',
                        controller: 'VisitDialogUploadController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.visitId});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            });
    }

})();
