(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldIdsNavigationBullets', idsNavigation)
        .controller('IdsNavigationBulletsController', IdsNavigationBulletsController);

    IdsNavigationBulletsController.$inject = ['$log', 'IdsNavigationService', '$location'];

    function idsNavigation() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                currentId: '=',
                parentId: '='
            },
            templateUrl: 'app/components/navigation/ids-navigation-bullets.html',
            controller: 'IdsNavigationBulletsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.parentId', function (newVal) {
                if (newVal) {
                    scope.vm.refreshOtherIds();
                }
            }, true);
        }
    }

    function IdsNavigationBulletsController($log, IdsNavigationService, $location) {
        var vm = this;

        vm.refreshOtherIds = refreshOtherIds;

        ///////

        function refreshOtherIds() {
            IdsNavigationService.retrieveObjects(vm.parentId).then(function(data){
                vm.objects = data.map(function(object){
                    return {id: object.id, url: generateUrl(object.id)};
                });
                enableCompareVisits();
            });
        }

        function generateUrl(objectId) {
            return '/#' + $location.url().replace(vm.currentId, objectId);
        }

        function enableCompareVisits() {
            if(vm.objects.length>1){
                var element = angular.element('.compareVisits'); // 1
                element.attr('disabled', false);
            }
        }

    }
})();
