(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCenterPanel', centerPanel)
        .controller('CenterPanelController', centerPanelController);

    function centerPanel() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                center: '='
            },
            templateUrl: 'app/entities/center/center-panel.html',
            controller: 'CenterPanelController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function centerPanelController() {
        var vm = this;


    }
})();
