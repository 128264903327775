(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PatientDetailController', PatientDetailController);

    PatientDetailController.$inject = ['$scope', '$rootScope', 'entity', 'pagingParams', 'Patient', 'MyCenter', 'Principal', 'CaseStudyService', '$state', 'Visit', '$analytics'];

    function PatientDetailController($scope, $rootScope, entity, pagingParams, Patient, MyCenter, Principal, CaseStudyService, $state, Visit, $analytics) {
        var vm = this;
        vm.patient = entity;
        vm.pagingParams = pagingParams;

        vm.load = load;

        var unsubscribe = $rootScope.$on('webportalApp:patientUpdate', function (event, result) {
            vm.patient = result;
        });
        $scope.$on('$destroy', unsubscribe);

        initPage();

        ////////////

        function load(id) {
            Patient.get({id: id}, function (result) {
                vm.patient = result;
            });
        }

        function initPage() {
            if (vm.patient.$promise) {
                vm.patient.$promise.then(function () {
                    vm.showAddButton = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
                    if (!vm.showAddButton) {
                        getMyCenter().then(function () {
                            vm.showAddButton = vm.myCenter.id === vm.patient.center.id;
                        });
                    }
                });
            }
            vm.caseStudy = CaseStudyService.retrieveByPatient(vm.patient);
            vm.caseStudy.then(function (caseStudy) {
                if (caseStudy) {
                    $analytics.eventTrack('impression', {category: 'caseStudy', label: caseStudy.id});
                }
            });
        }

        function getMyCenter(){
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }
    }
})();
