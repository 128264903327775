(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPatientSearchForm', patientSearchForm)
        .controller('PatientSearchFormController', PatientSearchFormController);

    PatientSearchFormController.$inject = ['$state', '$filter'];

    function patientSearchForm() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                advancedDefault: '@',
                simpleQuery: '@'
            },
            templateUrl: 'app/entities/patient/patient-search-form.html',
            controller: 'PatientSearchFormController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function PatientSearchFormController($state, $filter) {
        var vm = this;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.birthDate = false;
        vm.searchQuery = {};
        vm.searchQuery.simple = vm.simpleQuery;
        vm.advanced = vm.advancedDefault && vm.advancedDefault == 'true'; //make a boolean

        vm.advanced = vm.advanced || parse();

        vm.search = search;
        vm.openCalendar = openCalendar;
        vm.showSimple = showSimple;
        vm.showAdvanced = showAdvanced;
        vm.resetForm = resetForm;
        vm.showResetButton = showResetButton;

        ////////////

        function search() {
            if(vm.advanced) {
                concat();
            }
            $state.transitionTo('patient', {
                search: vm.searchQuery.simple
            });
        }

        function showSimple() {
            concat();
            vm.advanced = false;
        }

        function showAdvanced() {
            parse();
            vm.advanced = true;
        }

        function concat() {
            vm.searchQuery.simple = '';
            var first = true;
            if(vm.searchQuery.firstName){
                vm.searchQuery.simple += 'info.user.firstName:'+vm.searchQuery.firstName;
                first = false;
            }
            if(vm.searchQuery.lastName){
                if(!first){
                    vm.searchQuery.simple += ' AND '
                }
                vm.searchQuery.simple += 'info.user.lastName:'+vm.searchQuery.lastName;
                first = false;
            }
            if(vm.searchQuery.birthDate){
                if(!first){
                    vm.searchQuery.simple += ' AND '
                }
                vm.searchQuery.simple += 'info.birthDate:'+$filter('date')(vm.searchQuery.birthDate, "yyyy-dd-MM");
                first = false;
            }
            if(vm.searchQuery.externalId){
                if(!first){
                    vm.searchQuery.simple += ' AND '
                }
                if(isNumeric(vm.searchQuery.externalId)){
                    vm.searchQuery.simple += '(externalId:'+vm.searchQuery.externalId+' OR id:'+vm.searchQuery.externalId+')';
                }else{
                    vm.searchQuery.simple += 'externalId:'+vm.searchQuery.externalId;
                }
                first = false;
            }
        }

        function parse() {
            var fullyParsed = true;
            if (vm.searchQuery.simple) {
                var parts = vm.searchQuery.simple.split(' AND ');
                for (var i = 0; i < parts.length; i++) {
                    var part = parts[i];
                    var nameValue = part.split(':');
                    if (nameValue.length == 2) {
                        if (nameValue[0] == 'info.user.firstName') {
                            vm.searchQuery.firstName = nameValue[1];
                        } else if (nameValue[0] == 'info.user.lastName') {
                            vm.searchQuery.lastName = nameValue[1];
                        } else if (nameValue[0] == 'info.birthDate') {
                            var pattern = /(\d{4})-(\d{2})-(\d{2})/;
                            vm.searchQuery.birthDate = new Date(nameValue[1].replace(pattern, '$1-$3-$2'));
                        } else if (nameValue[0] == 'externalId') {
                            vm.searchQuery.externalId = nameValue[1];
                        } else{
                            fullyParsed = false;
                        }
                    } else if (nameValue.length == 3) {
                        var lastPart = nameValue[2];
                        vm.searchQuery.externalId = lastPart.substring(0, lastPart.indexOf(')'));
                    } else{
                        fullyParsed = false;
                    }
                }
            } else{
                fullyParsed = false;
            }
            return fullyParsed;
        }

        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function resetForm() {
            vm.searchQuery = {};
        }

        function showResetButton(){
            if(!vm.searchQuery){
                return false;
            }
            return isNotEmpty(vm.searchQuery.simple) || isNotEmpty(vm.searchQuery.firstName) || isNotEmpty(vm.searchQuery.lastName) || isNotEmpty(vm.searchQuery.birthDate) || isNotEmpty(vm.searchQuery.externalId);
        }

        function isNotEmpty(field){
            return field && field!='';
        }

    }
})();
