(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Book', Book);

    Book.$inject = ['$q', 'Product', 'AlertService'];

    function Book($q, Product, AlertService) {
        var books = undefined;
        var booksFound = undefined;

        var service = {
            get: getBooks,
            exists: booksFound
        };
        return service;

        ///////////////////////////////

        function getBooks() {
            if (booksFound == undefined) {
                var deferred = $q.defer();

                findProducts().$promise.then(function (data) {
                    var found = data.filter(filterBook);
                    if (found.length > 0) {
                        books = found;
                        booksFound = books != undefined;
                        //console.log(book);
                        deferred.resolve(books);
                    } else {
                        booksFound = false;

                        deferred.reject();
                    }
                }, function (reason) {
                    booksFound = false;

                    deferred.reject(reason);
                });

                books = deferred.promise;
            }
            return $q.when(books);
        }

        function findProducts() {
            return Product.query({
                page: 0,
                size: 100
            });
        }

        function findBook() {
            return $q(function (resolve, reject) {

                findProducts().$promise.then(function (data) {
                    var book = data.filter(filterBook)[0];
                    if (book) {
                        resolve(book);
                    } else {
                        reject();
                    }
                }, function (reason) {
                    reject(reason);
                })

            });
        }

        function filterBook(product) {
            if (product.type == 'BOOK' && product.status == 'SALABLE') {
                return true;
            } else {
                return false;
            }
        }
    }
})();
