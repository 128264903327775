(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('BookBuyController', BookBuyController);

    BookBuyController.$inject = ['$timeout', '$rootScope', '$q', 'entity', 'quantity', 'Principal', 'Auth', '$translate', 'AlertService', 'Order', 'Customer', '$state'];

    function BookBuyController($timeout, $rootScope, $q, entity, quantity, Principal, Auth, $translate, AlertService, Order, Customer, $state) {
        var vm = this;

        vm.book = entity;
        vm.quantity = quantity;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.loading = true;

        vm.login = login;
        vm.register = register;
        vm.copyEmail = copyEmail;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });
        navigateToOrder();

        ////////////////////////////

        function login(event) {

            event.preventDefault();

            doLogin(vm.username, vm.password, vm.rememberMe);
        }

        function doLogin(username, password, rememberme) {

            Auth.login({
                username: username,
                password: password,
                rememberMe: rememberme
            }).then(function () {
                $rootScope.$broadcast('authenticationSuccess');
                navigateToOrder();
            }).catch(function () {
                var message = $translate.instant('login.messages.error.authentication');
                AlertService.error(message);
            });
        }

        function register(event) {

            event.preventDefault();

            if (passwordsMatch()) {
                vm.registerAccount.langKey = $translate.use();
                vm.registerAccount.activated = true;

            }
        }

        function translateError(response) {
            var messageKey = 'register.messages.error.fail';

            if (response.status === 400 && response.data === 'login already in use') {
                messageKey = 'register.messages.error.userexists';
            } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                messageKey = 'register.messages.error.emailexists';
            }

            return $translate.instant(messageKey);
        }

        function passwordsMatch() {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                var message = $translate.instant('global.messages.error.dontmatch');
                AlertService.error(message);
                return false;
            }
            return true;
        }

        function copyEmail() {
            if (vm.registerAccount) {
                if (!vm.registerAccount.login && vm.registerAccount.email && vm.registerAccount.email.indexOf('@') != -1) {
                    var firstPart = vm.registerAccount.email.substr(0, vm.registerAccount.email.indexOf('@'));
                    vm.registerAccount.login = firstPart.replace(/[^0-9a-z]/gi, '');
                }
            }
        }

        function navigateToOrder() {
            Principal.identity().then(function (account) {
                if (account) {
                    prepareOrder(0)();
                }
            });
        }

        function prepareOrder(attempt) {
            var i = attempt;

            return function (error) {
                if (i < 3) {
                    console.log("attempt: " + i);
                    $q.all([vm.book.$promise, myOrders().$promise, myCustomers().$promise]).then(function (data) {
                        initOrder(data[1], data[2]).$promise.then(function (order) {
                            $state.transitionTo('order-detail.address', {id: order.id}, {location: 'replace'});
                        }).catch(function (response) {
                            var message = translateError(response);
                            AlertService.error(message);
                            vm.loading = false;
                        });
                    }, function (error) {
                        prepareOrder(i + 1)(error);
                    });
                } else {
                    console.log("tried to many times" + error);
                    var message = translateError(error);
                    AlertService.error(message);
                    vm.loading = false;
                }
            }
        }

        function myOrders() {
            return Order.query({
                page: 0,
                size: 1000,
                sort: 'lastModifiedDate,desc'
            });
        }

        function myCustomers() {
            return Customer.query({
                page: 0,
                size: 1000,
                sort: 'lastModifiedDate,desc'
            });
        }

        function initOrder(myOrders, myCustomers) {
            var order = selectOrder(myOrders);
            if (!order.customer) {
                order.customer = selectCustomer(myCustomers);
            }
            return createOrder(order);
        }

        function selectCustomer(result) {
            if (result[0]) {
                return result[0];
            } else {
                return undefined;
            }
        }

        function selectOrder(result) {
            var latestFound = result.filter(findUnfinishedBookOrder)[0];
            if (latestFound) {
                latestFound.items[0].quantity = vm.quantity;
                return latestFound;
            } else {
                return newOrder();
            }
        }

        function findUnfinishedBookOrder(order) {
            if (order.status != 'INCOMPLETE' && order.status != 'ACKNOWLEDGED') {
                return false;
            } else if (order.items.length == 0 || order.items[0].product.id != vm.book.id) {
                return false;
            } else {
                return true;
            }
        }

        function newOrder() {
            return {
                status: 'INCOMPLETE',
                items: [
                    {
                        quantity: vm.quantity,
                        product: vm.book
                    }
                ]
            };
        }

        function createOrder(order) {
            if (order.id) {
                return Order.update(order);
            } else {
                return Order.save(order);
            }
        }

    }
})();
