(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('FeedbackController', FeedbackController);

    FeedbackController.$inject = ['$location', '$uibModalInstance', '$timeout', 'FeedbackResource', 'AlertService', '$translate', 'Principal'];

    function FeedbackController($location, $uibModalInstance, $timeout, FeedbackResource, AlertService, $translate, Principal) {
        var vm = this;

        vm.sendFeedback = sendFeedback;
        vm.cancel = cancel;

        Principal.identity().then(function (account) {
            if (account) {
                vm.feedback = {
                    'firstName': account.firstName,
                    'lastName': account.lastName,
                    'email': account.email,
                    'type': 'INFO'
                };
            }else{
                vm.feedback = {
                    'type': 'TECHNICAL'
                };
            }
        });

        $timeout(function () {
            angular.element('#firstName').focus();
        });

        ////////////////////////////////

        function sendFeedback() {
            vm.feedback.description = vm.feedback.description + '\n\n(' + $location.absUrl() + ')';

            FeedbackResource.save(vm.feedback, onSuccess, onError);
        }

        function onSuccess() {
            $uibModalInstance.close();
            var message = $translate.instant('feedback.messages.info.thanks');
            AlertService.info(message);
        }

        function onError() {
            $uibModalInstance.close();
            var message = $translate.instant('feedback.messages.error.failed');
            AlertService.error(message);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
