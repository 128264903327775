(function() {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/?login',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            params: {
                login: {
                    value: 'false',
                    squash: true
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('settings');
                    $translatePartialLoader.addPart('visit');
                    $translatePartialLoader.addPart('visitStatus');
                    $translatePartialLoader.addPart('visitType');
                    $translatePartialLoader.addPart('pathology');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
