(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldUserDetail', userDetails)
        .controller('UserDetailsController', UserDetailsController);

    UserDetailsController.$inject = ['$log', 'User'];

    function userDetails() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '='
            },
            template: '<span ng-hide="vm.lastName">{{vm.login}}</span><span ng-show="vm.lastName">{{vm.firstName}} {{vm.lastName}}</span>',
            controller: 'UserDetailsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newVal) {
                scope.vm.retrieveName(newVal);
            }, true);
        }
    }

    function UserDetailsController($log, User) {
        var vm = this;

        vm.retrieveName = retrieveName;

        ///////////////////////////////////////

        function retrieveName(model) {
            vm.login = model.login;
            User.get({login: model.login}, function (result) {
                vm.firstName = result.firstName;
                vm.lastName = result.lastName;
            });
        }

    }
})();
