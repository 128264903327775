(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('ReportDetailPartScanController', ReportDetailPartScanController);

    ReportDetailPartScanController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'entity', 'Visit', 'ReportHelper', 'PartResource', 'Principal', 'ScanResource', '$http', '$log', 'CaseStudyService', 'report'];

    function ReportDetailPartScanController($scope, $rootScope, $state, $filter, entity, Visit, ReportHelper, PartResource, Principal, ScanResource, $http, $log, CaseStudyService, report) {
        var vm = this;
        vm.visit = entity;
        vm.report = report;
        vm.partStatuses = ReportHelper.partStatuses;

        vm.load = load;
        vm.showStatus = showStatus;
        vm.updatePart = updatePart;
        vm.retrieveImages = retrieveImages;

        activate();

        ////////////

        function activate() {
            initReportPart();

            var unsubscribe = $rootScope.$on('webportalApp:reportUpdate', function (event, result) {
                vm.report = result;
            });
            $scope.$on('$destroy', unsubscribe);

            bindScrollEvent();
            initCaseStudy();
        }

        function initReportPart() {
            vm.report.$promise.then(function () {
                vm.reportPart = ReportHelper.getParts(vm.report)[$state.$current.data.part[0]];
                //log.debug('Report-part initialized');

                prepareScans(vm.reportPart);

                var showAnyStatus = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
                vm.nothingToShow = true;
                vm.reportParts = ReportHelper.getFilteredPartsSortedArray(vm.report, $state.$current.data.part);
                vm.reportParts.forEach(function (item) {
                    if (showAnyStatus || item.status == 'PUBLISHED') {
                        item.show = true;
                        vm.nothingToShow = false;
                    } else {
                        item.show = false;
                    }
                });
            });
        }

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function showStatus() {
            var selected = $filter('filter')(vm.partStatuses, {value: vm.reportPart.status});
            return (vm.reportPart.status && selected.length) ? selected[0].text : 'Not set';
        }

        function updatePart() {
            PartResource.update({
                visitId: vm.visit.id,
                type: vm.reportPart.type.name,
                product: vm.reportPart.product
            }, vm.reportPart);
        }

        function prepareScans(reportPart) {
            if (reportPart) {
                var partName = reportPart.type.name;

                if (partName.indexOf('SCAN_') == 0 && reportPart && reportPart.binaries && reportPart.binaries[0]) {

                    var fileName = reportPart.binaries[0].fileName;
                    vm.binaryHash = fileName.substr(0, fileName.indexOf('.png'));
                    if (!vm.binaryHash) {
                        vm.binaryHash = fileName.substr(0, fileName.indexOf('.jpg'));
                        if (!vm.binaryHash) {
                            vm.binaryHash = fileName.substr(0, fileName.indexOf('.jpeg'));
                        }
                    }
                    vm.cut = partName.substr(5, partName.length);
                    var params = {
                        hash: vm.binaryHash,
                        cut: vm.cut
                    };
                    ScanResource.get(params).$promise.then(function (data) {
                        vm.slicesCount = data.count;
                        var half = vm.slicesCount / 2;
                        vm.sliderCount = 0;
                        vm.sliderMin = half;
                        vm.sliderMax = half;
                        vm.sliceIndex = -1;
                        vm.slicesSelected = 0;
                        vm.detailLevel = 5;

                        vm.sliceUrls = initUrls();

                        retrieveImages();
                    }, function (error) {
                        $log.debug(error);
                    });
                }
            }
        }

        function retrieveImages() {
            var newSlicesSelected = Math.round(vm.detailLevel * vm.slicesCount / 100);
            if (newSlicesSelected > vm.slicesSelected) {
                vm.slicesSelected = newSlicesSelected;
            }
            vm.numberInProgress = 0;

            var half = Math.round(vm.slicesCount / 2);
            var stepSize = Math.round(100 / vm.detailLevel);
            for (var i = 0; i <= half; i = i + stepSize) {
                retrieveImage(half + i);
                retrieveImage(half - i);
            }
        }

        function retrieveImage(index) {
            if (vm.sliceUrls[index] == '') {
                var url = 'media/0.1/scans/' + vm.binaryHash + '/' + vm.cut + '/' + index + '.png';
                vm.numberInProgress++;
                $http.get(url, {
                    responseType: 'arraybuffer',
                    headers: {
                        'accept': 'image/webp,image/*,*/*;q=0.8'
                    }
                }).then(onSuccess(url, index), onError);
            }
        }

        function onError(error) {
            $log.debug(error);
            vm.numberInProgress--;
        }

        function onSuccess(url, index) {
            var localUrl = url;
            var localIndex = index;
            return function (response) {
                var blob = new Blob(
                    [response.data],
                    {type: response.headers('Content-Type')}
                );
                var image = new Image();
                image.url = URL.createObjectURL(blob);

                vm.sliderCount++;
                vm.sliderMin = vm.sliderMin > localIndex ? localIndex : vm.sliderMin;
                vm.sliderMax = vm.sliderMax < localIndex ? localIndex : vm.sliderMax;
                vm.sliceUrls[localIndex] = image.url;
                //console.log(localIndex + ' ' + localUrl + ' ' +vm.sliceUrls[localIndex]);
                if (vm.sliceIndex == -1) {
                    vm.sliceIndex = findSliceIndexUp(0);
                }
                vm.numberInProgress--;
            };
        }

        function findSliceIndexUp(number) {
            for (var i = number; i < vm.slicesCount; i++) {
                if (vm.sliceUrls[i]) {
                    //console.log(i);
                    return i;
                }
            }
            return findSliceIndexDown(number);
        }

        function findSliceIndexDown(number) {
            for (var i = number; i >= 0; i--) {
                if (vm.sliceUrls[i]) {
                    //console.log(i);
                    return i;
                }
            }
            return findSliceIndexUp(number);
        }

        function initUrls() {
            var array = [vm.slicesCount];
            for (var i = 0; i < vm.slicesCount; i++) {
                array[i] = '';
            }
            return array;
        }

        function bindScrollEvent() {
            var slicePlaceholder = document.getElementById("slicePlaceholder");
            if (slicePlaceholder.addEventListener) {
                slicePlaceholder.addEventListener("mousewheel", MouseWheelHandler, false);
                slicePlaceholder.addEventListener("DOMMouseScroll", MouseWheelHandler, false);
            }
            else slicePlaceholder.attachEvent("onmousewheel", MouseWheelHandler);
        }

        function MouseWheelHandler(e) {

            e.preventDefault();

            // cross-browser wheel delta
            var e = window.event || e;
            var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            var newSliceIndex = vm.sliceIndex - delta;
            if (newSliceIndex < 0) {
                newSliceIndex = 0;
            } else if (newSliceIndex >= vm.slicesCount) {
                newSliceIndex = vm.slicesCount - 1;
            }
            //console.log(delta + ' ' + newSliceIndex + ' ' + vm.sliceIndex);
            if (delta < 0) {
                vm.sliceIndex = findSliceIndexUp(newSliceIndex);
            } else {
                vm.sliceIndex = findSliceIndexDown(newSliceIndex);
            }
            $scope.$apply();

            //console.log(vm.sliceUrls);

            return false;
        }

        function initCaseStudy() {
            vm.caseStudy = CaseStudyService.retrieveByVisit(vm.visit);
            vm.caseStudy.then(function (caseStudy) {
                vm.caseStudy = caseStudy;
            })
        }
    }
})();
