(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldFileUpload', fileUpload)
        .controller('FileUploadController', FileUploadController);

    FileUploadController.$inject = ['$log'];

    function fileUpload() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                items: '=',
                thumbnailClass: '@'
            },
            templateUrl: 'app/components/upload/file-upload.html',
            controller: 'FileUploadController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function FileUploadController($log) {
        var vm = this;
        vm.showCompressBar = false;

        vm.handleFileSelect = handleFileSelect;
        vm.uploadFile = uploadFile;

        var r = new Resumable({
            target: '/media/api/0.1/upload',
            query: {},
            maxChunkRetries: 4,
            prioritizeFirstAndLastChunk: true,
            simultaneousUploads: 4,
            chunkSize: 1 * 1024 * 1024,
            forceChunkSize: true,
            testChunks: false
        });

        document.getElementById('files').addEventListener('change', handleFileSelect, false);

        var worker;
        if (typeof(Worker) !== "undefined") {
            $log.debug('Yes!');
            worker = new Worker('app/components/upload/demo_workers.js');

            worker.addEventListener('message', onFileCompressed, false);
        } else {
            $log.debug('No :( !');
        }

        //////////

        function uploadFile(file) {
            r.addFile(file);
            vm.toCompress = vm.toCompress - 1;
            vm.doneCompress = vm.numberOfFiles - vm.toCompress;
            //r.upload();
        }

        function handleFileSelect(evt) {
            var files = evt.target.files;
            vm.numberOfFiles = files.length;
            vm.toCompress = vm.numberOfFiles;
            vm.doneCompress = 0;
            vm.showDummyCompressBar = false;
            vm.showCompressBar = true;
            for (var i = 0, file; file = files[i]; i++) {
                if(i % 100 === 0){
                    setTimeout(function(){/* Look mah! No name! */},2000);
                }
                if (vm.compressed) {
                    worker.postMessage(file); // Send data to our worker.
                } else {
                    vm.uploadFile(file);
                }
            }
        }

        function onFileCompressed(e) {
            //console.debug('File was zipped: ', e.data.fileName, e.data.blob);
            var file = blobToFile(e.data.blob, e.data.fileName);
            vm.uploadFile(file);
        }

        function blobToFile(theBlob, fileName) {
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        }

        var results = $('#results'),
            draggable = $('#dragHere'),
            uploadFile = $('#uploadFiles'),
            browseButton = $('#browseButton'),
            nothingToUpload = $('[data-nothingToUpload]');
        var finished = 0;


        // if resumable is not supported aka IE
        if (!r.support) location.href = 'http://browsehappy.com/';

        r.assignBrowse(browseButton, true);

        r.on('fileAdded', function (file, event) {
            vm.numberOfReadyFiles = r.files.length;
            vm.finished = finished;
            //$('.alert-box').text('Added ' + finished + '/' + r.files.length);
        });

        uploadFile.on('click', function () {
            if (r.files.length > 0) {
                r.upload();
            } else {
                nothingToUpload.fadeIn();
                setTimeout(function () {
                    nothingToUpload.fadeOut();
                }, 3000);
            }
        });

        $(document).on('click', '#browseButton', function () {
            //$('.alert-box').text('Please wait: adding files of folder...');
        });

        $(document).on('click', '.deleteFile', function () {
            var self = $(this),
                parent = self.parent(),
                identifier = parent.data('uniqueid'),
                file = r.getFromUniqueIdentifier(identifier);

            r.removeFile(file);
            parent.remove();
        });

        r.on('fileError', function (file, message) {
            var template =
                '<div data-uniqueid="' + file.uniqueIdentifier + '">' +
                '<span class="relativePath">' + file.relativePath + '</span>' +
                '<span class="large-6 right deleteFile"> X </span>' +
                '<span class="progress large-6">' +
                '<span class="meter" style="width:0%;color:blue"></span>' +
                '</span>' +
                '</div>';

            results.append(template);
        });

        r.on('fileSuccess', function (file, message) {
            finished += 1;
            //$('.alert-box').text(finished + '/' + r.files.length + ' - ' + parseInt(100 * r.progress()) + '%');
        });

        r.on('uploadStart', function () {
            //$('.alert-box').text('Uploading....');
        });

        r.on('complete', function () {
            //$('.alert-box').text('Uploaded ' + r.files.length);
        });

    }
})();
