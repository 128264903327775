(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'AlertService', 'ISO3166', '$state'];

    function SettingsController(Principal, Auth, JhiLanguageService, $translate, AlertService, ISO3166, $state) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.countries = ISO3166.codeToCountry;

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                countryKey: account.countryKey,
                lastName: account.lastName,
                login: account.login,
                company: account.company
            };
        };

        Principal.identity().then(function (account) {
            vm.settingsAccount = copyAccount(account);
            vm.hospitalRequired = Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV']);
        });

        function save() {
            Auth.updateAccount(vm.settingsAccount)
                .then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    Principal.identity(true).then(function (account) {
                        vm.settingsAccount = copyAccount(account);
                    });
                    JhiLanguageService.getCurrent().then(function (current) {
                        if (vm.settingsAccount.langKey !== current) {
                            $translate.use(vm.settingsAccount.langKey);
                        }
                    });
                    AlertService.clear();
                }).then(function () {
                $state.go('home');
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
    }
})();
