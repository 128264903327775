(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('case-study-home', {
                parent: 'infos',
                url: '/case-study-home',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_RESEARCHER'],
                    pageTitle: 'webportalApp.caseStudy.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/infos/case-study/case-study-home.html',
                        controller: 'CaseStudyHomeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('caseStudy');
                        $translatePartialLoader.addPart('caseStudyStatus');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('pathology');
                        return $translate.refresh();
                    }]
                }
            })
            .state('case-study', {
                parent: 'infos',
                url: '/case-study?page&sort&search&pathology',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_RESEARCHER'],
                    pageTitle: 'webportalApp.caseStudy.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/infos/case-study/case-studies.html',
                        controller: 'CaseStudyController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('caseStudy');
                        $translatePartialLoader.addPart('caseStudyStatus');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('pathology');
                        return $translate.refresh();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            pathology: $stateParams.pathology
                        };
                    }]
                }
            })
            .state('case-study-detail', {
                parent: 'infos',
                url: '/case-study/{id}',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_RESEARCHER'],
                    pageTitle: 'webportalApp.patient.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patient-detail.html',
                        controller: 'PatientDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    tab: {
                        value: '0',
                        squash: true
                    },
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('center');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('dataCategoryType');
                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('caseStudyStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        if ('new' !== $stateParams.id) {
                            return Patient.get({id: $stateParams.id});
                        }
                    }]
                }
            });
    }

})();
