(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('report', {
                parent: 'entity',
                url: '/report?page&sort&search',
                data: {
                    authorities: ['ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'reportApp.report.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/report/reports.html',
                        controller: 'ReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('reportStatus');
                        $translatePartialLoader.addPart('category');
                        return $translate.refresh();
                    }]
                }
            })
            .state('report-detail', {
                parent: 'app',
                url: '/report/{id}',
                data: {
                    authorities: ['ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'reportApp.report.medDev.title'
                },
                views: {
                    'navbar@': {
                        templateUrl: 'app/entities/report/navbar.html'
                    },
                    'content@': {
                        templateUrl: 'app/entities/report/report-detail.html',
                        controller: 'ReportDetailController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/components/empty_footer.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('lungInfoType');
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('doctor');
                        $translatePartialLoader.addPart('center');
                        $translatePartialLoader.addPart('export');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('reportStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }],
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }]
                }
            })
            .state('report.delete', {
                parent: 'report',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/report/report-delete-dialog.html',
                        controller: 'ReportDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Report', function (Report) {
                                return Report.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('report', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
