(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailTile', reportDetailTile)
        .controller('ReportDetailTileController', ReportDetailTileController);

    ReportDetailTileController.$inject = ['$scope', '$rootScope', '$state', '$filter', 'Principal', 'DeviceService', 'ReportHelper', 'ReportDetailPartHelperService'];

    function reportDetailTile() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                name: '@',
                captionKey: '@',
                tileClass: '@',
                current: '=',
                report: '='
            },
            templateUrl: 'app/entities/report/report-detail-tile.html',
            controller: 'ReportDetailTileController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newValue) {
                if (newValue) {
                    scope.vm.refreshTile();
                }
            }, true);
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.initTile();
                }
            }, true);
            scope.$watchGroup(['vm.model', 'vm.current'], function (newValues) {
                if (newValues[0] && newValues[1]) {
                    scope.vm.refreshCurrent();
                }
            }, true);
        }
    }

    function ReportDetailTileController($scope, $rootScope, $state, $filter, Principal, DeviceService, ReportHelper, ReportDetailPartHelperService) {
        var vm = this;
        vm.caption = translateCaptionKey();
        vm.imgClass = ReportDetailPartHelperService.stateName(vm.name);
        vm.showTooltips = !DeviceService.isTouch();
        vm.disabled = true;
        vm.isCurrent = false;

        var unsubscribe = $rootScope.$on('webportalApp:tileStateChange', function (event, result) {
            if (result == vm.name) {
                vm.isCurrent = true;
            } else {
                vm.isCurrent = false;
            }
        });
        $scope.$on('$destroy', unsubscribe);

        vm.initTile = initTile;
        vm.refreshTile = refreshTile;
        vm.refreshCurrent = refreshCurrent;
        vm.transition = transition;

        ////////////

        function initTile() {
            vm.report.$promise.then(function (data) {
                refreshTile();
                if (vm.current) {
                    refreshCurrent();
                }
            });
        }

        function refreshTile() {
            vm.href = $state.href(fullStateName(), {id: vm.report.visitId}, {location: 'replace'});

            var partStatus = getPartStatus();
            vm.wrongStatus = partStatus !== 'PUBLISHED';
            if (Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_RESEARCHER', 'ROLE_DATA_VIEWER']) && vm.wrongStatus) {
                vm.disabled = true;
                vm.wrongStatus = false;
            } else {
                vm.disabled = !vm.href || partStatus === 'NEW';
            }
        }

        function refreshCurrent() {
            vm.isCurrent = vm.current === vm.name;
        }

        function translateCaptionKey() {
            //console.log(vm.caption + ' ' + vm.captionKey);
            var translateKey = vm.captionKey ? vm.captionKey : 'webportalApp.PartType.' + vm.name;

            return $filter('translate')(translateKey);
        }

        function transition() {
            var config = {};
            if (vm.current !== 'Detail') {
                config = {location: 'replace'};
            }
            $state.transitionTo(fullStateName(), {id: vm.report.visitId}, config);
            $scope.$emit('webportalApp:tileStateChange', vm.name)
        }

        function fullStateName() {
            return 'visit-detail.' + ReportDetailPartHelperService.stateName(vm.name);
        }

        function getPart() {
            if (vm.model) {
                return vm.model;
            } else {
                return ReportHelper.getPart(vm.report, vm.name);
            }
        }

        function getPartStatus() {
            var part = getPart();
            if (part) {
                return part.status;
            } else {
                return '';
            }
        }
    }
})();
