(function () {
    'use strict';

    angular
        .module('webportalApp')
        .factory('ReportDetailPartDepositionService', ReportDetailPartDepositionService);

    ReportDetailPartDepositionService.$inject = [];

    function ReportDetailPartDepositionService() {
        var graphOptions = {
            'full': {
                'values': [
                    'extrathoracic',
                    'intrathoracic'
                ],
                'colors': ['#EEEEEE', '#53B5A1']
            },
            'intra': {
                'values': [
                    'central',
                    'distal',
                    'peripheral'
                ],
                'colors': ['#1E7968', '#BAE4DC', '#53B5A1']
            },
            'region': {
                'values': [
                    'RUL',
                    'RML',
                    'RLL',
                    'LUL',
                    'LLL'
                ],
                'colors': ['#BAE4DC', '#9FE1EE', '#53B5A1', '#1E7968', '#EEEEEE']
            }
        };

        var service = {
            graphOptions: graphOptions,
            generateLabels: generateLabels,
            generateData: generateData
        };
        return service;

        ////////////


        function generateLabels(graphOption) {
            return graphOption.values;
        }

        function generateData(depositionPart, graphOption) {
            return depositionPart.zoneEntries.filter(byName(graphOption.values)).map(roundPercentage);
        }

        function byName(properties) {
            return function (zoneEntry) {
                return properties.map(toUpperCase).indexOf(zoneEntry.zone) !== -1;
            };
        }

        function roundPercentage(zoneEntry) {
            return round(zoneEntry.percentage);
        }

        function round(value) {
            return Math.round(value * 100) / 100;
        }

        function toUpperCase(value) {
            return value.toUpperCase();
        }
    }
})();
