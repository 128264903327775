(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('VisitHelper', VisitHelper);

    VisitHelper.$inject = ['Visit', 'ReportHelper', 'Report'];

    function VisitHelper(Visit, ReportHelper, Report) {

        var service = {
            retrievePatient: retrievePatient,
            retrieveAllVisits: retrieveAllVisits,
            retrieveReport: retrieveReport
        };
        return service;

        ////////////////////////////////////

        function retrievePatient(patient, visit) {
            var patientPromise;
            if (patient) {
                patientPromise = patient.$promise;
            } else {
                patientPromise = visit.$promise.then(function (result) {
                    return result.patient;
                });
            }
            return patientPromise;
        }

        function retrieveAllVisits(patient) {
            return Visit.query({
                patientId: patient.id,
                size: 1000,
                sort: 'createdDate,desc'
            }).$promise;
        }

        function retrieveReport(visit) {
            var params = {
                id: visit.id,
                partType: ReportHelper.getClinicalPartNames(visit.type, visit.category)
            };
            return Report.get(params).$promise;
        }
    }
})();
