(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Visit', Visit);

    Visit.$inject = ['$resource', 'DateUtils'];

    function Visit($resource, DateUtils) {
        var resourceUrl = 'api/visits/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: transformResponse
            },
            'update': {method: 'PUT'}
        });

        function transformResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.date = DateUtils.convertDateTimeFromServer(data.date);
                data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                if (data.patient && data.patient.labels) {
                    data.patient.labelsUI = data.patient.labels.split(' ').map(function (item) {
                        return {text: item};
                    });
                }
            }
            return transformVisit(data);
        }

        function transformVisit(visit) {
            visit.patient = concatPatientName(visit.patient);
            return visit;
        }

        function concatPatientName(patient) {
            var name = '';
            if (patient && patient.info && patient.info.user) {
                if (patient.info.user.lastName) {
                    name = patient.info.user.lastName + ', ';
                }
                if (patient.info.user.firstName) {
                    name = name + patient.info.user.firstName;
                }
                patient.name = name;
            }
            //console.debug(patient);
            return patient;
        }
    }
})();
