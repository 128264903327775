(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('DataEntryDialogController', DataEntryDialogController);

    DataEntryDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'DataEntry', 'Visit'];

    function DataEntryDialogController($scope, $uibModalInstance, entity, DataEntry, Visit) {
        var vm = this;
        vm.dataEntry = entity;

        vm.visits = Visit.query();

        vm.load = load;
        vm.save = save;
        vm.clear = clear;

        ////////////

        function load(id) {
            DataEntry.get({id: id}, function (result) {
                vm.dataEntry = result;
            });

        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:dataEntryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError() {
            vm.isSaving = false;

        }

        function save() {
            vm.isSaving = true;
            if (vm.dataEntry.id !== null) {
                DataEntry.update(vm.dataEntry, onSaveSuccess, onSaveError);
            } else {
                DataEntry.save(vm.dataEntry, onSaveSuccess, onSaveError);
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
