(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('OrderDetailController', OrderDetailController);

    OrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Order', 'Payment', 'Invoice', 'Item', 'User'];

    function OrderDetailController($scope, $rootScope, $stateParams, previousState, entity, Order, Payment, Invoice, Item, User) {
        var vm = this;

        vm.order = entity;
        vm.previousState = previousState.name;
        vm.subtotal = 0;

        vm.copyEmail = copyEmail;

        calculateSubtotal();
        retrieveEmail();

        var unsubscribe = $rootScope.$on('webportalApp:customerOrderUpdate', function (event, result) {
            vm.order = result;
        });
        $scope.$on('$destroy', unsubscribe);


        //////////////////////

        function calculateSubtotal() {
            if (vm.order.items) {
                vm.order.items.forEach(addToSubTotal)
            }
        }

        function addToSubTotal(entry) {
            vm.subtotal += (entry.unitPrice * entry.quantity);
        }

        function retrieveEmail() {
            if (vm.order.customer) {
                User.get({login: vm.order.customer.login}).$promise.then(function (user) {
                    vm.order.customer.email = user.email;
                });
            }
        }

        function copyEmail() {
            var target = angular.element('#hidden_text');

            // make it visible, so can be focused
            target.attr('type', 'text');
            target.focus();
            // select all the text
            target[0].setSelectionRange(0, target.val().length);

            // copy the selection
            var succeed;
            try {
                succeed = document.execCommand("copy");
            } catch (e) {
                succeed = false;
            }

            // hide input again
            target.attr('type', 'hidden');

            return succeed;
        }
    }
})();
