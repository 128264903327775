(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadVisitController', UploadVisitController);

    UploadVisitController.$inject = ['patient', 'visit', 'UploadSession', 'Visit', 'Principal', '$scope', '$state', '$filter', 'VisitHelper', 'Doctor'];

    function UploadVisitController(patient, visit, UploadSession, Visit, Principal, $scope, $state, $filter, VisitHelper, Doctor) {
        var vm = this;

        vm.activeTab = 0;
        vm.visit = visit;
        vm.patient = patient;
        vm.visits = [];
        vm.isDataManager = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
        vm.doctorRequired = vm.isDataManager;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;

        vm.selectTab = selectTab;
        vm.selectedTab = selectedTab;
        vm.select = select;
        vm.save = save;
        vm.openCalendar = openCalendar;

        activate();

        //////////

        function activate() {
            VisitHelper.retrievePatient(patient, visit)
                .then(saveOnVM('patient'))
                .then(function (patient) {
                    vm.center = patient.center;

                    resolveDoctors(vm.center).then(initNewVisit);

                    return patient;
                })
                .then(VisitHelper.retrieveAllVisits)
                .then(prepareVisitsDropDown)
                .then(function () {
                    selectTab(vm.visit && vm.visit.id ? 0 : 1);
                });
        }

        function resolveDoctors(center) {
            var params = {};
            if (center) {
                params = {centerId: center.id};
            }
            return Doctor.query(params).$promise
                .then(saveOnVM('doctors'));
        }

        function initNewVisit(doctors) {
            if (doctors.length > 0) {
                vm.newVisit = {doctor: doctors[0]};
            }
        }

        function prepareVisitsDropDown(visits) {
            var filtered = visits.filter(byVisitStatus);
            filtered.forEach(addDisplayProperty);
            vm.visits = filtered;

            return vm.visits;
        }


        function byVisitStatus(visit) {
            return vm.isDataManager || visit.status == 'INCOMPLETE' || visit.status == 'READY_TO_UPLOAD' || visit.status == 'UPLOADED';
        }

        function addDisplayProperty(visit) {
            var visitDate = $filter('date')(visit.date, 'medium');
            var description = visit.description ? visit.description : 'n/a';
            if (description.length > 50) {
                description = description.substring(0, 50) + '...';
            }
            var status = visit.status;
            visit['display'] = visitDate + ' [' + status + '] ' + description;
        }

        function selectTab(tabName) {
            if (tabName !== vm.activeTab) {
                vm.activeTab = tabName;
            }
        }

        function selectedTab(id) {
            return vm.activeTab;
        }

        function select() {
            setAndGoNext(vm.visit);
        }

        function save() {
            vm.isSaving = true;
            vm.newVisit.patient = vm.patient;
            if (Principal.hasAnyAuthority(["ROLE_DOCTOR_MEDDEV"])) {
                vm.newVisit.status = 'READY_TO_UPLOAD';
            }
            Visit.save(vm.newVisit, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:visitUpdate', result);

            vm.isSaving = false;
            setAndGoNext(result);
        }

        function setAndGoNext(visit) {
            console.debug(visit);
            UploadSession.setVisit(visit)
                .then(function () {
                    $state.go('upload.confirm', {patientId: visit.patient.id, visitId: visit.id});
                    // $state.go('upload.visit-covid', {patientId: visit.patient.id, visitId: visit.id});
                });
        }


        function onSaveError() {

            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function saveOnVM(name) {
            return function (result) {
                vm[name] = result;
                return result;
            };
        }
    }
})();
