(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('CompareConfig', CompareConfig);

    CompareConfig.$inject = [];

    function CompareConfig() {

        function options(compare, visitDates) {
            return {
                chart: {
                    type: 'lineChart',
                    height: 275,
                    margin: {
                        top: 0,
                        right: 0,
                        bottom: 40,
                        left: 65
                    },
                    legend: {
                        updateState: true,
                        vers: 'furious'
                    },
                    x: selectXvalue,
                    y: selectYvalueFunction(compare),
                    average: 100,
                    interpolate: 'linear',
                    color: colorFunction,
                    legendColor: colorFunction,
                    duration: 300,
                    useInteractiveGuideline: true,
                    clipVoronoi: false,
                    tooltip: {
                        contentGenerator: generateToolTipContent
                    },

                    xAxis: {
                        //axisLabel: 'Visit dates',
                        tickFormat: function (d) {
                            return d3.time.format('%d%b%Y')(new Date(d))
                        },
                        showMaxMin: false,
                        staggerLabels: false,
                        tickValues: xTickValues(compare, visitDates)
                    },

                    yAxis: {
                        tickFormat: function (d) {
                            return d3.format('.1')(d);
                        },
                        "axisLabel": yAxisLabel(compare),
                        "axisLabelDistance": -10,
                        "showMaxMin": false,
                        "height": 60,
                        "ticks": null,
                        "width": 75,
                        "tickValues": yTickValues(compare),
                    },
                    "xDomain": xDomain(compare, visitDates),
                    "yDomain": yDomain(compare)
                }
            };
        }

        function yAxisLabel(compare) {
            var labels = {
                'DEFAULT': '% Predicted',
                'INTERNAL_FLOW_DISTRIBUTION': '% Total',
                'AIRWAY_WALL_THICKNESS': 'mL',
                'NODULES': 'mL',
                'VENTILATION_PERFUSION': 'L/mL'
            };
            return getProperty(labels, compare);
        }

        function defaultMaxValue(compare) {
            var values = {
                'DEFAULT': 100,
                'INTERNAL_FLOW_DISTRIBUTION': 36,
                'AIR_TRAPPING': 59,
                'AIRWAY_WALL_THICKNESS': 0,
                'NODULES': 0,
                'EMPHYSEMA': 8,
                'VENTILATION_PERFUSION': 0.1
            };
            return getProperty(values, compare);
        }

        function propertyName(compare) {
            var names = {
                'DEFAULT': 'value',
                'AIR_TRAPPING': 'percentage',
                'EMPHYSEMA': 'percentage'
            };
            return getProperty(names, compare);
        }

        function precision(compare) {
            var precisions = {
                'DEFAULT': 3,
                'AIR_TRAPPING': 1,
                'AIRWAY_WALL_THICKNESS': 2,
                'EMPHYSEMA': 2,
                'BLOOD_VESSEL_DENSITY': 2,
                'VENTILATION_PERFUSION': 3
            };
            return getProperty(precisions, compare);
        }

        function getProperty(options, compare) {
            var property = options[compare.type];
            if (property !== undefined) {
                return property;
            } else {
                return options['DEFAULT'];
            }
        }

        function colorFunction(d, i) {
            var colorArray = ['#3E524E', '#53C7B2', '#90F8E4', '#0FEEC5', '#1C7967', '#3953B7', '#80A9BE', '#4D5B7B', '#14b5e2', '#0b6f5b'];
            return colorArray[i];
        }

        function selectXvalue(d) {
            return d.date;
        }

        function selectYvalueFunction(compare) {
            if (compare.type == 'VENTILATION_PERFUSION') {
                return selectYvalue000;
            } else {
                return selectYvalue0;
            }
        }

        function selectYvalue0(d) {
            if (d.deviationEntry) {
                return Math.round(d.deviationEntry.value * 10) / 10;
            }
            return Math.round(d.value * 100) / 100;
        }

        function selectYvalue000(d) {
            if (d.deviationEntry) {
                return Math.round(d.deviationEntry.value * 1000) / 1000;
            }
            return Math.round(d.value * 1000) / 1000;
        }

        function generateToolTipContent(d) {
            //console.log(d);

            return '<h3>RML</h3><p>' + d.point[1] + '%</p>';
        }

        function xTickValues(compare, visitDates) {
            var stepSize = Math.ceil(visitDates.length / 10);
            //console.log(stepSize);
            var values = [];
            for (var index = 0; index < visitDates.length; index += stepSize) {
                values.push(visitDates[index]);
            }
            return values;
        }

        function yTickValues(compare) {
            var values = [];
            var stepSize = calcStepSize(compare);
            for (var value = 0; value <= compare.maxValue; value += stepSize) {
                values.push(value);
            }
            return values;
        }

        function xDomain(compare, visitDates) {

            var first = visitDates[0];
            var last = visitDates[visitDates.length - 1];

            var margin = calculateMargin(first, last, visitDates);

            Date.prototype.addDays = function (days) {
                var dat = new Date(this.valueOf());
                dat.setDate(dat.getDate() + days);
                return dat;
            };

            var min = new Date(first);
            var max = new Date(last);

            min = min.addDays(-margin);
            max = max.addDays(+margin);
            return [min, max];

        }

        function calculateMargin(first, last, visitDates) {
            if (first && last) {
                // Do the math.
                var numberOfVisits = visitDates.length;
                var millisecondsPerDay = 1000 * 60 * 60 * 24;
                var millisBetween = last.getTime() - first.getTime();
                var daysBetween = millisBetween / millisecondsPerDay;

                return daysBetween / (numberOfVisits * 2);

            } else {
                return 1;
            }
        }

        function yDomain(compare) {
            var maxValueWithMargin = compare.maxValue + compare.maxValue / 10;
            return [0, maxValueWithMargin];
        }

        /**
         * Select not too many gridlines, but always the gridline of 100, if maxValue >= 100;
         */
        function calcStepSize(compare) {
            if (compare.type == 'EMPHYSEMA') {
                return 5;
            }
            var fifth = compare.maxValue / 5;
            if (compare.type == 'NODULES') {
                return fifth == 0 ? 1 : Math.round(fifth);
            }
            if (compare.type == 'VENTILATION_PERFUSION') {
                return fifth == 0 ? 1 : Math.round(fifth * 100) / 100;
            }
            if (fifth > 50) {
                return 100;
            } else if (fifth == 50 || (50 - fifth) < (fifth - 25)) {
                return 50;
            } else if (fifth >= 25) {
                return 25
            } else {
                var roundedTo10 = Math.round(fifth / 10) * 10;
                var partitionalBy100 = roundedTo10 - (100 % roundedTo10);
                return partitionalBy100;
            }
        }

        var config = {
            options: options,
            yAxisLabel: yAxisLabel,
            defaultMaxValue: defaultMaxValue,
            selectYvalue: selectYvalue000,
            propertyName: propertyName,
            precision: precision
        };
        return config;
    }
})();
