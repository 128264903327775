(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCaseStudyIcon', caseStudyIcon)
        .controller('CaseStudyIconController', CaseStudyIconController);

    CaseStudyIconController.$inject = ['$scope', 'Principal', 'CaseStudyService', 'CaseStudyResource'];

    function caseStudyIcon() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '=',
                allowEdit: '@'
            },
            templateUrl: 'app/infos/case-study/case-study-icon.html',
            controller: 'CaseStudyIconController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                scope.vm.initValues(newVal);
            }, true);
            scope.$watch('vm.caseStudyOption', function (newVal) {
                scope.vm.success(newVal);
            }, true);
        }
    }

    function CaseStudyIconController($scope, Principal, CaseStudyService, CaseStudyResource ) {
        var vm = this;

        vm.caseStudy = vm.caseStudyOption;
        vm.caseStudyOptions = [
            {value: 0, text: 'No'},
            {value: 1, text: 'Yes'},
            {value: 2, text: 'Premium'}
        ];
        vm.editable = isEditable();

        vm.updateCaseStudy = updateCaseStudy;
        vm.initValues = initValues;
        vm.success = success;

        ///////////////////////////////

        function updateCaseStudy() {
            if (!vm.caseStudy.pricing) {
                return CaseStudyResource.delete({id: vm.patient.id});
            } else {
                if (vm.caseStudy.pricing === 1) {
                    vm.caseStudy.pricing = 'FREE';
                } else if (vm.caseStudy.pricing === 2) {
                    vm.caseStudy.pricing = 'PAID';
                }
                return CaseStudyResource.update({id: vm.patient.id}, vm.caseStudy, success);
            }
        }

        function success(data) {
            vm.caseStudy = data;

            if (vm.caseStudy && vm.caseStudy.pricing === 'FREE') {
                vm.caseStudy.pricing = 1;
            } else if (vm.caseStudy && vm.caseStudy.pricing === 'PAID') {
                vm.caseStudy.pricing = 2;
            }
        }

        function isEditable() {
            vm.editable = vm.allowEdit && vm.allowEdit == 'true' && Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
            return vm.editable;
        }

        function initValues(patient) {
            if (isEditable()) {
                initCaseStudy();
            }
        }

        function initCaseStudy() {
            if(!vm.caseStudy){
                vm.caseStudy = CaseStudyService.retrieveByPatient(vm.patient)
            }
            if(vm.caseStudy.then){
                vm.caseStudy.then(success);
            }else{
                success(vm.caseStudy);
            }
        }

        function isPaidCaseStudy(){
            return vm.patient.premiumCaseStudy || vm.caseStudy.pricing == 'PAID';
        }
    }
})();
