(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitController', VisitController);

    VisitController.$inject = ['$state', 'Visit', 'VisitSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'UploadSession'];

    function VisitController($state, Visit, VisitSearch, ParseLinks, AlertService, pagingParams, paginationConstants, UploadSession) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.status = pagingParams.status;
        vm.lastModifiedDate = pagingParams.lastModifiedDate;
        vm.currentSearch = pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filters = [];

        vm.loadAll = loadAll;
        vm.canEdit = canEdit;
        vm.canDelete = canEdit;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.goToUpload = goToUpload;

        vm.loadAll();
        if (pagingParams.status) {
            vm.filters.push({type: 'status', value: pagingParams.status, name: 'webportalApp.VisitStatus.'+pagingParams.status});
        }
        if (pagingParams.lastModifiedDate) {
            vm.filters.push({type: 'lastModifiedDate', value: pagingParams.lastModifiedDate});
        }

        ////////////

        function loadAll() {
            if (pagingParams.search) {
                VisitSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Visit.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    status: vm.status,
                    lastModifiedDate: vm.lastModifiedDate
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.visits = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status: findFilterValue('status'),
                lastModifiedDate: findFilterValue('lastModifiedDate')
            }, {location: 'replace'});
        }

        function findFilterValue(type) {
            var index;
            for (index = 0; index < vm.filters.length; index++) {
                if (vm.filters[index].type === type) {
                    return vm.filters[index].value;
                }
            }
            return '';
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'date';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.transition();
        }

        function canEdit(visit) {
            return visit.status !== 'INCOMPLETE' && visit.status !== 'READY_TO_UPLOAD';
        }

        function goToUpload(visit) {
            UploadSession.sessionEnded();
            $state.go('upload.init', {patientId: visit.patient.id, visitId: visit.id});
        }

    }
})();
