(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('LabelUSController', LabelUSController);

    LabelUSController.$inject = ['$scope', 'Principal', '$stateParams', 'HIDE_LABEL_US'];

    function LabelUSController($scope, Principal, $stateParams, HIDE_LABEL_US) {
        var vm = this;
        vm.hideLabelUS = HIDE_LABEL_US

    }
})();
