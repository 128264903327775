(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadHeaderController', UploadHeaderController);

    UploadHeaderController.$inject = ['UploadSession', 'UploadService', '$q', '$state', '$scope', '$rootScope', '$stateParams', '$analytics'];

    function UploadHeaderController(UploadSession, UploadService, $q, $state, $scope, $rootScope, $stateParams, $analytics) {
        var vm = this;

        vm.packaging = 'ZIP';
        vm.filesUploaded = 0;

        vm.startUpload = startUpload;
        vm.uploadDone = uploadDone;
        vm.uploadFailed = uploadFailed;
        vm.filesSubmitted = filesSubmitted;
        vm.fileAdded = fileAdded;
        vm.progress = progress;
        vm.fileProgress = fileProgress;
        vm.fileSuccess = fileSuccess;
        vm.tryAgain = tryAgain;

        activate();

        //////////

        function activate() {
            vm.flowObject = UploadSession.getFlowObject();
            vm.uploadSuccess = UploadSession.getUploadSuccess();
            vm.inProgress = UploadSession.getSessionId();
            vm.fileFolderCollapsed = vm.uploadSuccess || vm.inProgress;

            if (vm.flowObject) {
                vm.filesReadyToUpload = vm.flowObject.files.length;
            }
        }

        function startUpload($flow) {
            //$flow.opts.query.visitId = vm.visit.id;
            //$flow.opts.query.patientId = vm.visit.patient.id;
            $flow.opts.query.sessionId = UploadSession.getSessionId();
            $flow.upload();
            vm.inProgress = true;
            vm.fileFolderCollapsed = true;

            $analytics.eventTrack('click', {category: 'meddev', label: 'upload'});

            if ($state.is('upload.init')) {
                console.debug($stateParams);
                $state.go('upload.patient', {patientId: $stateParams.patientId, visitId: $stateParams.visitId});
            }
        }

        function fileSuccess(file, message, flow) {
            //console.log('fileSuccess');
            //console.debug(file);
            //console.debug(message);
            //console.debug(flow);

            var lastChunck = getLastChunckParams(file);
            console.log(lastChunck);
            UploadSession.setLastChunck(lastChunck);

            if (lastChunck.flowTotalChunks > 1) {
                vm.lastFileVerification = UploadService.verifyFile({}, lastChunck).$promise
                    .catch(cancelUploadFunction(flow));
            } else {
                vm.lastFileVerification = $q.resolve(true);
            }
        }


        function fileAdded(file, message) {
            //console.debug(file);
            vm.filesReadyToUpload += 1;
        }

        function finishFile(lastChunck) {
            var flowObject = flow;
            return function (response) {
                vm.inProgress = false;
                vm.uploadError = true;
                flowObject.cancel();
            };
        }

        function cancelUploadFunction(flow) {
            var flowObject = flow;
            return function (response) {
                vm.inProgress = false;
                vm.uploadError = true;
                flowObject.cancel();
            };
        }

        function getLastChunckParams(file) {
            var chuncks = file.chunks.length;
            var lastChunck = file.chunks[chuncks - 1];

            return lastChunck.flowObj.opts.query;
        }


        function uploadDone() {
            //console.log('uploadDone');
            vm.lastFileVerification
                .then(function (response) {
                    vm.filesReadyToUpload = 0;
                    vm.inProgress = false;

                    if (!vm.uploadError) {
                        vm.uploadSuccess = true;
                        UploadSession.setUploadSuccess(true);
                        //VisitStatusResource.update({'visitId': vm.visit.id, 'value': 'UPLOADED'}, {});
                        //MediaEventResource.create({}, {'type': 'DICOM_UPLOADED', 'content': vm.visit.id});

                        $rootScope.$broadcast('webportalApp:dicomUploaded', response);
                        //console.debug('message sent');
                    }
                });
        }

        function uploadFailed() {
            vm.filesReadyToUpload = 0;
            vm.uploadSuccess = false;
            vm.uploadError = true;
            vm.inProgress = false;
        }

        function filesSubmitted($files, $event, $flow) {
            vm.uploadSuccess = false;
            vm.uploadError = false;
            vm.filesReadyToUpload = $files.length;

            console.debug(UploadSession.init($flow));
        }

        function fileProgress($file, $flow) {
            vm.inProgress = true;
            if (vm.filesReadyToUpload === 1) {
                vm.progressVar = $file.progress();
                vm.filesUploaded = Math.round($file.progress() * 100) / 100;
            }
        }

        function progress($flow) {
            vm.inProgress = true;
            if (vm.filesReadyToUpload !== 1) {
                vm.progressVar = $flow.progress();
                vm.filesUploaded = Math.round(vm.filesReadyToUpload * vm.progressVar);
            }
        }

        function tryAgain() {
            vm.inProgress = false;
            vm.uploadSuccess = false;
            vm.uploadError = false;
            vm.fileFolderCollapsed = false;
        }

    }
})();
