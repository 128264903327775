(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitStatusTag', visitStatusTag)
        .controller('VisitStatusTagController', VisitStatusTagController);

    VisitStatusTagController.$inject = ['VisitStatusResource'];

    function visitStatusTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                status: '=',
                hideIcon: '@',
                hideLabel: '@'
            },
            templateUrl: 'app/entities/visit/visit-status-tag.html',
            controller: 'VisitStatusTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function VisitStatusTagController(VisitStatusResource) {
        var vm = this;

        vm.statusList = [{name: 'INCOMPLETE', icon: 'fa-ban', class: 'text-danger'},
            {name: 'READY_TO_UPLOAD', icon: 'fa-upload', class: 'text-warning'},
            {name: 'UPLOADED', icon: 'fa-upload', class: 'text-info'},
            {name: 'VERIFIED', icon: 'fa-upload', class: 'text-success'},
            {name: 'IMAGE_PROCESSING_STARTED', icon: 'fa-picture-o', class: 'text-warning'},
            {name: 'IMAGE_PROCESSING_COMPLETE', icon: 'fa-picture-o', class: 'text-success'},
            {name: 'POST_PROCESSING_STARTED', icon: 'fa-cogs', class: 'text-warning'},
            {name: 'POST_PROCESSING_COMPLETE', icon: 'fa-cogs', class: 'text-success'}];

        ////////////////
    }
})();
