(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldMissingValue', missingValue)
        .controller('MissingValueController', MissingValueController);

    MissingValueController.$inject = [];

    function missingValue() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                property: '@',
                precision: '@',
                scale: '@'
            },
            template: '<span>{{(vm.value / vm.scale) | number: vm.precision}}<small ng-show="vm.model.missing" class="text-grey">N/A</small></span>',
            controller: 'MissingValueController',
            controllerAs: 'vm',
            bindToController: true,
            replace: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newVal) {
                if (newVal) {
                    scope.vm.initValue(newVal);
                }
            }, true);
        }
    }

    function MissingValueController() {
        var vm = this;

        vm.property = vm.property ? vm.property : 'value';
        vm.precision = vm.precision ? parseInt(vm.precision) : 0;
        vm.scale = vm.scale ? vm.scale : 1;

        vm.initValue = initValue;

        ////////////////////////

        function initValue(model) {
            vm.value = model[vm.property];
        }
    }
})();
