(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldBrowserSupportBanner', fldBrowserSupportBanner)
        .controller('BrowserSupportBannerController', BrowserSupportBannerController);

    BrowserSupportBannerController.$inject = ['DeviceService'];

    function fldBrowserSupportBanner() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/components/device/browser-support-banner.html',
            controller: 'BrowserSupportBannerController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function BrowserSupportBannerController(DeviceService) {
        var vm = this;

        checkBrowserSupport();

        /////////////////////

        function checkBrowserSupport() {
            vm.isBrowserSupported = DeviceService.isCurrentBrowserSupported();
        }
    }
})();
