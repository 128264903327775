(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('nurse', {
                parent: 'entity',
                url: '/nurse?page&sort&search',
                data: {
                    authorities: ['ROLE_DATA_MANAGER'],
                    pageTitle: 'webportalApp.nurse.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/nurse/nurses.html',
                        controller: 'NurseController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('nurse');
                        return $translate.refresh();
                    }]
                }
            })
            .state('nurse-detail', {
                parent: 'entity',
                url: '/nurse/{id}',
                data: {
                    authorities: ['ROLE_DATA_MANAGER'],
                    pageTitle: 'webportalApp.nurse.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/nurse/nurse-detail.html',
                        controller: 'NurseDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('nurse');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Nurse', function ($stateParams, Nurse) {
                        return Nurse.get({id: $stateParams.id});
                    }]
                }
            })
            .state('nurse.new', {
                parent: 'nurse',
                url: '/new',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/nurse/nurse-dialog.html',
                        controller: 'NurseDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    speciality: null,
                                    telephone: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('nurse', null, {reload: true});
                    }, function () {
                        $state.go('nurse');
                    });
                }]
            })
            .state('nurse.edit', {
                parent: 'nurse',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/nurse/nurse-dialog.html',
                        controller: 'NurseDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Nurse', function (Nurse) {
                                return Nurse.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('nurse', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('nurse.delete', {
                parent: 'nurse',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/nurse/nurse-delete-dialog.html',
                        controller: 'NurseDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Nurse', function (Nurse) {
                                return Nurse.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('nurse', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
