(function () {
    'use strict';

    angular
        .module('webportalApp')
        .factory('UploadService', UploadService);

    UploadService.$inject = ['$resource'];

    function UploadService($resource) {
        return $resource('media/api/0.1/upload', {}, {
            'upload': {method: 'POST'},
            'finishFile': {url: 'media/api/0.1/finish', method: 'POST'},
            'verifyFile': {url: 'media/api/0.1/verify', method: 'POST'}
        });
    }
})();
