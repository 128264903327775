(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('DataEntryDetailController', DataEntryDetailController);

    DataEntryDetailController.$inject = ['$scope', '$rootScope', 'entity', 'DataEntry'];

    function DataEntryDetailController($scope, $rootScope, entity, DataEntry) {
        var vm = this;
        vm.dataEntry = entity;

        vm.load = function (id) {
            DataEntry.get({id: id}, function (result) {
                vm.dataEntry = result;
            });
        };
        var unsubscribe = $rootScope.$on('webportalApp:dataEntryUpdate', function (event, result) {
            vm.dataEntry = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
