(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('httpSrc', httpSrc);


    function httpSrc($http) {
        var directive = {
            link: link
        };
        return directive;

        function link($scope, elem, attrs) {
            function revokeObjectURL() {
                if ($scope.objectURL) {
                    URL.revokeObjectURL($scope.objectURL);
                }
            }

            $scope.$watch('objectURL', function (objectURL) {
                elem.attr('src', objectURL);
            });

            $scope.$on('$destroy', function () {
                revokeObjectURL();
            });

            attrs.$observe('httpSrc', function (url) {
                revokeObjectURL();

                if (url && url.indexOf('data:') === 0) {
                    $scope.objectURL = url;
                } else if (url) {
                    retrieveData(0, url)();
                }
            });

            function retrieveData(attempt, url) {
                var i = attempt;
                var link = url;
                return function (error) {
                    if (i < 3) {
                        if (i > 0) {
                            console.log('Retry: ' + i + ' for url ' + link);
                        }
                        $http.get(link, {responseType: 'arraybuffer'})
                            .then(function (response) {
                                var blob = new Blob(
                                    [response.data],
                                    {type: response.headers('Content-Type')}
                                );
                                $scope.objectURL = URL.createObjectURL(blob);
                            }, retrieveData(i + 1, link));
                    }
                }
            }
        }
    }
}());
