(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('PatientVisitHeaderController', PatientVisitHeaderController);

    PatientVisitHeaderController.$inject = [];

    function PatientVisitHeaderController() {
        var vm = this;


        //////////

    }
})();
