(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('data-entry', {
                parent: 'entity',
                url: '/data-entry',
                data: {
                    authorities: ['ROLE_DATA_MANAGER'],
                    pageTitle: 'webportalApp.dataEntry.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/data-entry/data-entries.html',
                        controller: 'DataEntryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataEntry');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('dataCategoryType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('data-entry-detail', {
                parent: 'entity',
                url: '/data-entry/{id}',
                data: {
                    authorities: ['ROLE_DATA_MANAGER'],
                    pageTitle: 'webportalApp.dataEntry.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/data-entry/data-entry-detail.html',
                        controller: 'DataEntryDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataEntry');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('dataCategoryType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DataEntry', function ($stateParams, DataEntry) {
                        return DataEntry.get({id: $stateParams.id});
                    }]
                }
            })
            .state('data-entry.new', {
                parent: 'data-entry',
                url: '/new',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/data-entry/data-entry-dialog.html',
                        controller: 'DataEntryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    type: null,
                                    value: null,
                                    category: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('data-entry', null, {reload: true});
                    }, function () {
                        $state.go('data-entry');
                    });
                }]
            })
            .state('data-entry.edit', {
                parent: 'data-entry',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/data-entry/data-entry-dialog.html',
                        controller: 'DataEntryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['DataEntry', function (DataEntry) {
                                return DataEntry.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('data-entry', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('data-entry.delete', {
                parent: 'data-entry',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/data-entry/data-entry-delete-dialog.html',
                        controller: 'DataEntryDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['DataEntry', function (DataEntry) {
                                return DataEntry.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('data-entry', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
