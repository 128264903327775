(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitDialogNewController', VisitDialogNewController);

    VisitDialogNewController.$inject = ['$scope', '$uibModalInstance', '$q', 'entity', 'Visit', 'Center', 'Patient', 'Doctor', 'VisitCategory', 'MyCenter'];

    function VisitDialogNewController($scope, $uibModalInstance, $q, entity, Visit, Center, Patient, Doctor, VisitCategory, MyCenter) {
        var vm = this;
        vm.visit = entity;

        vm.centers = Center.query();
        vm.doctors = Doctor.query();
        vm.patients = Patient.query();

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;
        vm.isSaving = false;

        vm.load = load;
        vm.create = create;
        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.centerFilter = centerFilter;

        activate();

        //////////

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:visitUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function create() {
            vm.isSaving = true;

            correctVisitCategory().then(saveVisit);
        }

        function saveVisit() {
            Visit.save(vm.visit, onSaveSuccess, onSaveError);
        }

        function correctVisitCategory() {
            return VisitCategory.byDoctor(vm.visit.doctor)
                .then(function (category) {
                    vm.visit.category = category;
                });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');

        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function centerFilter(hasCenter) {
            return (vm.center === hasCenter.center );
        }

        function activate() {
            $q.all([vm.visit.$promise, vm.centers.$promise, vm.doctors.$promise]).then(function () {
                if (vm.visit.patient) {
                    vm.center = vm.visit.patient.center;
                    vm.patientSelected = true;
                } else if (vm.centers.length === 1) {
                    vm.center = vm.centers[0];
                } else if (vm.visit.doctor) {
                    vm.center = vm.visit.doctor.center;
                }
                if (vm.doctors.length === 1) {
                    vm.visit.doctor = vm.doctors[0];
                }
            });
        }
    }
})();
