(function() {
    'use strict';

    angular
        .module('webportalApp')
        .provider('DeviceService', DeviceService);

    function DeviceService () {
        /*jshint validthis: true */
        this.$get = getService;

        getService.$inject = [];

        function getService () {

            var result = new UAParser().getResult();

            return {
                isTouch: isTouch,
                isCurrentBrowserSupported: isCurrentBrowserSupported,
                isBrowserSupported: isBrowserSupported
            };

            function isTouch() {
                var touchDevices = ['tablet', 'mobile'];
                var deviceType = result.device ? result.device.type : 'unknown';

                return touchDevices.indexOf(deviceType) !== -1;
            }

            function isCurrentBrowserSupported() {
                var browserName = result.browser.name;
                return isBrowserSupported(browserName);
            }

            function isBrowserSupported(browserName) {
                //console.debug(result);
                var supportedBrowsers = ['Chrome', 'Firefox', 'Edge'];

                return supportedBrowsers.indexOf(browserName) !== -1;
            }
        }
    }
})();
