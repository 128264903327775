(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitDetailController', VisitDetailController);

    VisitDetailController.$inject = ['$scope', '$rootScope', 'entity', 'Visit', 'report', 'CaseStudyService'];

    function VisitDetailController($scope, $rootScope, entity, Visit, report, CaseStudyService) {
        var vm = this;
        vm.visit = entity;
        vm.report = report;

        vm.load = load;

        var unsubscribe = $rootScope.$on('webportalApp:visitUpdate', function (event, result) {
            vm.visit = result;
        });
        $scope.$on('$destroy', unsubscribe);

        initCaseStudy();

        ////////////

        function load(id) {
            Visit.get({id: id}, function (result) {
                vm.visit = result;
            });
        }

        function initCaseStudy() {
            vm.caseStudy = CaseStudyService.retrieveByVisit(vm.visit);
            /*vm.caseStudy.then(function (caseStudy) {
                vm.caseStudy = caseStudy;
            })*/
        }
    }
})();
