(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CouponCodeDialogController', CouponCodeDialogController);

    CouponCodeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CouponCode', 'salesRepId', 'Product'];

    function CouponCodeDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, CouponCode, salesRepId, Product) {
        var vm = this;

        vm.couponCode = entity;
        vm.salesRepId = salesRepId;
        vm.datePickerOpenStatus = {};
        vm.products = Product.query();

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        if (!vm.couponCode.id) {
            vm.couponCode.validUntil = new Date().setMonth(new Date().getMonth() + 6);
        }

        ///////////////////////////

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.couponCode.id !== null) {
                CouponCode.update({salesRepId: vm.salesRepId}, vm.couponCode, onSaveSuccess, onSaveError);
            } else {
                CouponCode.save({salesRepId: vm.salesRepId}, vm.couponCode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:couponCodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validUntil = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
