(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('VisitDialogUploadController', VisitDialogUploadController);

    VisitDialogUploadController.$inject = ['$uibModalInstance', 'entity', 'VisitStatusResource', 'MediaEventResource', 'UploadService', '$q', '$analytics'];

    function VisitDialogUploadController($uibModalInstance, entity, VisitStatusResource, MediaEventResource, UploadService, $q, $analytics) {
        var vm = this;

        vm.visit = entity;
        vm.packaging = 'ZIP';
        vm.filesUploaded = 0;
        vm.fileFolderCollapsed = false;

        vm.clear = clear;
        vm.close = close;
        vm.startUpload = startUpload;
        vm.uploadDone = uploadDone;
        vm.uploadFailed = uploadFailed;
        vm.filesSubmitted = filesSubmitted;
        vm.progress = progress;
        vm.fileProgress = fileProgress;
        vm.fileSuccess = fileSuccess;

        //////////

        function startUpload($flow) {
            $flow.opts.query.visitId = vm.visit.id;
            $flow.opts.query.patientId = vm.visit.patient.id;
            $flow.upload();
            vm.inProgress = true;
            vm.fileFolderCollapsed = true;

            $analytics.eventTrack('click', {category: 'research', label: 'upload'});
        }

        function fileSuccess(file, message, flow) {
            //console.debug(file);
            //console.debug(message);
            //console.debug(flow);

            var lastChunck = getLastChunckParams(file);

            if (lastChunck.flowTotalChunks > 1) {
                vm.lastFileVerification = UploadService.finishFile({}, lastChunck).$promise
                    .catch(cancelUploadFunction(flow));
            } else {
                vm.lastFileVerification = $q.resolve(true);
            }
        }

        function finishFile(lastChunck) {
            var flowObject = flow;
            return function (response) {
                vm.inProgress = false;
                vm.uploadError = true;
                flowObject.cancel();
            }
        }

        function cancelUploadFunction(flow) {
            var flowObject = flow;
            return function (response) {
                vm.inProgress = false;
                vm.uploadError = true;
                flowObject.cancel();
            }
        }

        function getLastChunckParams(file) {
            var chuncks = file.chunks.length;
            var lastChunck = file.chunks[chuncks - 1];

            return lastChunck.flowObj.opts.query;
        }


        function uploadDone() {
            vm.lastFileVerification
                .then(function (response) {
                    vm.filesReadyToUpload = 0;
                    vm.inProgress = false;

                    if (!vm.uploadError) {
                        vm.uploadSuccess = true;
                        VisitStatusResource.update({'visitId': vm.visit.id, 'value': 'UPLOADED'}, {});
                        MediaEventResource.create({}, {'type': 'DICOM_UPLOADED', 'content': vm.visit.id});
                    }
                });
        }

        function uploadFailed() {
            vm.filesReadyToUpload = 0;
            vm.uploadSuccess = false;
            vm.uploadError = true;
            vm.inProgress = false;
        }

        function filesSubmitted($files, $event, $flow) {
            vm.uploadSuccess = false;
            vm.uploadError = false;
            vm.filesReadyToUpload = $files.length;
        }

        function fileProgress($file, $flow) {
            vm.inProgress = true;
            if (vm.filesReadyToUpload == 1) {
                vm.progressVar = $file.progress();
                vm.filesUploaded = Math.round($file.progress() * 100) / 100;
            }
        }

        function progress($flow) {
            vm.inProgress = true;
            if (vm.filesReadyToUpload != 1) {
                vm.progressVar = $flow.progress();
                vm.filesUploaded = Math.round(vm.filesReadyToUpload * vm.progressVar);
            }
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function close() {
            $uibModalInstance.close('ok');
        }

    }
})();
