(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('OrderAddressController', OrderAddressController);

    OrderAddressController.$inject = ['$timeout', '$scope', 'entity', 'Order', 'Principal', 'Auth', '$state', 'AlertService', 'ISO3166'];

    function OrderAddressController($timeout, $scope, entity, Order, Principal, Auth, $state, AlertService, ISO3166) {
        var vm = this;

        vm.order = entity;
        vm.countries = ISO3166.codeToCountry;

        vm.updateOrder = updateOrder;

        vm.useBillingAddress = vm.order.customer == undefined || vm.order.customer.shipmentAddress == undefined;
        initialiseFromAccount();
        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        //////////

        function initialiseFromAccount() {
            Principal.identity().then(function (account) {

                initializeObject(vm.order, 'customer', {});
                initializeObject(vm.order.customer, 'address', {});
                initializeObject(vm.order.customer, 'login', account.login);
                initializeObject(vm.order.customer.address, 'firstName', account.firstName);
                initializeObject(vm.order.customer.address, 'lastName', account.lastName);
                initializeObject(vm.order.customer.address, 'countryCode', account.countryKey);

                if (!vm.order.customer.address.locality && account.location) {
                    var index = account.location.indexOf(',');
                    if (index == -1) {
                        vm.order.customer.address.locality = account.location;
                    } else {
                        vm.order.customer.address.locality = account.location.substring(0, index);
                        vm.order.customer.address.country = account.location.substring(index + 1, account.location.length);
                    }
                }
            });
        }

        function initializeObject(object, property, defaultValue) {
            if (object && !object[property]) {
                object[property] = defaultValue;
            }
        }

        function updateOrder() {
            vm.isSaving = true;
            vm.order.customer.name = vm.order.customer.address.fullName;
            if (!vm.order.customer.name) {
                vm.order.customer.name = vm.order.customer.address.firstName + ' ' + vm.order.customer.address.lastName;
            }
            if (vm.useBillingAddress) {
                vm.order.customer.shipmentAddress = undefined;
            }

            Order.update(vm.order, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.order = result;
            $scope.$emit('webportalApp:customerOrderUpdate', result);
            vm.isSaving = false;
            updateAccount();
            $state.transitionTo('order-detail.review', {id: vm.order.id});
        }

        function updateAccount() {
            Principal.identity().then(function (account) {
                // use shipping address if available
                var address = vm.order.customer.shipmentAddress ? vm.order.customer.shipmentAddress : vm.order.customer.address;
                initializeObject(account, 'firstName', address.firstName);
                initializeObject(account, 'lastName', address.lastName);

                // use billing address
                initializeObject(account, 'company', vm.order.customer.address.fullName);
                initializeObject(account, 'location', vm.order.customer.address.locality + ', ' + vm.order.customer.address.country);
                initializeObject(account, 'countryKey', vm.order.customer.address.countryCode);

                Auth.updateAccount(account);
            });
        }

        function onSaveError(response) {
            vm.isSaving = false;
            AlertService.error(response.data.description);
        }
    }
})();
