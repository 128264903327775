(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailComparisonGraphs', reportDetailComparisonGraphs)
        .controller('ReportDetailComparisonGraphsController', ReportDetailComparisonGraphsController);

    ReportDetailComparisonGraphsController.$inject = ['ReportDetailPartHelperService', 'ReportHelper', 'Compare'];

    function reportDetailComparisonGraphs() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visits: '='
            },
            templateUrl: 'app/entities/report/report-detail-comparison-graphs.html',
            controller: 'ReportDetailComparisonGraphsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.visits', function (newValue) {
                if (newValue) {
                    scope.vm.retrieveFVC(newValue);
                }
            }, true);
        }
    }

    function ReportDetailComparisonGraphsController(ReportDetailPartHelperService, ReportHelper, Compare) {
        var vm = this;

        vm.retrieveFVC = retrieveFVC;

        /////////////////////

        function retrieveFVC(visits) {
            vm.visitIds = vm.visits.map(function (elem) {
                return elem.id + '';
            });
            Compare.get({
                id: 'LUNG_FUNCTION',
                visitId: vm.visitIds,
                filter: 'FVC_PRO_PRED'
            }).$promise.then(function (data) {
                vm.fvcData = data;
                //console.debug(vm.fvcData);
            });
        }

    }
})();
