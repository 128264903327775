(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadVisitCovidController', UploadVisitCovidController);

    UploadVisitCovidController.$inject = ['patient', 'visit', 'UploadSession', 'Visit', 'Principal', '$scope', '$state', '$filter', 'Doctor'];

    function UploadVisitCovidController(patient, visit, UploadSession, Visit, Principal, $scope, $state, $filter, Doctor) {
        var vm = this;
        vm.visit = visit;
        vm.patient = patient;
        vm.save = save;

        function save() {
            $state.go('upload.confirm', {patientId: visit.patient.id, visitId: visit.id});
        }

    }
})();
