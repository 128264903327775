(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailPartCollectionElement', reportDetailPartCollectionElement)
        .controller('ReportDetailPartCollectionElementController', ReportDetailPartCollectionElementController);

    ReportDetailPartCollectionElementController.$inject = ['PartResource', 'ReportHelper', 'ReportDetailPartHelperService'];

    function reportDetailPartCollectionElement() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                reportPart: '=',
                visitId: '=',
                category: '='
            },
            templateUrl: 'app/entities/part/report-detail-part-collection-element.html',
            controller: 'ReportDetailPartCollectionElementController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function ReportDetailPartCollectionElementController(PartResource, ReportHelper, ReportDetailPartHelperService) {
        var vm = this;
        vm.partStatuses = ReportHelper.partStatuses;
        vm.chartOptions = ReportDetailPartHelperService.procentChartOptions;
        vm.chartColors = ReportDetailPartHelperService.procentChartColors;

        vm.updatePart = updatePart;

        ////////////

        function updatePart() {
            PartResource.update({
                visitId: vm.visitId,
                type: vm.reportPart.type.name,
                product: vm.reportPart.product
            }, vm.reportPart);
        }
    }
})();
