(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('upload', {
                parent: 'app',
                url: '/upload?patientId,visitId',
                abstract: true,
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER'],
                    pageTitle: 'webportalApp.upload.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/upload/upload-base.html',
                        controller: 'UploadBaseController',
                        controllerAs: 'vm'
                    },
                    'upload-header@upload': {
                        templateUrl: 'app/entities/upload/upload-header.html',
                        controller: 'UploadHeaderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('upload');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        return $translate.refresh();
                    }]
                }
            })
            .state('upload.init', {
                parent: 'upload',
                url: '/init',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-init.html'
                    }
                },
                resolve: {}
            })
            .state('upload.patient', {
                parent: 'upload',
                url: '/patient',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-patient.html',
                        controller: 'UploadPatientController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId}).$promise;
                        }
                    }]
                }
            })
            .state('upload.visit', {
                parent: 'upload',
                url: '/visit',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-visit.html',
                        controller: 'UploadVisitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: ['$stateParams', 'Patient', '$location', function ($stateParams, Patient, $location) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId});
                        } else {
                            if (!$stateParams.visitId) {
                                $location.path('/upload/patient');
                            }
                        }
                    }],
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        if ($stateParams.visitId) {
                            return Visit.get({id: $stateParams.visitId});
                        }
                    }]
                }
            })
            .state('upload.visit-general', {
                parent: 'upload',
                url: '/visit-general',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-visit-general.html',
                        controller: 'UploadVisitGeneralController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: ['$stateParams', 'Patient', '$location', function ($stateParams, Patient, $location) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId});
                        } else {
                            if (!$stateParams.visitId) {
                                $location.path('/upload/patient');
                            }
                        }
                    }],
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.visitId}).$promise;
                    }]
                }
            })
            .state('upload.visit-lung-function', {
                parent: 'upload',
                url: '/visit-lung-function',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-visit-lung-function.html',
                        controller: 'UploadVisitLungFunctionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.visitId}).$promise;
                    }],
                    patient: ['$stateParams', 'Patient', '$location', function ($stateParams, Patient, $location) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId});
                        } else {
                            if (!$stateParams.visitId) {
                                $location.path('/upload/patient');
                            }
                        }
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('report');
                        return $translate.refresh();
                    }]
                }
            })
            .state('upload.visit-covid', {
                parent: 'upload',
                url: '/visit-covid',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-visit-covid.html',
                        controller: 'UploadVisitCovidController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: ['$stateParams', 'Patient', '$location', function ($stateParams, Patient, $location) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId});
                        } else {
                            if (!$stateParams.visitId) {
                                $location.path('/upload/patient');
                            }
                        }
                    }],
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.visitId}).$promise;
                    }]
                }
            })
            .state('upload.confirm', {
                parent: 'upload',
                url: '/confirm',
                views: {
                    'wizard-step@upload': {
                        templateUrl: 'app/entities/upload/upload-confirm.html',
                        controller: 'UploadConfirmController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: ['$stateParams', 'Patient', '$location', function ($stateParams, Patient, $location) {
                        if ($stateParams.patientId) {
                            return Patient.get({id: $stateParams.patientId});
                        } else {
                            if (!$stateParams.visitId) {
                                $location.path('/upload/patient');
                            }
                        }
                    }],
                    visit: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.visitId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('report');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
