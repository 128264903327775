(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailComparisonGraph', reportDetailComparisonGraph)
        .controller('ReportDetailComparisonGraphController', ReportDetailComparisonGraphController);

    ReportDetailComparisonGraphController.$inject = ['ReportDetailPartHelperService', 'ReportHelper', 'Compare'];

    function reportDetailComparisonGraph() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visits: '=',
                fvcData: '=',
                partType: '@',
                zones: '@'
            },
            templateUrl: 'app/entities/report/report-detail-comparison-graph.html',
            controller: 'ReportDetailComparisonGraphController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watchGroup(['vm.visits', 'vm.fvcData'], function (newValues) {
                if (newValues[0] && newValues[1]) {
                    scope.vm.prepare(newValues[0]);
                }
            }, true);
        }
    }

    function ReportDetailComparisonGraphController(ReportDetailPartHelperService, ReportHelper, Compare) {
        var vm = this;

        vm.titleKey = 'reportApp.report.medDev.friParams.params.' + vm.partType;
        vm.graph = {};
        vm.zoneArray = vm.zones.split(',');

        var primaryColor = '#000000';
        var secondaryColor = '#6DC5B4';
        if (vm.zoneArray.length === 2) {
            vm.colors = [primaryColor, secondaryColor, secondaryColor, primaryColor, secondaryColor, secondaryColor];
            vm.shapes = ['square', 'triangle-up', 'circle'];
            vm.icons = ['fa-stop', 'fa-rotate-270 fa-play', 'fa-circle'];
        } else {
            vm.colors = [primaryColor, secondaryColor, primaryColor, secondaryColor];
            vm.shapes = ['square', 'circle'];
            vm.icons = ['fa-stop', 'fa-circle'];
        }

        vm.prepare = prepare;

        /////////////////////

        function prepare(visits) {
            vm.visitIds = visits.map(function (elem) {
                return elem.id + '';
            });
            Compare.get({id: vm.partType, visitId: vm.visitIds, filter: vm.zoneArray}).$promise.then(function (data) {
                vm.partTypeData = data;
                console.debug(vm.partTypeData);

                vm.series = vm.fvcData.series.concat(vm.partTypeData.series);

                //console.log(vm.series);

                vm.series.forEach(function (serie, index) {
                    serie.icon = vm.icons[index];
                    serie.color = vm.colors[index];
                });
                vm.visitColumnClass = vm.series[0].values.length === 3 ? 'three' : 'two';
                vm.graph.data = generateData(vm.series);
                vm.graph.options = generateOptions();
            });
        }

        function generateData(series) {
            var data = [];

            series.forEach(function (serie, index) {
                var graphSerie = {
                    key: serie.key + '1',
                    type: 'scatter',
                    yAxis: 1,
                    values: serie.values
                };
                serie.values.forEach(function (value) {
                    value.shape = vm.shapes[index];
                });
                data.push(graphSerie);
            });

            series.forEach(function (serie) {
                var graphSerie = {
                    key: serie.key + '2',
                    type: 'line',
                    yAxis: 1,
                    values: serie.values
                };
                data.push(graphSerie);
            });

            console.log(data);
            return data;
        }

        function xDomain(series) {
            return [-0.2, 0.2 + series[0].values.length - 1];
        }


        function yDomain(series) {
            var max = 120;
            series.forEach(function (serie) {
                serie.values.forEach(function (value) {
                    if (value.deviationEntry && value.deviationEntry.value > max) {
                        max = value.deviationEntry.value + 10;
                    }
                });
            });
            return [0, max];
        }

        function generateOptions() {
            return {
                chart: {
                    type: 'multiChart',
                    height: 170,
                    width: 220,
                    margin: {
                        top: 0,
                        right: 10,
                        bottom: 5,
                        left: 30
                    },
                    color: vm.colors,
                    scatter: {
                        onlyCircles: false
                    },
                    x: function (d, i) {
                        return i;
                    },
                    y: function (d) {
                        if (d.deviationEntry) {
                            return d.deviationEntry.value;
                        }
                    },
                    xAxis: {
                        tickFormat: function (d) {
                            if (d) {
                                return vm.graph.data[0].values[d].x;
                            }
                        },
                        showMaxMin: false
                    },
                    yAxis1: {
                        tickFormat: function (d) {
                            return d;
                        },
                        showMaxMin: false,
                        tickValues: [0, 100]
                    },
                    xDomain: xDomain(vm.series),
                    yDomain1: yDomain(vm.series),
                    scatters1: {
                        xDomain: xDomain(vm.series)
                    },
                    lines1: {
                        xDomain: xDomain(vm.series)
                    },
                    interpolate: 'linear',
                    showLegend: false,
                    tooltip: {
                        enabled: false
                    }
                }
            };
        }

    }
})();
