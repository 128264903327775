(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailVisitFri', reportDetailVisitFri)
        .controller('ReportDetailVisitFriController', ReportDetailVisitFriController);

    ReportDetailVisitFriController.$inject = ['ReportDetailPartHelperService'];

    function reportDetailVisitFri() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                reportParts: '='
            },
            templateUrl: 'app/entities/report/report-detail-visit-fri.html',
            controller: 'ReportDetailVisitFriController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.reportParts', function (newValue) {
                if (newValue) {
                    scope.vm.prepareParts();
                }
            }, true);
        }
    }

    function ReportDetailVisitFriController(ReportDetailPartHelperService) {
        var vm = this;

        vm.prepareParts = prepareParts;

        /////////////////////

        function prepareParts() {
            //console.log(vm.reportParts);
            var rows = {'RUL_RML': [], 'RLL': [], 'LUL': [], 'LLL': []};

            processPart(vm.reportParts.LOBAR_VOLUMES_TLC, rows, 0);
            processPart(vm.reportParts.INTERNAL_FLOW_DISTRIBUTION, rows, 1);
            vm.centralValue = processPart(vm.reportParts.SPECIFIC_AIRWAY_VOLUME_TLC, {'CENTRAL': []}, 0).CENTRAL[0];

            //console.log(vm.centralValue);

            vm.values = [];
            vm.values.push({zone: 'RUL_RML', values: rows.RUL_RML});
            vm.values.push({zone: 'LUL', values: rows.LUL});
            vm.values.push({zone: 'RLL', values: rows.RLL});
            vm.values.push({zone: 'LLL', values: rows.LLL});

            //console.log(vm.values);
        }

        function processPart(part, rows, index) {
            if (part && part.zoneEntries) {
                part.zoneEntries.forEach(function (entry) {
                    if (rows[entry.zone]) {
                        rows[entry.zone][index] = entry;
                        ReportDetailPartHelperService.calculateRelativeValue(entry.deviationEntry);
                        ReportDetailPartHelperService.selectClass(entry.deviationEntry);
                    }
                });
            }
            return rows;
        }
    }
})();
