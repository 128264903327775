(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldMaxValue', maxValue)
        .controller('MaxValueController', MaxValueController);

    MaxValueController.$inject = [];

    function maxValue() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                model: '=',
                max: '@',
                unit: '@'
            },
            template: '<span>{{vm.correctedValue}}</span>',
            controller: 'MaxValueController',
            controllerAs: 'vm',
            bindToController: true,
            replace: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.model', function (newVal) {
                if (newVal) {
                    scope.vm.updateCorrected();
                }
            }, true);
        }
    }

    function MaxValueController() {
        var vm = this;
        vm.correctedValue = 'N/A';

        vm.updateCorrected = updateCorrected;

        updateCorrected();

        ////////////////////////

        function updateCorrected(){
            if (vm.model < vm.max) {
                vm.correctedValue = vm.model + (vm.unit ? ' ' + vm.unit : '');
            }
        }
    }
})();
