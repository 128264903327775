(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('ScanResource', ScanResource);

    ScanResource.$inject = ['$resource'];

    function ScanResource($resource) {
        var resourceUrl = 'media/api/0.1/scans/:hash/:cut';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET'
            }
        });
    }
})();
