(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPatientGenderTag', patientGenderTag)
        .controller('PatientGenderTagController', PatientGenderTagController);

    //PatientGenderTagController.$inject = [];

    function patientGenderTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                hideIcon: '@',
                hideLabel: '@'
            },
            templateUrl: 'app/entities/patient/patient-gender-tag.html',
            controller: 'PatientGenderTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function PatientGenderTagController() {
        var vm = this;

        ////////////////
    }
})();
