(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('LabelEUController', LabelEUController);

    LabelEUController.$inject = ['$scope', 'Principal', '$stateParams', 'HIDE_LABEL_EU'];

    function LabelEUController($scope, Principal, $stateParams, HIDE_LABEL_EU) {
        var vm = this;
        vm.hideLabelEU = HIDE_LABEL_EU

    }
})();
