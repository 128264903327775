(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CaseStudyController', CaseStudyController);

    CaseStudyController.$inject = ['$state', 'CaseStudyResource', 'PatientSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function CaseStudyController($state, CaseStudyResource, PatientSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        var vm = this;
        vm.showSearch = false;
        vm.reverse = pagingParams.ascending;
        vm.predicate = pagingParams.predicate;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filters = [];

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.sortBy = sortBy;
        vm.sortOrder = sortOrder;

        if (pagingParams.pathology) {
            vm.filters.push({type: 'pathology', value: pagingParams.pathology, name: 'webportalApp.Pathology.'+pagingParams.pathology});
        }
        vm.loadAll();

        ////////////

        function loadAll() {
            if (pagingParams.search) {
                PatientSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    pathology: pagingParams.pathology
                }, onSuccess, onError);
            } else {
                CaseStudyResource.query({
                    page: pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort(),
                    pathology: pagingParams.pathology
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.caseStudies = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                pathology: findFilterValue('pathology')

            }, {location: 'replace'});
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function findFilterValue(type) {
            var index;
            for (index = 0; index < vm.filters.length; index++) {
                if (vm.filters[index].type === type) {
                    return vm.filters[index].value;
                }
            }
            return '';
        }

        function sortBy() {
            vm.reverse = true;
            vm.transition();
        }

        function sortOrder() {
            vm.reverse = !vm.reverse;
            vm.transition();
        }

    }
})();
