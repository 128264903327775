(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitStatusSwitch', visitStatusSwitch)
        .controller('VisitStatusSwitchController', VisitStatusSwitchController);

    VisitStatusSwitchController.$inject = ['VisitStatusResource'];

    function visitStatusSwitch() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '=',
                statusFrom: '@',
                statusTo: '@'
            },
            templateUrl: 'app/entities/visit/visit-status-switch.html',
            controller: 'VisitStatusSwitchController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            /* */
        }
    }

    function VisitStatusSwitchController(VisitStatusResource) {
        var vm = this;

        vm.updateStatus = updateStatus;

        ////////////////

        function updateStatus(newStatus){
            VisitStatusResource.update({'visitId': vm.visit.id, 'value': newStatus}, {})
                .$promise.then(function(visit) {
                    vm.visit = visit;
                });
        }

    }
})();
