(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('MyCenter', MyCenter);

    MyCenter.$inject = ['$q','Center'];

    function MyCenter($q, Center) {
        var myCenterCache;
        var noCenter;

        return {
            'getMyCenter': getMyCenter,
            'clear': clear
        };

        ////////////

        function getMyCenter(){
            var params = {id: 'my'};
            if(myCenterCache){
                return myCenterCache;
            }
            if(noCenter){
                return noCenter.promise;
            }
            myCenterCache = Center.get(params).$promise;
            myCenterCache.then(function (data) {

            },function(reason){
                noCenter = $q.defer();
                noCenter.reject(reason);
                return noCenter.promise;
            });
            return myCenterCache;
        }

        function clear(){
            myCenterCache = undefined;
            noCenter = undefined;
        }
    }
})();
