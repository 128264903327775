(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldIdsNavigationNext', idsNavigationNext)
        .controller('IdsNavigationNextController', IdsNavigationNextController);

    IdsNavigationNextController.$inject = ['$log', 'IdsNavigationService', '$location'];

    function idsNavigationNext() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                currentId: '=',
                parentId: '='
            },
            templateUrl: 'app/components/navigation/ids-navigation-next.html',
            controller: 'IdsNavigationNextController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.parentId', function (newVal) {
                if (newVal) {
                    scope.vm.refreshOtherIds();
                }
            }, true);
        }
    }

    function IdsNavigationNextController($log, IdsNavigationService, $location) {
        var vm = this;

        vm.refreshOtherIds = refreshOtherIds;

        ///////

        function refreshOtherIds() {
            IdsNavigationService.retrieveObjects(vm.parentId).then(function(data){
                vm.objects = data.map(function(object){
                    return {id: object.id, url: generateUrl(object.id)};
                });
                var index, len, previous;
                for (index = 0, len = vm.objects.length; index < len; ++index) {
                    if (previous && vm.currentId === previous.id) {
                        vm.next = vm.objects[index];
                        break;
                    }
                    previous = vm.objects[index];
                }
            });
        }

        function generateUrl(objectId) {
            return '/#' + $location.url().replace(vm.currentId, objectId);
        }

    }
})();
