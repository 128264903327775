(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldReportDetailGeneralDetails', reportDetailGeneralDetails)
        .controller('ReportDetailGeneralDetailsController', ReportDetailGeneralDetailsController);

    ReportDetailGeneralDetailsController.$inject = ['Report', 'Principal'];

    function reportDetailGeneralDetails() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '=',
                report: '='
            },
            templateUrl: 'app/entities/report/report-detail-general-details.html',
            controller: 'ReportDetailGeneralDetailsController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.report', function (newValue) {
                if (newValue) {
                    scope.vm.activate();
                }
            }, true);
        }
    }

    function ReportDetailGeneralDetailsController(Report, Principal) {
        var vm = this;

        vm.updateReport = updateReport;
        vm.activate = activate;

        activate();


        ////////////////

        function activate() {
            vm.showRemarks = vm.report.description || Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
        }

        function updateReport() {
            Report.update(vm.report);
        }

    }
})();
