(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('UploadSession', UploadSession);

    UploadSession.$inject = ['$state'];

    function UploadSession($state) {

        var flowObject;
        var sessionId;
        var lastChunck;
        var uploadSuccess;

        var service = {
            init: init,
            getFlowObject: geFlowObject,
            getSessionId: getSessionId,
            setLastChunck: setLastChunck,
            getLastChunck: getLastChunck,
            setUploadSuccess: setUploadSuccess,
            getUploadSuccess: getUploadSuccess,
            setPatient: setPatient,
            setVisit: setVisit,
            sessionEnded: sessionEnded
        };
        return service;

        ////////////////////////////////////

        function init(flowObject) {
            console.debug('init');
            var array = new Uint32Array(1);

            var crypto = window.crypto || window.msCrypto;

            crypto.getRandomValues(array);

            this.sessionId = array[0];
            this.lastChunck = 0;
            this.uploadSuccess = false;

            this.flowObject = flowObject;

            return this.sessionId;
        }

        function geFlowObject() {
            //console.log(this.flowObject);
            return this.flowObject;
        }

        function getSessionId() {
            return this.sessionId;
        }

        function setLastChunck(lastChunck) {
            this.lastChunck = lastChunck;
        }

        function getLastChunck() {
            return this.lastChunck;
        }

        function setUploadSuccess(uploadSuccess) {
            this.uploadSuccess = uploadSuccess;
        }

        function getUploadSuccess() {
            return this.uploadSuccess;
        }

        function setPatient(patient) {
            return $state.go($state.current, {patientId: patient.id}, {location: 'replace'});
        }

        function setVisit(visit) {
            return $state.go($state.current, {patientId: visit.patient.id, visitId: visit.id}, {location: 'replace'});
        }

        function sessionEnded() {
            this.sessionId = 0;
            this.lastChunck = 0;
            this.uploadSuccess = false;
            this.flowObject = 0;
        }
    }
})();
