(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CouponCodeDetailController', CouponCodeDetailController);

    CouponCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CouponCode'];

    function CouponCodeDetailController($scope, $rootScope, $stateParams, previousState, entity, CouponCode) {
        var vm = this;

        vm.couponCode = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('webportalApp:couponCodeUpdate', function (event, result) {
            vm.couponCode = result;
        });
        $scope.$on('$destroy', unsubscribe);

        //////////////////////////////
    }
})();
