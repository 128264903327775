(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('googleAddress', googleAddress);

    function googleAddress() {
        return {
            require: 'ngModel',
            scope: {
                address: '=',
                ngModel: '='
            },
            link: link
        };

        function link($scope, element, attrs, model) {

            var options = {
                types: ['address'],
                componentRestrictions: {}
            };
            var autocomplete = new google.maps.places.Autocomplete(element[0], options);

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                $scope.$apply(function () {
                    var place = autocomplete.getPlace();

                    var components = place.address_components;
                    $scope.address.street = selectField('route', components).long_name + ' ' + selectField('street_number', components).long_name;
                    $scope.address.postalCode = selectField('postal_code', components).long_name;
                    $scope.address.locality = selectField('locality', components).long_name;
                    var country = selectField('country', components);
                    $scope.address.country = country.long_name;
                    $scope.address.countryKey = country.short_name;
                    $scope.address.countryCode = $scope.address.countryKey;
                });
            });
        }

        function selectField(type, components) {
            var field;
            for (var i = 0, length = components.length; i < length; i++) {
                field = components[i];
                if (field.types.indexOf(type) != -1) {
                    return field;
                }
            }
            return {long_name: '', short_name: ''};
        }
    }
})();
