(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCaseStudyBanner', caseStudyBanner)
        .controller('CaseStudyBannerController', CaseStudyBannerController);

    CaseStudyBannerController.$inject = ['$sce', 'Principal', 'CaseStudyService'];

    function caseStudyBanner() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/infos/case-study/case-study-banner.html',
            controller: 'CaseStudyBannerController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.caseStudyOption', function (newVal) {
                scope.vm.prepareBanners(newVal);
            }, true);
            scope.$watch('vm.patient', function (newVal) {
                scope.vm.prepareByPatient(newVal);
            }, true);
        }
    }

    function CaseStudyBannerController($sce, Principal, CaseStudyService) {
        var vm = this;

        vm.prepareBanners = prepareBanners;
        vm.onlyBanners = onlyBanners;
        vm.prepareByPatient = prepareByPatient;

        vm.caseStudy = vm.caseStudyOption;

        ///////////////////////////////////////

        function prepareByPatient(patient) {
            if (!vm.caseStudy) {
                vm.caseStudy = CaseStudyService.retrieveByPatient(patient);
            }
            if (vm.caseStudy.then) {
                vm.caseStudy.then(prepareBanners);
            } else {
                prepareBanners(vm.caseStudy);
            }
        }

        function prepareBanners(newVal) {
            vm.caseStudy = newVal;
            if (vm.caseStudy && vm.caseStudy.contents) {
                Principal.identity().then(function (account) {
                    var countryKey = account.countryKey;

                    vm.caseStudy.contents.forEach(function (element) {
                        element.safeUrl = trust(element.url);
                    });

                    var filtered = vm.caseStudy.contents.sort(sortByCountry).filter(byCountry(countryKey));

                    vm.imuBanner = filtered.filter(byType('BANNER_IMU'))[0];
                    vm.skyBanner = filtered.filter(byType('BANNER_SKY'))[0];
                });
            }
        }

        function byType(type) {
            return function (element) {
                return element.type == type;
            }
        }

        function sortByCountry(first, second) {
            if (first.country == undefined && second.country == undefined) {
                return 0;
            } else if (first.country != undefined && second.country != undefined) {
                return first.country.localeCompare(second.country);
            } else if (first.country == undefined) {
                return +1;
            } else {
                return -1;
            }
        }

        function byCountry(countryKey) {
            return function (element) {
                return element.country == undefined || element.country == countryKey;
            }
        }

        function onlyBanners(element) {
            return element.type.indexOf('BANNER') == 0;
        }

        function trust(url) {
            return $sce.trustAsResourceUrl(url);
        }
    }
})();
