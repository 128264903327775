(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitNavigationPanel', visitNavigationPanel)
        .controller('VisitNavigationPanelController', VisitNavigationPanelController);

    VisitNavigationPanelController.$inject = ['Principal', 'IdsNavigationService', 'Visit', '$state'];

    function visitNavigationPanel() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '='
            },
            templateUrl: 'app/entities/visit/visit-navigation-panel.html',
            controller: 'VisitNavigationPanelController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function VisitNavigationPanelController(Principal, IdsNavigationService, Visit, $state) {
        var vm = this;
        vm.showPatientRelations = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER']);

        IdsNavigationService.reset();

        ////////////
    }
})();
