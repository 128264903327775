(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('CenterDialogController', CenterDialogController);

    CenterDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Center'];

    function CenterDialogController($scope, $uibModalInstance, entity, Center) {
        var vm = this;
        vm.center = entity;

        vm.load = load;
        vm.save = save;
        vm.clear = clear;

        ////////////

        function load(id) {
            Center.get({id: id}, function (result) {
                vm.center = result;
            });

        }

        function onSaveSuccess(result) {
            $scope.$emit('webportalApp:centerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError() {
            vm.isSaving = false;

        }

        function save() {
            vm.isSaving = true;
            if (vm.center.id !== null) {
                Center.update(vm.center, onSaveSuccess, onSaveError);
            } else {
                Center.save(vm.center, onSaveSuccess, onSaveError);
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
