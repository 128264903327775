(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldCaseStudyTiles', caseStudyTitle)
        .controller('CaseStudyTilesController', CaseStudyTilesController);

    CaseStudyTilesController.$inject = ['Pathology'];

    function caseStudyTitle() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                caseStudyOption: '='
            },
            templateUrl: 'app/infos/case-study/case-study-tiles.html',
            controller: 'CaseStudyTilesController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {

        }
    }

    function CaseStudyTilesController(Pathology) {
        var vm = this;

        vm.pathologyOptions = Pathology.pathologyOptions;
    }
})();
