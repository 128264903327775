(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldWorkflowAlert', workflowAlert)
        .controller('WorkflowAlertController', WorkflowAlertController);

    WorkflowAlertController.$inject = ['Principal'];

    function workflowAlert() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                visit: '='
            },
            templateUrl: 'app/components/workflow/workflow-alert.html',
            controller: 'WorkflowAlertController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.visit', function (newValue) {
                if (newValue) {
                    scope.vm.onVisitChange();
                }
            }, true);
        }
    }

    function WorkflowAlertController(Principal) {
        var vm = this;
        var showWorkflowStates = ['IMAGE_PROCESSING_STARTED','IMAGE_PROCESSING_COMPLETE'];

        vm.showWorkflow = false;
        vm.isOwner = false;

        vm.onVisitChange = onVisitChange;

        onVisitChange();

        ///////////////////

        function onVisitChange() {
            vm.showWorkflow = showWorkflowStates.indexOf(vm.visit.status) != -1;
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isOwner = vm.account.login == vm.visit.lastModifiedBy;
            });
        }
    }
})();
