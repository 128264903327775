(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('InvitationChangePwdController', InvitationChangePwdController);

    InvitationChangePwdController.$inject = ['$stateParams', 'Auth', 'LoginService', 'Invitation', '$state'];

    function InvitationChangePwdController($stateParams, Auth, LoginService, Invitation, $state) {
        var vm = this;

        vm.login = LoginService.open;
        vm.finishInvite = finishInvite;

        function finishInvite() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Invitation.finish({
                    key: $stateParams.key,
                    newPassword: vm.resetAccount.password
                }).$promise.then(function (user) {
                    vm.success = 'OK';
                    vm.user = user;
                    login(user);
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function login(user) {
            Auth.login({
                username: user.login,
                password: vm.resetAccount.password,
                rememberMe: false
            }).then(function () {
                vm.authenticationError = false;

                $state.go('settings');
                $rootScope.$broadcast('authenticationSuccess');
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        /////
    }
})();
