(function () {
    'use strict';

    angular
        .module('webportalApp')
        .filter('limitHtml', limitHtml);

    limitHtml.$inject = ['$sce'];

    function limitHtml($sce) {
        return function (text, limit, startAt) {
            var finalText = text;
            //var changedString = String(text).replace(/<[^>]+>/gm, '');
            if (text && limit) {
                var changedString = text;
                var length = changedString.length;

                finalText = length > limit ? changedString.substr(startAt-1, limit - 1) : changedString.substr(startAt-1, length - 1);
            }

            return $sce.trustAsHtml(finalText);
        };
    }
})();
