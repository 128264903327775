(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('IntroductionController', IntroductionController);

    IntroductionController.$inject = ['$scope', '$state', 'LoginService', '$translate', 'AlertService', 'Principal', 'Redeem'];

    function IntroductionController($scope, $state, LoginService, $translate, AlertService, Principal, Redeem) {
        var vm = this;

        vm.parameters = [
            {
                name: 'LOBAR_VOLUMES',
                images: [
                    {id: 1, url: '../../../content/images/introduction/LOBAR_VOLUMES_TLC.png', label: 'TLC'},
                    {id: 2, url: '../../../content/images/introduction/LOBAR_VOLUMES_FRC.png', label: 'FRC'}
                ],
                publications: [
                    '10.14814/phy2.13059',
                    '10.1016/j.bjane.2015.11.004',
                    '10.2147/COPD.S93830',
                    '10.1371/journal.pone.0118454',
                    '10.1183/09031936.00168314',
                    '10.1088/0031-9155/59/23/7267',
                    '10.1089/jamp.2013.1064',
                    '10.1183/09031936.00011714',
                    '10.4187/respcare.02756',
                    '10.2147/COPD.S22823',
                    '10.1016/j.medengphy.2007.11.002'
                ]
            },
            {
                name: 'INTERNAL_FLOW_DISTRIBUTION',
                images: [
                    {id: 1, url: '../../../content/images/introduction/INTERNAL_FLOW_DISTRIBUTION.gif', label: ''}
                ],
                publications: [
                    '10.14814/phy2.13059',
                    '10.2147/COPD.S106480',
                    '10.1016/j.bjane.2015.11.004',
                    '10.2147/COPD.S93830',
                    '10.1148/radiol.2015142278',
                    '10.1002/cnm.2730',
                    '10.1371/journal.pone.0118454',
                    '10.1183/09031936.00168314',
                    '10.1088/0031-9155/59/23/7267',
                    '10.1183/09031936.00011714',
                    '10.4187/respcare.02756',
                    '10.1098/rsfs.2012.0057',
                    '10.3109/08958378.2011.644351',
                    '10.2147/COPD.S22823',
                    '10.1148/radiol.10100322',
                    '10.1016/j.medengphy.2007.11.002'
                ]
            },
            {
                name: 'AIRWAY_VOLUME',
                images: [
                    {id: 1, url: '../../../content/images/introduction/AIRWAY_VOLUME_TLC.png', label: 'TLC'},
                    {id: 2, url: '../../../content/images/introduction/AIRWAY_VOLUME_FRC.png', label: 'FRC'}
                ],
                publications: [
                    '10.14814/phy2.13059',
                    '10.2147/COPD.S93830',
                    '10.1080/17476348.2016.1192464',
                    '10.1002/cnm.2730',
                    '10.1371/journal.pone.0118454',
                    '10.1183/09031936.00168314',
                    '10.1089/jamp.2013.1064',
                    '10.1183/09031936.00011714',
                    '10.1098/rsfs.2012.0057',
                    '10.1159/000347120',
                    '10.2147/COPD.S49307',
                    '10.1183/09031936.00072511',
                    '10.3109/08958378.2011.644351',
                    '10.2147/COPD.S29847',
                    '10.2147/COPD.S21917',
                    '10.2147/COPD.S22823',
                    '10.1002/ar.20877',
                    '10.1016/j.medengphy.2007.11.002',
                    '10.1016/j.jbiomech.2007.07.009'
                ]
            },
            {
                name: 'AIRWAY_RESISTANCE_FUNCTIONAL',
                images: [
                    {
                        id: 1,
                        url: '../../../content/images/introduction/AIRWAY_RESISTANCE_FUNCTIONAL_TLC.gif',
                        label: 'TLC'
                    },
                    {
                        id: 2,
                        url: '../../../content/images/introduction/AIRWAY_RESISTANCE_FUNCTIONAL_FRC.gif',
                        label: 'FRC'
                    }
                ],
                publications: [
                    '10.14814/phy2.13059',
                    '10.2147/COPD.S93830',
                    '10.1080/17476348.2016.1192464',
                    '10.1002/cnm.2730',
                    '10.1183/09031936.00168314',
                    '10.1089/jamp.2013.1064',
                    '10.1183/09031936.00011714',
                    '10.1098/rsfs.2012.0057',
                    '10.1159/000347120',
                    '10.2147/COPD.S49307',
                    '10.1183/09031936.00072511',
                    '10.3109/08958378.2011.644351',
                    '10.2147/COPD.S21917',
                    '10.2147/COPD.S22823',
                    '10.1016/j.medengphy.2007.11.002',
                    '10.1016/j.jbiomech.2007.07.009'
                ]
            },
            {
                name: 'AIR_TRAPPING',
                images: [
                    {id: 1, url: '../../../content/images/introduction/AIR_TRAPPING.png', label: ''}
                ],
                publications: [
                    '10.1371/journal.pone.0118454',
                    '10.1089/jamp.2013.1064'
                ]
            },
            {
                name: 'AIRWAY_WALL_THICKNESS',
                images: [
                    {id: 1, url: '../../../content/images/introduction/AIRWAY_WALL_THICKNESS.png', label: ''}
                ],
                publications: []
            },
            {
                name: 'BLOOD_VESSEL_DENSITY',
                images: [
                    {id: 1, url: '../../../content/images/introduction/BLOOD_VESSEL_DENSITY.png', label: ''}
                ],
                publications: [
                    '10.2147/COPD.S106480',
                    '10.2147/COPD.S93830',
                    '10.1183/09031936.00011714'
                ]
            },
            {
                name: 'EMPHYSEMA',
                images: [
                    {id: 1, url: '../../../content/images/introduction/EMPHYSEMA.png', label: ''}
                ],
                publications: []
            },
            {
                name: 'AEROSOL_DEPOSITION',
                images: [
                    {id: 1, url: '../../../content/images/introduction/AEROSOL_DEPOSITION_LUNG_2.png', label: 'Lung deposition'},
                    {id: 2, url: '../../../content/images/introduction/AEROSOL_DEPOSITION_PENET_DEPTH_2.png', label: 'Penetration depth'},
                    {id: 3, url: '../../../content/images/introduction/AEROSOL_DEPOSITION_REGIONAL_2.png', label: 'Regional distribution'}
                ]
            }
        ];


        ///////////////////


    }

})();
