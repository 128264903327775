(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('VisitCategory', VisitCategory);

    VisitCategory.$inject = ['User'];

    function VisitCategory(User) {

        var service = {
            byDoctor: byDoctor
        };
        return service;

        ////////////////////////////////////

        function byDoctor(doctor) {
            var params = {login: doctor.user.login};
            return User.get(params).$promise
                .then(getVisitCategoryByAuthorities);
        }

        function getVisitCategoryByAuthorities(user) {
            if (isResearchDoctor(user)) {
                return 'RESEARCH'; // the exception
            } else {
                return 'MED_DEV'; //Normal
            }
        }

        function isResearchDoctor(user) {
            for (var i = 0; i < user.authorities.length; i++) {
                if (user.authorities[i] === 'ROLE_DOCTOR_RESEARCHER') {
                    return true;
                }
            }
            return false;
        }
    }
})();
