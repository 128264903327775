(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('compare-detail', {
                abstract: true,
                parent: 'entity',
                url: '/patient/{patientId}/compare?visitIds',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/components/empty_footer.html'
                    },
                    'sticky-footer@': {
                        templateUrl: 'app/entities/compare/compare-tiles.html',
                        controller: 'CompareTilesController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.compare.detail.title'
                },
                params: {
                    visitIds: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('compare');


                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('lungZone');

                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('binary');
                        return $translate.refresh();
                    }],
                    visits: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.query({
                            patientId: $stateParams.patientId,
                            page: 0,
                            size: 1000,
                            sort: 'date,asc'
                        });
                    }]
                }
            })
            .state('compare-lobar', {
                parent: 'compare-detail',
                url: '/lobar',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['LOBAR_VOLUMES_TLC', 'LOBAR_VOLUMES_FRC']
                }
            })
            .state('compare-airway', {
                parent: 'compare-detail',
                url: '/airway',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['AIRWAY_VOLUME_TLC', 'AIRWAY_VOLUME_FRC', 'SPECIFIC_AIRWAY_VOLUME_TLC', 'SPECIFIC_AIRWAY_VOLUME_FRC']
                }
            })
            .state('compare-resistance', {
                parent: 'compare-detail',
                url: '/airwayResistance',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['AIRWAY_RESISTANCE_FUNCTIONAL_TLC', 'AIRWAY_RESISTANCE_FUNCTIONAL_FRC', 'SPECIFIC_AIRWAY_RESISTANCE_TLC', 'SPECIFIC_AIRWAY_RESISTANCE_FRC']
                }
            })
            .state('compare-density', {
                parent: 'compare-detail',
                url: '/vesselDensity',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['BLOOD_VESSEL_DENSITY']
                }
            })
            .state('compare-fibrosis', {
                parent: 'compare-detail',
                url: '/fibrosis',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['FIBROSIS']
                }
            })
            .state('compare-distribution', {
                parent: 'compare-detail',
                url: '/distribution',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['INTERNAL_FLOW_DISTRIBUTION']
                }
            })
            .state('compare-trapping', {
                parent: 'compare-detail',
                url: '/trapping',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['AIR_TRAPPING']
                }
            })
            .state('compare-emphysema', {
                parent: 'compare-detail',
                url: '/emphysema',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['EMPHYSEMA']
                }
            })
            .state('compare-airwaywall', {
                parent: 'compare-detail',
                url: '/airwayWall',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['AIRWAY_WALL_THICKNESS']
                }
            })
            .state('compare-nodules', {
                parent: 'compare-detail',
                url: '/nodules',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['NODULES']
                }
            })
            .state('compare-ventilationPerfusion', {
                parent: 'compare-detail',
                url: '/ventilationPerfusion',
                views: {
                    'content@': {
                        templateUrl: 'app/entities/compare/compare-detail.html',
                        controller: 'CompareDetailController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    part: ['VENTILATION_PERFUSION']
                }
            })
        ;
    }

})();
