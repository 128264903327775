(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldPatientTableCellTag', patientTableCellTag)
        .controller('PatientTableCellTagController', PatientTableCellTagController);

    PatientTableCellTagController.$inject = ['Principal'];

    function patientTableCellTag() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                patient: '=',
                visit: '=',
                maxChars: '@',
                clickable: '@',
                onlyNames: '@'
            },
            templateUrl: 'app/entities/patient/patient-table-cell-tag.html',
            controller: 'PatientTableCellTagController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.patient', function (newVal) {
                if (newVal) {
                    scope.vm.activate(newVal);
                }
            }, true);
        }
    }

    function PatientTableCellTagController(Principal) {
        var vm = this;

        vm.showPatientInfo = false;
        vm.maxChars = vm.maxChars ? vm.maxChars : 30;
        vm.enableClickThrough = vm.clickable == 'true' ? true : false;

        vm.activate = activate;

        ////////////////

        function activate(patient) {
            vm.showPatientInfo = Principal.hasAnyAuthority(['ROLE_DOCTOR_RESEARCHER']) && (patient.name || vm.onlyNames == 'true');
        }
    }
})();
