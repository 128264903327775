(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('UploadBaseController', UploadBaseController);

    UploadBaseController.$inject = [];

    function UploadBaseController() {
        var vm = this;


        //////////

    }
})();
