(function () {
    'use strict';

    angular
        .module('webportalApp')
        .factory('IdsNavigationService', IdsNavigationService);

    IdsNavigationService.$inject = ['Visit'];

    function IdsNavigationService(Visit) {
        var patientId;
        var navObjects = [];

        var service =  {
            reset: reset,
            retrieveObjects: retrieveObjects
        };

        return service;

        /////////

        function reset() {
            patientId = null;
        }

        function retrieveObjects(parentId) {
            if (!matches(parentId)) {
                patientId = parentId;
                navObjects = refreshOtherIds(parentId);
            }
            return navObjects;
        }

        function refreshOtherIds(parentId) {
            return Visit.query({
                patientId: parentId,
                page: 0,
                size: 50,
                sort: sort()
            }).$promise;
        }

        function sort() {
            return ['date,asc'];
        }

        function matches(parentId) {
            return patientId === parentId;

        }

    }
})();
