(function () {
    'use strict';

    angular
        .module('webportalApp')
        .controller('SalesRepDeleteController', SalesRepDeleteController);

    SalesRepDeleteController.$inject = ['$uibModalInstance', 'entity', 'SalesRep'];

    function SalesRepDeleteController($uibModalInstance, entity, SalesRep) {
        var vm = this;

        vm.salesRep = entity;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        /////////////////////////////

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            SalesRep.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
