(function () {
    'use strict';
    angular
        .module('webportalApp')
        .factory('Report', Report);

    Report.$inject = ['$resource'];

    function Report($resource) {
        var resourceUrl = 'report/api/0.1/reports/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
