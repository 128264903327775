(function() {
    'use strict';

    angular
        .module('webportalApp')
        .controller('OrderReviewController', OrderReviewController);

    OrderReviewController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'Order', 'Payment', 'Invoice', '$location', '$sce', 'Ecom', 'OrderCouponCode', 'AlertService', '$translate'];

    function OrderReviewController($timeout, $scope, $stateParams, $q, entity, Order, Payment, Invoice, $location, $sce, Ecom, OrderCouponCode, AlertService, $translate) {
        var vm = this;

        vm.order = entity;
        vm.addCouponCode = addCouponCode;
        vm.products = products;
        vm.others = others;

        prepare();

        //////////////////////////

        function prepare() {
            vm.subtotal = 0;

            calculateSubtotal();

            Ecom.get({id: vm.order.id}).$promise.then(function (ecom) {
                vm.ecom = ecom;
                vm.formAction = $sce.trustAsResourceUrl(ecom.formAction);
                vm.acceptUrl = generateUrl('accept');
            });
        }

        function calculateSubtotal() {
            vm.order.items.forEach(addToSubTotal)
        }

        function addToSubTotal(entry){
            vm.subtotal += (entry.unitPrice * entry.quantity);
        }



        function generateUrl(status) {
            return $location.absUrl().replace('review', status);
        }

        function addCouponCode() {
            return OrderCouponCode.update({id: vm.order.id, code: vm.couponCode}, null, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.order = data;

            prepare();
        }

        function onError(error) {
            //console.log(error);
            var message = $translate.instant('orderApp.order.wizard.review.coupon.code.error.response');
            AlertService.warning(message);
        }

        function products(item) {
            return !others(item);
        }

        function others(item) {
            return (['DELIVERY_FEE', 'DISCOUNT'].indexOf(item.product.type) !== -1);
        }
    }
})();
