(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldVisitsNested', visitsNested)
        .controller('VisitsNestedController', VisitsNestedController);

    VisitsNestedController.$inject = ['$state', 'Visit', 'ParseLinks', 'AlertService', 'paginationConstants', 'MyCenter', 'Principal', 'UploadSession'];

    function visitsNested() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                master: '=',
                doctor: '=',
                patient: '=',
                pagingParams: '='
            },
            templateUrl: 'app/entities/visit/visits-nested.html',
            controller: 'VisitsNestedController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.master', function (newValue) {
                if (newValue) {
                    scope.vm.loadAll();
                }
            }, true);
        }
    }

    function VisitsNestedController($state, Visit, ParseLinks, AlertService, paginationConstants, MyCenter, Principal, UploadSession) {
        var vm = this;

        vm.visitsShowDoctor = (vm.master !== vm.doctor);
        vm.visitsShowPatient = (vm.master !== vm.patient);
        vm.reverse = vm.pagingParams.ascending;
        vm.predicate = vm.pagingParams.predicate;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.goToUpload = goToUpload;

        getMyCenter();

        ////////////

        function loadAll() {
            if (vm.master === vm.patient) {
                vm.patient.$promise.then(function () {
                    Visit.query({
                        patientId: vm.patient.id,
                        page: vm.pagingParams.page - 1,
                        size: paginationConstants.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);

                });
            }
            if (vm.master === vm.doctor) {
                vm.doctor.$promise.then(function () {
                    Visit.query({
                        doctorId: vm.doctor.id,
                        page: vm.pagingParams.page - 1,
                        size: paginationConstants.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);

                });
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = vm.pagingParams.page;

            vm.visits = data;

            flagEditableVisits();
            enableCompareVisits();
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.master.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, {location: 'replace'});
        }

        function getMyCenter(){
            return MyCenter.getMyCenter().then(function (data) {
                vm.myCenter = data;
                return vm.myCenter;
            })
        }

        function enableCompareVisits() {
            if(vm.visits.length>1){
                var element = angular.element('#compareVisits'); // 1
                element.prop('disabled', false);
            }
        }

        function flagEditableVisits() {
            getMyCenter().then(function (myCenter) {
                vm.visits.forEach(function (visit) {
                    var myVisit = visit.doctor.center.id === myCenter.id;
                    var correctStatus = visit.status === 'INCOMPLETE' || visit.status === 'READY_TO_UPLOAD' || visit.status === 'UPLOADED';

                    visit.allowEdit = (myVisit && correctStatus);
                })
            }).catch(function () {
                var isDataManager = Principal.hasAnyAuthority(['ROLE_DATA_MANAGER']);
                vm.visits.forEach(function (visit) {
                    visit.allowEdit = isDataManager;
                })
            });
        }

        function goToUpload(visit) {
            UploadSession.sessionEnded();
            $state.go('upload.init', {patientId: visit.patient.id, visitId: visit.id});
        }



    }
})();
