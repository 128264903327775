(function () {
    'use strict';

    angular
        .module('webportalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('visit', {
                parent: 'entity',
                url: '/visit?page&sort&search&status&lastModifiedBy',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/visit/visits.html',
                        controller: 'VisitController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true
                    },
                    search: null,
                    status: null,
                    lastModifiedBy: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            lastModifiedBy: $stateParams.lastModifiedBy
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('dataCategoryType');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('category');
                        return $translate.refresh();
                    }]
                }
            })
            .state('visit-detail', {
                parent: 'entity',
                url: '/visit/{id}',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/visit/visit-detail.html',
                        controller: 'VisitDetailController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/components/empty_footer.html'
                    },
                    'sticky-footer@': {
                        templateUrl: 'app/entities/report/report-detail-tiles.html',
                        controller: 'ReportDetailTilesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('dataCategoryType');
                        $translatePartialLoader.addPart('dataEntryType');


                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('gender');

                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('reportStatus');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('binary');
                        $translatePartialLoader.addPart('category');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.lobar', {
                parent: 'visit-detail',
                url: '/lobar',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['LOBAR_VOLUMES_TLC', 'LOBAR_VOLUMES_FRC']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-collection.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.airway', {
                parent: 'visit-detail',
                url: '/airway',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AIRWAY_VOLUME_TLC', 'AIRWAY_VOLUME_FRC', 'SPECIFIC_AIRWAY_VOLUME_TLC', 'SPECIFIC_AIRWAY_VOLUME_FRC', 'SPECIFIC_AIRWAY_RADIUS_TLC']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-collection.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.resistance', {
                parent: 'visit-detail',
                url: '/airwayResistance',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AIRWAY_RESISTANCE_FUNCTIONAL_TLC', 'AIRWAY_RESISTANCE_FUNCTIONAL_FRC', 'SPECIFIC_AIRWAY_RESISTANCE_TLC', 'SPECIFIC_AIRWAY_RESISTANCE_FRC']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-collection.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })

            .state('visit-detail.deposition', {
                parent: 'visit-detail',
                url: '/deposition',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AEROSOL_DEPOSITION']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/deposition/report-detail-part-deposition.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.distribution', {
                parent: 'visit-detail',
                url: '/distribution',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['INTERNAL_FLOW_DISTRIBUTION']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.emphysema', {
                parent: 'visit-detail',
                url: '/emphysema',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['EMPHYSEMA']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.trapping', {
                parent: 'visit-detail',
                url: '/trapping',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AIR_TRAPPING']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.density', {
                parent: 'visit-detail',
                url: '/vesselDensity',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['BLOOD_VESSEL_DENSITY']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.fibrosis', {
                parent: 'visit-detail',
                url: '/fibrosis',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['FIBROSIS']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.airwaywall', {
                parent: 'visit-detail',
                url: '/airwayWall',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AIRWAY_WALL_THICKNESS']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.nodules', {
                parent: 'visit-detail',
                url: '/nodules',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['NODULES']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.ventilationPerfusion', {
                parent: 'visit-detail',
                url: '/ventilationperfusion',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['VENTILATION_PERFUSION']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-single.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.spirometrie', {
                parent: 'visit-detail',
                url: '/spirometrie',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['SPIROMETRIE']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.lungvolumes', {
                parent: 'visit-detail',
                url: '/lungvolumes',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['LUNG_VOLUMES']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.lungresistance', {
                parent: 'visit-detail',
                url: '/lungresistance',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['AIRWAY_RESISTANCE_CLINICAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.diffusion', {
                parent: 'visit-detail',
                url: '/diffusion',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['DIFFUSION_CAPACITY']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.sleep_info', {
                parent: 'visit-detail',
                url: '/sleepinfo',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['SLEEP_INFO']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.pneumo_info', {
                parent: 'visit-detail',
                url: '/pneumoinfo',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['PNEUMO_INFO']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-clinical.html',
                        controller: 'ReportDetailPartController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.axial', {
                parent: 'visit-detail',
                url: '/axial',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['SCAN_AXIAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-scan.html',
                        controller: 'ReportDetailPartScanController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.coronal', {
                parent: 'visit-detail',
                url: '/coronal',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['SCAN_CORONAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-scan.html',
                        controller: 'ReportDetailPartScanController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-detail.sagittal', {
                parent: 'visit-detail',
                url: '/sagittal',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.detail.title',
                    part: ['SCAN_SAGITTAL']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/part/report-detail-part-scan.html',
                        controller: 'ReportDetailPartScanController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('lungZone');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('partStatus');
                        $translatePartialLoader.addPart('lungInfoType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit-export', {
                parent: 'app',
                url: '/visit/{id}/export',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DATA_MANAGER', 'ROLE_DATA_VIEWER'],
                    pageTitle: 'webportalApp.visit.export.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/visit/visit-export.html',
                        controller: 'VisitExportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('export');

                        $translatePartialLoader.addPart('pathology');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('lenghtUnit');
                        $translatePartialLoader.addPart('weightUnit');
                        $translatePartialLoader.addPart('dataCategoryType');
                        $translatePartialLoader.addPart('dataEntryType');
                        $translatePartialLoader.addPart('center');

                        $translatePartialLoader.addPart('race');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('doctor');
                        $translatePartialLoader.addPart('report');
                        $translatePartialLoader.addPart('reportStatus');
                        $translatePartialLoader.addPart('part');
                        $translatePartialLoader.addPart('partType');
                        $translatePartialLoader.addPart('binary');
                        $translatePartialLoader.addPart('lungZone');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Visit', function ($stateParams, Visit) {
                        return Visit.get({id: $stateParams.id});
                    }],
                    report: ['$stateParams', 'Report', function ($stateParams, Report) {
                        return Report.get({id: $stateParams.id});
                    }]
                }
            })
            .state('visit.new', {
                parent: 'visit',
                url: '/new?patientId',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog-new.html',
                        controller: 'VisitDialogNewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: 'new', patientId: $stateParams.patientId});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('visit-detail.listEdit', {'id': result.id}, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('visit-detail.listEdit', {
                parent: 'visit-detail',
                url: '/listEdit',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog.html',
                        controller: 'VisitDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('visit', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('visit', null, {location: 'replace'});
                    });
                }]
            })
            .state('visit-detail.edit', {
                parent: 'visit-detail',
                url: '/edit',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog.html',
                        controller: 'VisitDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('visit-detail.upload', {
                parent: 'visit-detail',
                url: '/upload',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-dialog-upload.html',
                        controller: 'VisitDialogUploadController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function (result) {
                        $state.go('^', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            })
            .state('visit.delete', {
                parent: 'visit',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_DOCTOR_RESEARCHER', 'ROLE_DOCTOR_MEDDEV', 'ROLE_DATA_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/visit/visit-delete-dialog.html',
                        controller: 'VisitDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Visit', function (Visit) {
                                return Visit.get({id: $stateParams.id});
                            }]
                        }
                    }).result.then(function () {
                        $state.go('visit', null, {reload: true, location: 'replace'});
                    }, function () {
                        $state.go('^', null, {location: 'replace'});
                    });
                }]
            });
    }

})();
