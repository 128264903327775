(function () {
    'use strict';

    angular
        .module('webportalApp')
        .directive('fldOrdersNested', ordersNested)
        .controller('OrdersNestedController', OrdersNestedController);

    OrdersNestedController.$inject = ['$state', 'Order', 'ParseLinks', 'AlertService', 'paginationConstants', 'MyCenter'];

    function ordersNested() {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                salesRep: '=',
                pagingParams: '=',
                tab: '='
            },
            templateUrl: 'app/orders/order/orders-nested.html',
            controller: 'OrdersNestedController',
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch('vm.salesRep', function (newValue) {
                if (newValue) {
                    scope.vm.loadAll();
                }
            }, true);
        }
    }

    function OrdersNestedController($state, Order, ParseLinks, AlertService, paginationConstants, MyCenter) {
        var vm = this;

        vm.reverse = vm.pagingParams.ascending;
        vm.predicate = vm.pagingParams.predicate;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.transition = transition;

        ////////////

        function loadAll() {
            vm.salesRep.$promise.then(function () {
                Order.query({
                    salesRepId: vm.salesRep.id,
                    page: vm.pagingParams.page - 1,
                    size: paginationConstants.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

            });
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = vm.pagingParams.page;

            vm.orders = data;
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.salesRep.id,
                tab: vm.tab,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            }, {location: 'replace'});
        }

    }
})();
